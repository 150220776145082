import { useSolutionPopup } from '@/stores/common/useSolutionPopup';

const Estimate = () => {
  const { setSolutionModal } = useSolutionPopup();

  return (
    <div className="inner sectionTitleWrap">
      <h4 className="point">가격 정책</h4>
      <h2 className="title">
        레스토랑별 맞춤형 <br />
        예약 관리 솔루션 시작하기
      </h2>
      <ul className="solution-items">
        <li>
          <div className="item-header">
            <h3>Basic</h3>
            <span className="number yellow">월 예약 300건</span>
          </div>
          <p>소규모 레스토랑을 위한 맞춤형 예약 관리</p>
          <div className="item-con">
            <ul>
              <li>가입비, 설치비 무료</li>
              <li>약정 기간 없음</li>
              <li>자동 콜백(Callback) 문자 기능</li>
            </ul>
            <a className="btn-more popupOpen" onClick={() => setSolutionModal(true)}>
              견적 받기
            </a>
          </div>
        </li>
        <li data-wow-delay="0.2s">
          <div className="item-header">
            <h3>Standard</h3>
            <span className="number white-blue">월 예약 600건</span>
          </div>
          <p>성장하는 레스토랑을 위한 최고의 예약 관리</p>
          <div className="item-con">
            <ul>
              <li>Basic 서비스 모든 기능 제공</li>
              <li>고객 멤버십</li>
              <li>노쇼(No-show) 방지 예약금 기능</li>
            </ul>
            <a className="btn-more popupOpen" onClick={() => setSolutionModal(true)}>
              견적 받기
            </a>
          </div>
        </li>
        <li data-wow-delay="0.4s">
          <div className="item-header">
            <h3>Premium</h3>
            <span className="number blue">월 예약 1200건</span>
          </div>
          <p>대규모 프랜차이즈 법인을 위한 클라우드 기반 예약 관리</p>
          <div className="item-con">
            <ul>
              <li>Standard 서비스 모든 기능 제공</li>
              <li>예약 관리 애플리케이션 제공</li>
              <li>전체 매장 예약 통계 리포트</li>
            </ul>
            <a className="btn-more popupOpen" onClick={() => setSolutionModal(true)}>
              견적 받기
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Estimate;
