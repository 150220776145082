import CheckPersonalInfoAgreement from '@/components/CheckPersonalInfoAgreement/CheckPersonalInfoAgreement';
import FormTitle from '@/components/FormTitle/FormTitle';
import { FreeExperienceContainer } from '@/components/kakao/FreeExperience/FreeExperience.styles';
import { postSlackMessage, validationForm } from '@/hooks/postSlackMessage.hook';
import { putPrivacyData } from '@/hooks/usePrivacyForm.hook';
import { PAGE_TYPE } from '@/shared/utils/common.constants';
import { SELECT_PRIVACY_TYPE } from '@/shared/utils/privacy/privacy.constants';
import { useState } from 'react';
import { useSolution } from '@/stores/common/useSolution';
import { FreeTrialAppForSolutionContainer, FreeTrialTitle, SolutionInquiryButton } from '@/components/Waiting/FreeTrialApp/FreeTrialApp.styles';
import { TM_POST_CHANNEL } from '@/constants';

interface IFreeTrialApp {
  parentClass?: string;
  freeTrialPageType: string;
}

const FreeTrialApp = (props: IFreeTrialApp) => {
  const { parentClass, freeTrialPageType } = props;

  const [form, setForm] = useState({ email: '', tel: '', coName: '' });
  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState<boolean>(false);

  const { mutateAsync: privacyMutate } = putPrivacyData();
  const { mutateAsync: sendMessageMutate } = postSlackMessage(PAGE_TYPE.DEFAULT, null, freeTrialPageType);
  const { isSolutionFrame } = useSolution();

  const submit = async () => {
    if (isSolutionFrame) {
      window.parent.postMessage({ type: TM_POST_CHANNEL, data: `Submit:${freeTrialPageType}` }, '*');
    } else {
      try {
        validationForm(freeTrialPageType, form, isCheckedPrivacy);
        await privacyMutate({
          phone: form.tel.replace(/-/g, ''),
          email: form.email,
          privacyType: SELECT_PRIVACY_TYPE.DEFAULT,
          isCheckedAgreement: isCheckedPrivacy,
        });

        await sendMessageMutate(form).then(() => {
          setForm({ email: '', tel: '', coName: '' });
        });
        // 신청/예약 완료 페이지 로그수집
        window._nasa = window._nasa || {};
        if (window.wcs) {
          window._nasa.cnv = window.wcs.cnv('4', freeTrialPageType);
          window.wcs_do(window._nasa);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return !isSolutionFrame ? (
    <FreeExperienceContainer id="form" className={`wrap colorBack noFloat ${parentClass}`}>
      <div className="gapDef" />
      <div className="inner form clear-parent form-content-wrap">
        <FormTitle desc="기존 고객은 추가 비용없이 무료로 도입 가능합니다." />
        <div className="right">
          <div className="inputWrap required">
            <label htmlFor="coName" className="title-label">
              매장명
            </label>
            <input type="text" id="coName" value={form.coName} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap required">
            <label htmlFor="phone" className="title-label">
              대표자 연락처
            </label>
            <input type="tel" id="tel" value={form.tel} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap">
            <label htmlFor="email" className="title-label">
              이메일
            </label>
            <input type="email" id="email" value={form.email} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <CheckPersonalInfoAgreement type="DEFAULT" htmlFor="defaultForm" setChecked={setIsCheckedPrivacy} />
          <div className="btnBox">
            <button className="defBtn" onClick={submit}>
              신청하기
            </button>
            <p>신청서를 제출하시면 영업일 기준 2일 이내 고객센터를 통해 연락을 드릴 예정입니다.</p>
          </div>
        </div>
      </div>
      <div className="gapDef" />
    </FreeExperienceContainer>
  ) : (
    <FreeTrialAppForSolutionContainer id="form">
      <FreeTrialTitle>
        추가 비용 없이
        <br />
        무료로 도입해보세요
      </FreeTrialTitle>
      <SolutionInquiryButton onClick={submit}>신청하기</SolutionInquiryButton>
    </FreeTrialAppForSolutionContainer>
  );
};

export default FreeTrialApp;
