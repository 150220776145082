import { FormTitleContainer } from './FormTitle.styles';

interface IFormTitle {
  desc?: string;
}

const FormTitle = (props: IFormTitle) => {
  const { desc } = props;
  return (
    <FormTitleContainer className="leftTit left">
      <h2 className="sub-title">무료 체험 신청하세요.</h2>
      <p className="description">{desc || '최고의 컨설턴트가 고객님의 상황에 맞게 설계해 드립니다.'}</p>
    </FormTitleContainer>
  );
};

export default FormTitle;
