import React from 'react';
import { IManageContainer } from './ManageContainer.type';
import {} from './ManageContainer.constants';
import { ManageContainerContainer } from './ManageContainer.styles';
//import classnames from 'classnames';

const ManageContainer = (props: IManageContainer): React.ReactElement => {
  return (
    <ManageContainerContainer>
      <div className="containerInner manage">
        <div className="title">고객 관리</div>
        <div className="sub-title">관리자 도구를 통한 효과적인<br />고객 관리</div>
        <div className="description">채널 내 예약 기능 도입으로 더 많은 고객과 친구를 맺고, 고객의 방문일자와 특성에 따른 고객 관리 서비스를 제공하세요.</div>
        <div className="containerBody">
          <img src="/kakao/img/img.png" alt="" width={600}/>
        </div>
      </div>
    </ManageContainerContainer>
  );
};

export default ManageContainer;
