import styled from '@emotion/styled';
import { MediaQuery } from '@/shared/styles';

export const SatisfactionSurveyImg = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  box-sizing: border-box;
  background: #f2f3f4;
  border-radius: 16px;

  ${MediaQuery.mobile} {
    height: auto;
    min-height: 280px;
  }

  .img-content img {
    display: block;
    width: 100%;
  }

  &.response-rate {
    align-items: end;
    .img-content img {
      max-width: 544px;

      ${MediaQuery.tablet} {
        max-width: 530px;
      }

      ${MediaQuery.mobile} {
        max-width: 238px;
      }
    }
  }

  &.review {
    align-items: end;
    .img-content img {
      max-width: 766px;

      ${MediaQuery.tablet} {
        max-width: 746px;
      }

      ${MediaQuery.mobile} {
        max-width: 308px;
      }
    }
  }

  &.evaluation-results {
    .img-content img {
      max-width: 800px;

      ${MediaQuery.tablet} {
        max-width: 772px;
      }

      ${MediaQuery.mobile} {
        max-width: 280px;
      }
    }
  }

  &.data-download {
    .img-content img {
      max-width: 788px;

      ${MediaQuery.tablet} {
        max-width: 766px;
      }

      ${MediaQuery.mobile} {
        max-width: 280px;
      }
    }
  }
`;
