import { ConfigureContent, HeadContent, KTratePlanContainer, KTratePlanWrap, PriceContent, RatePlanContent, Title } from './KTratePlan.styles';

const KTratePlan = () => {
  return (
    <KTratePlanContainer>
      <Title>
        나에게 맞는 KT AI 통화비서를 <br />
        만나보세요
      </Title>
      <KTratePlanWrap>
        <RatePlanContent>
          <HeadContent>
            <h3>스탠다드</h3>
            <span>AI전화에 통합 예약 관리까지! 매출이 늘어나는 솔루션 AI 통화비서</span>
          </HeadContent>
          <PriceContent>
            <div>
              <h5>22,000원</h5>
              <span>월단위/VAT 포함</span>
            </div>
            <ol className="ordered-lists">
              <li>편리하게 전화·네이버·카카오 예약을 1개 APP으로 통합 관리하세요.</li>
              <li>간단하고 반복적인 문의(주차, 영업시간, 위치)는 AI가 대답해드려요.</li>
              <li>픽업 포장 주문전화는 AI가 대신 접수해드려요.</li>
            </ol>
          </PriceContent>
          <ConfigureContent>
            <ul className="unordered-lists">
              <li>
                <div>
                  <strong>모든 착신콜</strong>에 대해서 AI 응대
                </div>
              </li>
              <li>
                <strong>예약/주문</strong>
              </li>
              <li>
                <strong>주차/영업시간 자동 안내</strong>
              </li>
              <li>통화이력/발신번호 표시</li>
              <li>홍보 인사말</li>
              <li>통화내용 문자메모</li>
              <li>착신통화 전환</li>
              <li>
                <strong>무료 문자 월 300건(9,900원 상당)</strong>
              </li>
            </ul>
          </ConfigureContent>
        </RatePlanContent>
        <RatePlanContent>
          <HeadContent className="light-header">
            <h3>라이트</h3>
            <span>내 유선 전화를 AI 전화로 만들어주는 유선 전화의 필수품 AI 통화비서</span>
          </HeadContent>
          <PriceContent>
            <div>
              <h5>5,500원</h5>
              <span>월단위/VAT 포함</span>
            </div>
            <ol className="ordered-lists">
              <li>가게 인사말을 내맘대로 APP에서 바꿀 수 있어요.</li>
              <li>걸려온 전화를 APP에서 한눈에 리스트로 확인할 수 있어요.</li>
              <li>못 받은 전화 용건을 문자 메모로 남겨줘요.</li>
            </ol>
          </PriceContent>
          <ConfigureContent>
            <ul className="unordered-lists">
              <li>미수신콜에 대해서 AI 응대</li>
              <li className="not-provided">예약/주문 미제공</li>
              <li className="not-provided">주차/영업시간 자동 안내 미제공</li>
              <li>통화이력/발신번호 표시</li>
              <li>홍보 인사말</li>
              <li>통화내용 문자메모</li>
              <li>착신통화 전환</li>
              <li className="not-provided">무료 문자 미제공</li>
            </ul>
          </ConfigureContent>
        </RatePlanContent>
      </KTratePlanWrap>
    </KTratePlanContainer>
  );
};

export default KTratePlan;
