import React from 'react';

import styled from '@emotion/styled';
import { MediaQuery } from '@/shared/styles';

export const CustomerReviewsContainer = styled.div<React.CSSProperties>`
  position: relative;
  margin-top: 200px;

  &::before {
    content: '';
    display: block;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    z-index: -1;
    background-color: #eaeef8;
  }

  .feedbackWrap {
    gap: 102px;

    ${MediaQuery.tablet} {
      gap: 40px;
    }

    ${MediaQuery.mobile} {
      gap: 0px;
    }

    .customer-review-head-title {
      ${MediaQuery.tablet} {
        max-width: 362px;
      }

      ${MediaQuery.mobile} {
        margin-bottom: 28px;
      }
    }
    .feedBack {
      ${MediaQuery.tablet} {
        max-width: 566px;
      }
      ${MediaQuery.mobile} {
        max-width: 100%;
      }
      li b {
        color: #2d60b8;
        font-weight: 700;
      }
    }
  }
`;
