import React from 'react';
import { IFloatingButton } from './FloatingButton.type';
import {} from './FloatingButton.constants';
import { FloatingButtonContainer } from './FloatingButton.styles';
//import classnames from 'classnames';

const FloatingButton = (props: IFloatingButton): React.ReactElement => {
  function moveHomepage() {
    document.querySelector('#form').scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <FloatingButtonContainer onClick={moveHomepage}>
      <span>카카오톡 챗봇예약 도입하기</span>
    </FloatingButtonContainer>
  );
};

export default FloatingButton;
