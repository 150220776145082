import { MediaQuery } from '@/shared/styles';
import styled from '@emotion/styled';

export const SkillContainer = styled.article`
  margin-block: 200px;

  ${MediaQuery.tablet} {
    max-width: calc(100% - 6% - 6%);
    margin-inline: auto;
  }
  ${MediaQuery.mobile} {
    max-width: calc(100% - 6.66% - 6.66%);
    margin-block: 140px;
  }
`;
export const SkillImgWrapper = styled.section`
  display: flex;
  justify-content: center;
  padding-block: 86px;
  background: #f2f3f4;
  border-radius: 16px;

  ${MediaQuery.tablet} {
    padding-block: 107.4px;
    padding-inline: 63.5px;
  }
  ${MediaQuery.mobile} {
    padding: 26px 28px;
  }

  .skill-img-wrap {
    display: flex;
    align-items: center;
    gap: 40px;

    ${MediaQuery.mobile} {
      flex-direction: column;
    }

    li {
      &:first-of-type {
        ${MediaQuery.tablet} {
          max-width: 412.2px;
        }
      }

      &:nth-of-type(2) {
        ${MediaQuery.tablet} {
          max-width: 388.8px;
        }
      }

      img {
        max-width: 100%;
      }
    }
  }
`;
