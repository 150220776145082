import apiBase from './apiBase';

export const devServerApi = apiBase({
  baseURL: process.env.REACT_APP_API_HOST,
});

export const serverImgURL = process.env.REACT_APP_IMAGE_HOST;

export const privacyApi = apiBase({
  baseURL: process.env.REACT_APP_PRIVACY_API_HOST,
});

export const slackBotApi = apiBase({
  baseURL: process.env.REACT_APP_SLACK_BOT_API_HOST,
});
