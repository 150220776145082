import React from 'react';
import { CustomerReviewsContainer } from '@/components/SatisfactionSurvey/CustomerReviews/CustomerReviews.styles';
import HeadTitle from '@/components/HeadTitle/HeadTitle';
import ReviewLogo01 from '@/assets/images/survey/customerReviewsLogo/review_01.png';
import ReviewLogo02 from '@/assets/images/survey/customerReviewsLogo/review_02.png';
import ReviewLogo03 from '@/assets/images/survey/customerReviewsLogo/review_03.png';
import ReviewLogo04 from '@/assets/images/survey/customerReviewsLogo/review_04.png';
import ReviewLogo05 from '@/assets/images/survey/customerReviewsLogo/review_05.png';
import ReviewLogo06 from '@/assets/images/survey/customerReviewsLogo/review_06.png';

const CustomerReviews = (): React.ReactElement => {
  return (
    <CustomerReviewsContainer>
      <div className="gapDef" />
      <article className="inner feedbackWrap sectionTitleWrap">
        <HeadTitle className="customer-review-head-title" subTitle="고객 후기" title={'만족도 조사 기능 \n사용해보니 어떠셨나요?'} />
        <ul className="feedBack">
          <li>
            <dl>
              <dd>
                매장에 도움이 될 만한 피드백을 수렴할 수 있어 의미가 있습니다. 예를들면, 회 두께가 아쉽다와 같은 고객이 개선 요청하는 내용을 확인하고,{' '}
                <b>해당 고객의 메모에 기록해서 재방문 시에는 보완을 할 수 있는 순기능이 있습니다.</b>
              </dd>
              <dt>
                <img src={ReviewLogo01} alt="삿뽀로 압구정점 로고" />
                <span>
                  삿뽀로 압구정점 과장<em>임소라</em>
                </span>
              </dt>
            </dl>
          </li>
          <li>
            <dl>
              <dd>
                고객이 매장을 방문한 이후 바로 피드백을 확인할 수 있어서 너무 편리합니다. 만족도 평가를 진행하는 것을 전직원들이 인지를 하고 있다보니,{' '}
                <b>자연스럽게 서비스 퀄리티가 높아지는 효과도 경험할 수 있습니다.</b>
              </dd>
              <dt>
                <img src={ReviewLogo02} alt="경복궁 대구점 로고" />
                <span>
                  경복궁 대구점 과장<em>윤승희</em>
                </span>
              </dt>
            </dl>
          </li>
          <li>
            <dl>
              <dd>
                월별 자료 취합에 용이하며, 개선 필요한 점들을 개선할 수 있는 계기가 되어 좋습니다. 이전에는 고객의 리뷰를 네이버 리뷰나 블로그에 들어가서 일일이 확인해야 했는데,{' '}
                <b>평가 리포트는 한눈에 확인할 수 있는 점이 편리합니다.</b>
              </dd>
              <dt>
                <img src={ReviewLogo03} alt="고구려 영업팀장 로고" />
                <span>
                  고구려 영업팀장<em>서동혁</em>
                </span>
              </dt>
            </dl>
          </li>
          <li>
            <dl>
              <dd>
                만족도 평가에 <b>불편한 의견을 주신 고객님이 재방문 했을 때, 당시 서비스에 대해 사과</b>드리며, 특별 케어한 사례가 있었습니다. 이러한 과정이 고객 감동을 실천하는
                모범사례로써 활용되고 있습니다.
              </dd>
              <dt>
                <img src={ReviewLogo04} alt="한양 한옥마을 로고" />
                <span>
                  한양 한옥마을 점장<em>박명순</em>
                </span>
              </dt>
            </dl>
          </li>
          <li>
            <dl>
              <dd>
                매월 불만족 의견을 취합하고 이를 개선하기 위해 고객의 의견을 적극적으로 수용하고 있습니다. 또한, 칭찬의 목소리도 꼼꼼하게 확인해서{' '}
                <b>가장 많은 칭찬을 받은 직원을 대상으로 포상을 하는 것에도 활용</b>하고 있습니다.
              </dd>
              <dt>
                <img src={ReviewLogo05} alt="라운지 OIC 로고" />
                <span>
                  라운지 OIC 차장<em>이하림</em>
                </span>
              </dt>
            </dl>
          </li>
          <li>
            <dl>
              <dd>
                매장에서 놓쳤던 부분을 고객님의 리뷰를 통해 알게 된 경험이 있습니다. <b>여러 피드백 중에 매장 입장에서 도움이 될 만한 피드백을 확인</b>할 수 있어서 긍정적으로
                활용하고 있습니다.
              </dd>
              <dt>
                <img src={ReviewLogo06} alt="평창동북악관 신관 로고" />
                <span>
                  평창동북악관 신관 부점장<em>조계숙</em>
                </span>
              </dt>
            </dl>
          </li>
        </ul>
      </article>
      <div className="gapDef" />
    </CustomerReviewsContainer>
  );
};

export default CustomerReviews;
