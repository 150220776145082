import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import CommonProvider from '@/provider/common.provider';
import AboutIndexPage from '@/pages/about/index';
import CashnoteIndexPage from '@/pages/cashnote/index';
import CorpIndexPage from '@/pages/corp/index';
import FranchiseIndexPage from '@/pages/franchise/index';
import IndexPage from '@/pages/index';
import KakaoIndexPage from '@/pages/kakao/index';
import KbIndexPage from '@/pages/kb/index';
import KtIndexPage from '@/pages/kt/index';
import NewsIndexPage from '@/pages/news/index';
import Newslist$IdPage from '@/pages/newslist/[id]';
import NewslistIndexPage from '@/pages/newslist/index';
import PickupIndexPage from '@/pages/pickup/index';
import Press$IdPage from '@/pages/press/[id]';
import PressIndexPage from '@/pages/press/index';
import RestaurantIndexPage from '@/pages/restaurant/index';
import SatisfactionSurveyIndexPage from '@/pages/satisfaction-survey/index';
import WaitingIndexPage from '@/pages/waiting/index';

const CustomRouter = (props = {}) => <BrowserRouter>
  <Routes {...props}>
    <Route key="AboutIndexPage" path="/about/" element={<CommonProvider><AboutIndexPage/></CommonProvider> }/>
    <Route key="CashnoteIndexPage" path="/cashnote/" element={<CommonProvider><CashnoteIndexPage/></CommonProvider> }/>
    <Route key="CorpIndexPage" path="/corp/" element={<CommonProvider><CorpIndexPage/></CommonProvider> }/>
    <Route key="FranchiseIndexPage" path="/franchise/" element={<CommonProvider><FranchiseIndexPage/></CommonProvider> }/>
    <Route key="IndexPage" path="/" element={<CommonProvider><IndexPage/></CommonProvider> }/>
    <Route key="KakaoIndexPage" path="/kakao/" element={<CommonProvider><KakaoIndexPage/></CommonProvider> }/>
    <Route key="KbIndexPage" path="/kb/" element={<CommonProvider><KbIndexPage/></CommonProvider> }/>
    <Route key="KtIndexPage" path="/kt/" element={<CommonProvider><KtIndexPage/></CommonProvider> }/>
    <Route key="NewsIndexPage" path="/news/" element={<CommonProvider><NewsIndexPage/></CommonProvider> }/>
    <Route key="Newslist$IdPage" path="/newslist/:id" element={<CommonProvider><Newslist$IdPage/></CommonProvider> }/>
    <Route key="NewslistIndexPage" path="/newslist/" element={<CommonProvider><NewslistIndexPage/></CommonProvider> }/>
    <Route key="PickupIndexPage" path="/pickup/" element={<CommonProvider><PickupIndexPage/></CommonProvider> }/>
    <Route key="Press$IdPage" path="/press/:id" element={<CommonProvider><Press$IdPage/></CommonProvider> }/>
    <Route key="PressIndexPage" path="/press/" element={<CommonProvider><PressIndexPage/></CommonProvider> }/>
    <Route key="RestaurantIndexPage" path="/restaurant/" element={<CommonProvider><RestaurantIndexPage/></CommonProvider> }/>
    <Route key="SatisfactionSurveyIndexPage" path="/satisfaction-survey/" element={<CommonProvider><SatisfactionSurveyIndexPage/></CommonProvider> }/>
    <Route key="WaitingIndexPage" path="/waiting/" element={<CommonProvider><WaitingIndexPage/></CommonProvider> }/>
  </Routes>
</BrowserRouter>;

export default CustomRouter;
