import { slackBotApi } from '@/shared/apis/devServerApi';
import { VALIDATE_EMAIL, VALIDATE_PHONE } from '@/shared/lib/validation';
import { PAGE_CHANNEL_CODE } from '@/shared/utils/slackMessage/slackMessage.constants';
import { FREE_TRIAL_PAGE_TYPE } from '@/shared/utils/survey/survey.constants';
import { useMutation } from 'react-query';

const headers = {
  'Access-Control-Allow-Origin': '*',
};

type TformType = 'name' | 'job' | 'email' | 'tel' | 'coName' | 'coAddr' | 'coTel' | 'coCarrier' | 'gender' | 'phone' | 'birth' | 'federalTaxId' | 'etcCarrier';

type TForm = {
  [key in TformType]?: string;
};

const sendSlackMessageContent = (type: string, ktPageType: string, form: TForm): string => {
  switch (type) {
    case 'kt':
      return `KT AI 통화비서 - ${ktPageType}에서 진입한 고객입니다.
      매장명 : ${form.coName}
      매장 유선번호 : ${form.coTel}
      유선번호 통신사 : ${form.coCarrier !== '기타' ? form.coCarrier : form.etcCarrier}
      대표자 성함 : ${form.name}
      대표자 성별 : ${form.gender}
      휴대폰 번호 : ${form.phone}
      대표자 생년월일 : ${form.birth}
      사업자 등록번호 : ${form.federalTaxId}
      ${form.email ? `이메일 주소 :  ${form.email}` : ''}`;

    case FREE_TRIAL_PAGE_TYPE.WAITING:
      return `[테이블매니저 상담신청 - 대기]
      매장명: ${form.coName}
      대표자 연락처: ${form.tel}
      ${form.email ? `이메일 주소 :  ${form.email}` : ''}
      `;

    case FREE_TRIAL_PAGE_TYPE.PICKUP:
      return `[테이블매니저 상담신청 - 픽업]
      매장명: ${form.coName}
      대표자 연락처: ${form.tel}
      ${form.email ? `이메일 주소 :  ${form.email}` : ''}
      `;

    case FREE_TRIAL_PAGE_TYPE.SURVEY:
      return `[테이블매니저 상담신청 - 만족도 조사]
      매장명: ${form.coName}
      대표자 연락처: ${form.tel}
      ${form.email ? `이메일 주소 :  ${form.email}` : ''}
      `;

    default:
      return `[테이블매니저 상담신청]
      이름 : ${form.name}
      ${form.job ? `직함 : ${form.job}` : ''}
      이메일 : ${form.email}
      휴대폰 : ${form.tel}
      매장명 : ${form.coName}
      ${form.coAddr ? `매장주소 : ${form.coAddr}` : ''}`;
  }
};

const throwErrorAlert = (description: string) => {
  alert(description);
  throw new Error('');
};

const validationForm = (type: string, form: TForm, isCheckedPrivacy: boolean) => {
  switch (type) {
    case 'kt':
      if (!form.coName) {
        throwErrorAlert('매장명을 입력해주세요');
      }
      if (!form.coTel) {
        throwErrorAlert('매장 유선번호를 입력해주세요');
      }
      if (!form.coCarrier || (form.coCarrier === '기타' && !form.etcCarrier)) {
        throwErrorAlert('유선번호 통신사를 선택해주세요');
      }
      if (!form.name) {
        throwErrorAlert('대표자 성함을 입력해주세요');
      }
      if (!form.gender) {
        throwErrorAlert('대표자 성별을 선택해주세요');
      }
      if (!form.phone || !VALIDATE_PHONE.test(form.phone)) {
        throwErrorAlert('휴대폰 번호를 확인해주세요');
      }
      if (!form.birth) {
        throwErrorAlert('대표자 생년월일을 입력해주세요');
      }
      if (!form.federalTaxId) {
        throwErrorAlert('사업자 등록번호을 입력해주세요');
      }
      if (!isCheckedPrivacy) {
        throwErrorAlert('개인정보 수집 및 이용에 동의해주세요');
      }
      if (form.email.length && !VALIDATE_EMAIL.test(form.email)) {
        throwErrorAlert('이메일을 올바르게 입력해주세요.');
      }
      return;

    case FREE_TRIAL_PAGE_TYPE.WAITING:
      if (!form.coName) {
        throwErrorAlert('매장명을 입력해주세요');
      }
      if (!form.tel || !VALIDATE_PHONE.test(form.tel)) {
        throwErrorAlert('대표자 연락처를 확인해주세요');
      }
      if (!isCheckedPrivacy) {
        throwErrorAlert('개인정보 수집 및 이용에 동의해주세요');
      }
      return;

    case FREE_TRIAL_PAGE_TYPE.PICKUP:
      if (!form.coName) {
        throwErrorAlert('매장명을 입력해주세요');
      }
      if (!form.tel || !VALIDATE_PHONE.test(form.tel)) {
        throwErrorAlert('대표자 연락처를 확인해주세요');
      }
      if (!isCheckedPrivacy) {
        throwErrorAlert('개인정보 수집 및 이용에 동의해주세요');
      }
      return;

    case FREE_TRIAL_PAGE_TYPE.SURVEY:
      if (!form.coName) {
        throwErrorAlert('매장명을 입력해주세요');
      }
      if (!form.tel || !VALIDATE_PHONE.test(form.tel)) {
        throwErrorAlert('대표자 연락처를 확인해주세요');
      }
      if (!isCheckedPrivacy) {
        throwErrorAlert('개인정보 수집 및 이용에 동의해주세요');
      }
      return;

    default:
      if (!form.name) {
        throwErrorAlert('이름을 입력해주세요');
      }
      if (!form.email || !VALIDATE_EMAIL.test(form.email)) {
        throwErrorAlert('이메일을 확인해주세요');
      }
      if (!form.tel || !VALIDATE_PHONE.test(form.tel)) {
        throwErrorAlert('연락처를 확인해주세요');
      }
      if (!form.coName) {
        throwErrorAlert('매장명을 입력해주세요');
      }
      if (!isCheckedPrivacy) {
        throwErrorAlert('개인정보 수집 및 이용에 동의해주세요');
      }
  }
};

const postSlackMessage = (type: string, ktPageType?: string, pageType?: string) => {
  return useMutation((form: TForm) => postSlackMessage.fetcher(type, ktPageType, form, pageType), {
    meta: { headers: headers },
    onSuccess: () => {
      alert('상담 신청이 완료 되었습니다.\n테이블매니저 전문 컨설턴트가 연락 드리도록 하겠습니다.');
    },
    onError: () => {
      return alert('정상적으로 처리되지 않았습니다. 관리자에게 문의하세요.');
    },
  });
};

postSlackMessage.fetcher = (type: string, ktPageType: string, form: TForm, pageType: string) =>
  slackBotApi.api.post(`/app/NOTIFICATION/channel/${PAGE_CHANNEL_CODE[type.toUpperCase()]}/message`, {
    text: sendSlackMessageContent(pageType || type, ktPageType, form),
  });

export { postSlackMessage, validationForm };
