import { MediaQuery } from '@/shared/styles';
import React from 'react';
import styled from '@emotion/styled';

export const TabListContainer = styled.div<React.CSSProperties>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77px;
  box-sizing: border-box;
  position: relative;

  ${MediaQuery.mobile} {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 50vw);
    bottom: 0;
    width: 100vw;
    height: 1px;
    background-color: #f2f3f4;
  }
  & > * {
    z-index: 1;
    position: relative;
  }

  .tab {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #12233a;
    padding: 8px;
    cursor: pointer;

    &:first-of-type {
      margin-right: 28px;
    }

    &.selected {
      text-align: center;
      color: #2d60b8;
      border-bottom: 4px solid #2d60b8;
    }
  }
`;
