import { MediaQuery } from '@/shared/styles';
import React from 'react';
import styled from '@emotion/styled';

export const Wrapper = styled.div<React.CSSProperties>`
  margin: 0 auto;
  //padding: 0 80px;
  width: fit-content;
  box-sizing: border-box;

  & > div.container {
    margin: 0 auto;
  }
  ${MediaQuery.tablet} {
    padding: 0;
    width: auto;
  }
`;
