import { getPrivacyUrl } from '@/hooks/usePrivacyForm.hook';
import { CheckPersonalInfoAgreementContainer } from './CheckPersonalInfoAgreement.style';

const CheckPersonalInfoAgreement = ({ type, htmlFor, setChecked }) => {
  const { data: privacyData, isLoading } = getPrivacyUrl(type);
  if (isLoading) return;

  const goToCollectionUsePersonalInfo = () => {
    const privacyUrl = privacyData[0].content.KO.contentUrl;

    if (privacyUrl) {
      window.open(privacyUrl, '_blank', 'noopener,noreferrer');
    } else {
      alert('올바르지 않은 경로입니다.');
    }
  };

  return (
    <CheckPersonalInfoAgreementContainer>
      <input type="checkbox" name={htmlFor} id={htmlFor} onChange={() => setChecked((prev) => !prev)} />
      <label htmlFor={htmlFor}>
        <em onClick={goToCollectionUsePersonalInfo}>개인정보 수집 및 이용</em>에 동의합니다.
      </label>
    </CheckPersonalInfoAgreementContainer>
  );
};

export default CheckPersonalInfoAgreement;
