import React from 'react';
import { ChatbotImageContainerContainer } from '@/components/kakao/ChatbotImageContainer/ChatbotImageContainer.styles';
import PickUpServiceImg from '@/assets/images/pickup/intro_header.json';
import Lottie from 'lottie-react';
import { useSolution } from '@/stores/common/useSolution';
import { TM_POST_CHANNEL } from '@/constants';

const IntroHeader = (): React.ReactElement => {
  const { isSolutionFrame } = useSolution();

  function submit() {
    if (isSolutionFrame) window.parent.postMessage({ type: TM_POST_CHANNEL, data: 'Submit:pickup' }, '*');
    else document.querySelector('#form').scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <ChatbotImageContainerContainer className="pickup-intro-header waiting-intro-header kt-head-img-wrapper">
      <section className="left-area">
        <h4 className="title">픽업까지 한번에</h4>
        <h1 className="description">
          카카오톡으로 포장 <br />
          주문도 관리해보세요
        </h1>
        <div className="bottom-move-button" onClick={submit}>
          <span>솔루션 체험하기</span>
          <img src="/kakao/img/animation_arrow.gif" className="arrow-icon" />
        </div>
      </section>
      <section className="right-area kt-image-wrapper">
        <Lottie className="image" animationData={PickUpServiceImg} />
      </section>
    </ChatbotImageContainerContainer>
  );
};

export default IntroHeader;
