import styled from '@emotion/styled';
import { MediaQuery } from '@/shared/styles';

export const PickUpDetailImg = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  box-sizing: border-box;
  background: #f2f3f4;
  border-radius: 16px;

  ${MediaQuery.mobile} {
    height: auto;
    min-height: 280px;
  }

  .img-content img {
    width: 100%;
    display: block;
  }

  &.integration-management {
    .img-content img {
      max-width: 652px;

      ${MediaQuery.tablet} {
        max-width: 680px;
      }

      ${MediaQuery.mobile} {
        max-width: 280px;
      }
    }
  }

  &.order {
    .img-content img {
      max-width: 800px;

      ${MediaQuery.tablet} {
        max-width: 840px;
      }

      ${MediaQuery.mobile} {
        max-width: 280px;
      }
    }
  }

  &.convenient-use {
    .img-content {
      li {
        width: 100%;
        max-width: 474.31px;
        overflow: hidden;
        border-radius: 16px;

        ${MediaQuery.tablet} {
          max-width: 474px;
        }

        ${MediaQuery.mobile} {
          max-width: 260px;
        }
      }
    }
  }
`;
