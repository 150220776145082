import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import CustomerReviews from '@/components/SatisfactionSurvey/CustomerReviews/CustomerReviews';
import DataDownload from '@/components/SatisfactionSurvey/DataDownload/DataDownload';
import EvaluationResults from '@/components/SatisfactionSurvey/EvaluationResults/EvaluationResults';
import IntroHeader from '@/components/SatisfactionSurvey/IntroHeader/IntroHeader';
import ResponseRate from '@/components/SatisfactionSurvey/ResponseRate/ResponseRate';
import Review from '@/components/SatisfactionSurvey/Review/Review';
import FloatingButton from '@/components/Waiting/FloatingButton/FloatingButton';
import FreeTrialApp from '@/components/Waiting/FreeTrialApp/FreeTrialApp';
import { Wrapper } from '@/components/kakao/Wrapper/Wrapper';
import { FREE_TRIAL_PAGE_TYPE } from '@/shared/utils/survey/survey.constants';

const SatisfactionSurveyHome = () => {
  return (
    <>
      <Header />
      <main>
        <Wrapper>
          <IntroHeader />
          <ResponseRate />
          <Review />
          <EvaluationResults />
          <DataDownload />
          <CustomerReviews />
        </Wrapper>
        <FreeTrialApp parentClass="change-css-survey" freeTrialPageType={FREE_TRIAL_PAGE_TYPE.SURVEY} />
      </main>
      <Footer />
      <FloatingButton title="만족도 조사 서비스 신청" />
    </>
  );
};

export default SatisfactionSurveyHome;
