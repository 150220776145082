import React from 'react';
import styled from '@emotion/styled';
import { MediaQuery } from '@/shared/styles';

export const NewbieContainerContainer = styled.div<React.CSSProperties> `
  text-align: center;
  color: #fff;
  padding: 100px;
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  font-weight:400;
  margin-bottom: 200px;
  ${MediaQuery.tablet} {
    margin-bottom:0;
  }
  

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    bottom: 0;
    background-color: #05153C;
    z-index:0;
    width: 100vw;
  }
  & > * {
    z-index:1;
  }

  & > .title {
    font-weight: 500;
    font-size: 41.6px;
    line-height: 54px;
    margin-bottom:30px;
    ${MediaQuery.tablet} {
      font-size: 17.5px;
      line-height: 24px;
      margin-bottom:12px;
    }
  }

  & > .sub-title {
    font-weight: 500;
    font-size: 51.2px;
    line-height: 67px;
    letter-spacing: -0.05em;
    margin-bottom:48px;
    ${MediaQuery.tablet} {
      font-size: 33.6px;
      line-height: 44px;
      margin-bottom:28px;
    }
  }

  & > .description {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.03em;
    ${MediaQuery.tablet} {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;
