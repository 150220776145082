import HeadTitle from '@/components/HeadTitle/HeadTitle';
import FlexibleDeviceImg from '@/assets/images/waiting/flexible_device.png';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import { WaitingDetailImg } from '../WaitingDetailLayout.styles';

const FlexibleDevice = () => {
  return (
    <SkillContainer>
      <HeadTitle
        subTitle="유연한 기기 사용"
        title={'사용하던 태블릿으로 \n대기 고객 접수'}
        desc={'사용하던 기기로 대기를 받아보세요. \n접수 고객에겐 카카오톡으로 대기번호를 알려드립니다.'}
      />
      <WaitingDetailImg className="flexible-device">
        <ul className="img-content">
          <li>
            <img src={FlexibleDeviceImg} alt="유연한 기기 사용 서비스 소개 영상" />
          </li>
        </ul>
      </WaitingDetailImg>
    </SkillContainer>
  );
};

export default FlexibleDevice;
