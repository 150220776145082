type TPageNames = 'about' | 'corp' | 'franchise' | 'news' | 'press' | 'newslist' | 'restaurant' | 'form';

type TPageList = {
  [key in TPageNames]: string;
};

const PAGE_LIST: TPageList = {
  about: '회사소개',
  corp: '공공・의료・레저',
  franchise: '프랜차이즈',
  restaurant: '레스토랑',
  news: '뉴스룸',
  press: '뉴스룸',
  newslist: '뉴스룸',
  form: '상담신청',
};

export const changeTitle = (path?: string) => {
  document.title = path ? `테이블매니저 - ${PAGE_LIST[path.replace(/\//g, '')]}` : '테이블매니저 - 1위 예약•대기•고객관리 솔루션 기업';
};
