import { FloatingButtonContainer } from '@/components/kakao/FloatingButton/FloatingButton.styles';
import React from 'react';
import { useSolution } from '@/stores/common/useSolution';
import { SUBMIT_TYPE, TM_POST_CHANNEL } from '@/constants';

const FloatingButton = (props): React.ReactElement => {
  const { title } = props;
  const { isSolutionFrame } = useSolution();

  function submit() {
    if (!isSolutionFrame) document.querySelector('#form').scrollIntoView({ behavior: 'smooth' });
    else window.parent.postMessage({ type: TM_POST_CHANNEL, data: `Submit:${SUBMIT_TYPE[title]}` }, '*');
  }

  return (
    <FloatingButtonContainer onClick={submit}>
      <span>{title}</span>
    </FloatingButtonContainer>
  );
};

export default FloatingButton;
