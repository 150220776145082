import * as React from 'react';

import { ChatbotImageContainerContainer } from '@/components/kakao/ChatbotImageContainer/ChatbotImageContainer.styles';
import MainImage from '@/assets/images/KTcallAssistant/main.png';

const MailHeaderImage = (): React.ReactElement => {
  function moveHomepage() {
    document.querySelector('#form').scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <ChatbotImageContainerContainer className="kt-head-img-wrapper">
      <section className="left-area">
        <h4 className="title">KT AI 통화비서 공식 파트너</h4>
        <h1 className="description">
          아직도 전화를
          <br />
          사람이 받나요?
        </h1>
        <div className="bottom-move-button" onClick={moveHomepage}>
          <span>AI 통화비서 신청하기</span>
          <img src="/kakao/img/animation_arrow.gif" className="arrow-icon" />
        </div>
        {/* <button className="bottom-move-button on-handheld" onClick={moveHomepage}>
          AI 통화비서 신청하기
        </button> */}
      </section>
      <section className="right-area kt-image-wrapper">
        <img className="image" src={MainImage} alt="KT AI 통화비서 메인 이미지" />
      </section>
    </ChatbotImageContainerContainer>
  );
};

export default MailHeaderImage;
