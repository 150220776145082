import React from 'react';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import HeadTitle from '@/components/HeadTitle/HeadTitle';
import { SatisfactionSurveyImg } from '../SatisfactionSurveyLayout.styles';
import EvaluationResultsImg from '@/assets/images/survey/evaluation_results.png';

const EvaluationResults = (): React.ReactElement => {
  return (
    <SkillContainer>
      <HeadTitle
        className="evaluation-results-head-title"
        subTitle="한눈에 파악하는 고객 경험"
        title={'수집한 평가결과는 \n한눈에 비교'}
        desc="여러 매장의 조사를 수집하고, 기간별, 매장별 조사 결과를 모아 볼 수 있어요. 필터를 적용해 다양한 방식으로 고객 경험을 관리해보세요."
      />
      <SatisfactionSurveyImg className="evaluation-results">
        <ul className="img-content">
          <li>
            <img src={EvaluationResultsImg} alt="한눈에 파악하는 고객 경험 서비스 소개" />
          </li>
        </ul>
      </SatisfactionSurveyImg>
    </SkillContainer>
  );
};

export default EvaluationResults;
