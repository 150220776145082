import { MediaQuery } from '@/shared/styles';
import styled from '@emotion/styled';

export const CheckPersonalInfoAgreementContainer = styled.div`
  width: fit-content;
  margin: 22px 0 2px;

  ${MediaQuery.mobile} {
    margin: 28px 0 8px;
  }

  input[type='checkbox'] {
    display: none;

    & + label:before {
      content: '';
      display: block;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      border: 1.5px solid rgba(18, 35, 58, 0.6);
      background-color: #fff;
      border-radius: 3px;
      box-sizing: border-box;
    }

    &:checked + label:before {
      border-color: transparent;
      background-color: #2d60b8;
      background-image: url("data:image/svg+xml,%3Csvg width='28' height='21' viewBox='0 0 28 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.5758 3.28215L24.666 0.372437L10.5239 14.5146L3.25128 7.24195L0.422852 10.0704L10.6045 20.252L27.5758 3.28215Z' fill='white' /%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px 9px;
    }
  }

  label {
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #12233a99;
    cursor: pointer;

    em {
      font-style: normal;
      color: #2d60b8;
      text-decoration: underline;
    }
  }
`;
