import React from 'react';
import { PickUpDetailImg } from '@/components/PickUp/PickUpDetailLayout.styles';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import HeadTitle from '@/components/HeadTitle/HeadTitle';
import OrderImg from '@/assets/images/pickup/order.png';

const Order = (): React.ReactElement => {
  return (
    <SkillContainer>
      <HeadTitle
        className="order-head-title"
        subTitle="한눈에 펼쳐보는 주문현황"
        title="한눈에 보는 간편 주문 관리"
        desc={'오늘 우리 가게에 들어온 픽업 주문, \n한 페이지에서 모두 확인할 수 있어요.'}
      />
      <PickUpDetailImg className="order">
        <ul className="img-content">
          <li>
            <img src={OrderImg} alt="한눈에 보는 간편 주문 관리 서비스 소개" />
          </li>
        </ul>
      </PickUpDetailImg>
    </SkillContainer>
  );
};

export default Order;
