import React from 'react';
import styled from '@emotion/styled';
import { StyledContainer } from '@/components/kakao/StyledContainer/StyledContainer.styles';
import { MediaQuery } from '@/shared/styles';

export const EffectContainerContainer = styled.div<React.CSSProperties> `
  padding-top: 200px;
  ${StyledContainer};
  ${MediaQuery.tablet} {
    background-color: #2847A4;
  }
  .containerInner.effect-box {
    ${MediaQuery.tablet} {
      * {
        color: #ffffff !important;
      }
    }
    .containerBody.icon-box-list {
      display: flex;
      background-color: #2847A4;
      padding: 100px 92.5px;
      box-sizing:border-box;
      gap: 40px;
      border-radius: 20px;
      align-items:flex-start;
      ${MediaQuery.tablet} {
        gap: 100px;
        padding: 0;
        flex-direction:column;
        align-items:center;
      }
      .icon-box {
        display:flex;
        flex-direction:column;
        justify-content:center;
        ${MediaQuery.tablet} {
          align-items:center;
        }
        flex: 0.33333;
        
        .icon {
          align-self:center;
          display: flex;
          align-items: center;
          
          width: 192px;
          height: 156px;
          margin-bottom: 24px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
          ${MediaQuery.tablet} {
            margin-bottom: 40px;
          }

          &.computer {
            background-image: url(/kakao/img/img_computer_d.svg);
          }

          &.phone2 {
            background-image: url(/kakao/img/img_mobile_d.svg);
            margin-left: 35px;
            ${MediaQuery.tablet} {
              margin-left:0px;
              align-self:center;
            }
          }

          &.zero {
            background-image: url(/kakao/img/img_number_d.svg);
          }
        }

        .icon-description {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.01em;
          color: rgba(255,255,255, 0.75);
          word-break:break-word;
          ${MediaQuery.tablet} {
            text-align:center;
          }
        }
      }
    }
  }
`;
