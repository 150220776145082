import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import { Wrapper } from '@/components/kakao/Wrapper/Wrapper';
import CustomerManagement from '@/components/Waiting/CustomerManagement/CustomerManagement';
import DataUtilization from '@/components/Waiting/DataUtilization/DataUtilization';
import FlexibleDevice from '@/components/Waiting/FlexibleDevice/FlexibleDevice';
import FloatingButton from '@/components/Waiting/FloatingButton/FloatingButton';
import FreeTrialApp from '@/components/Waiting/FreeTrialApp/FreeTrialApp';
import IntroHeader from '@/components/Waiting/IntroHeader/IntroHeader';
import PreventCustomerChurn from '@/components/Waiting/PreventCustomerChurn/PreventCustomerChurn';
import ViewMode from '@/components/Waiting/ViewMode/ViewMode';
import { FREE_TRIAL_PAGE_TYPE } from '@/shared/utils/survey/survey.constants';

const WaitingHome = () => {
  return (
    <>
      <Header />
      <main>
        <Wrapper>
          <IntroHeader />
          <PreventCustomerChurn />
          <CustomerManagement />
          <ViewMode />
          <FlexibleDevice />
          <DataUtilization />
        </Wrapper>
        <FreeTrialApp freeTrialPageType={FREE_TRIAL_PAGE_TYPE.WAITING} />
      </main>
      <Footer />
      <FloatingButton title="대기 서비스 신청" />
    </>
  );
};

export default WaitingHome;
