export const KT_AI_CALL_MANAGER_PAGE_TYPE = {
  TM: 'tablemanager',
  KB: 'kb',
  CASH_NOTE: 'cashNote',
} as const;

export const ENTRY_PAGE_SELECTOR = {
  [KT_AI_CALL_MANAGER_PAGE_TYPE.KB]: 'KB스타샵',
  [KT_AI_CALL_MANAGER_PAGE_TYPE.TM]: '테이블매니저',
  [KT_AI_CALL_MANAGER_PAGE_TYPE.CASH_NOTE]: '캐시노트',
};
