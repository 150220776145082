import { MediaQuery } from '@/shared/styles';
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IIsCashNotePage extends React.CSSProperties {
  isCashNotePage?: boolean | null;
}

export const BenefitsContainer = styled.article`
  margin-block: 200px;
  padding-block: 100px;
  position: relative;
  ${MediaQuery.tablet} {
    max-width: calc(100% - 6% - 6%);
    margin-inline: auto;
  }
  ${MediaQuery.mobile} {
    max-width: calc(100% - 6.66% - 6.66%);
    margin-block: 140px;
  }

  &:before {
    content: '';
    width: 100vw;
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    bottom: 0;
    background-color: #05153c;
    z-index: -1;
  }
`;

export const BenefitsDesc = styled.section<IIsCashNotePage>`
  h3 {
    padding-bottom: 30.58px;
    font-weight: 700;
    font-size: 41.6px;
    line-height: 54px;
    text-align: center;
    color: #ffffff;

    ${MediaQuery.tablet} {
      font-size: 36.4px;
      line-height: 47px;
    }
    ${MediaQuery.mobile} {
      padding-bottom: 12px;
      font-size: 17.5px;
      line-height: 24px;
    }
  }
  h2 {
    margin-bottom: 0;
    padding-bottom: 48px;
    font-weight: 700;
    font-size: 51.2px;
    line-height: 67px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #ffffff;

    ${MediaQuery.tablet} {
      font-size: 44.8px;
      line-height: 58px;
    }
    ${MediaQuery.mobile} {
      font-size: 33.6px;
      line-height: 44px;
      padding-bottom: 28px;
    }
  }
  p {
    margin: 0;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.03em;
    color: #fff;

    ${MediaQuery.tablet} {
      font-size: 21px;
      line-height: 30px;
    }
    ${MediaQuery.mobile} {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }

    ${(props) =>
      props.isCashNotePage &&
      css`
        max-width: 674px;
        margin-inline: auto;

        ${MediaQuery.tablet} {
          max-width: 590px;
        }
        ${MediaQuery.mobile} {
          max-width: 390px;
        }
      `}
  }
`;

export const BenefitsArrow = styled.section`
  margin-block: 48px;
  text-align: center;
  svg {
    width: 40px;
    height: 40px;
    vertical-align: -webkit-baseline-middle;

    > path {
      fill: #3e4e69;
    }

    ${MediaQuery.mobile} {
      width: 32px;
      height: 32px;
    }
  }

  ${MediaQuery.mobile} {
    margin-block: 28px;
  }
`;

export const BenefitItems = styled.section`
  width: fit-content;
  padding: 40px 60px;
  box-sizing: border-box;
  white-space: pre-wrap;
  background-color: #2847a4;
  border-radius: 20px;
  margin: 0 auto;

  ${MediaQuery.mobile} {
    width: 100%;
    padding: 30px 20px;
  }

  ul {
    display: flex;
    gap: 80px;

    ${MediaQuery.mobile} {
      gap: 40px;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li.content {
      display: flex;
      align-items: center;

      &:before {
        content: '';
        flex-shrink: 0;
        flex-grow: 0;
        width: 48px;
        height: 48px;
        border-radius: 100px;
        margin-right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #12233a;
        background-image: url("data:image/svg+xml,%3Csvg width='28' height='21' viewBox='0 0 28 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.5758 3.28215L24.666 0.372437L10.5239 14.5146L3.25128 7.24195L0.422852 10.0704L10.6045 20.252L27.5758 3.28215Z' fill='white'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 27.15px 19.88px;

        ${MediaQuery.mobile} {
          width: 32px;
          height: 32px;
          margin-right: 16px;
          background-size: 18.1px 13.25px;
        }
      }

      .text-wrap {
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.03em;
        color: rgba(255, 255, 255, 0.6);

        ${MediaQuery.mobile} {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
        }

        em {
          display: block;
          padding-top: 4px;
          font-weight: 700;
          font-style: normal;
          color: rgba(255, 255, 255, 1);

          ${MediaQuery.mobile} {
            font-weight: 700;
            font-size: 17.5px;
            line-height: 24px;
          }
        }
      }
    }
  }
`;

export const InformationContainer = styled.article`
  max-width: 1100px;
  ${MediaQuery.tablet} {
    max-width: calc(100% - 6% - 6%);
    margin-inline: auto;
  }
  ${MediaQuery.mobile} {
    max-width: 100%;
  }
`;
