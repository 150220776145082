import '@/styles/corp.scss';

import Consultation from '@/components/Consultation/Consultation';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import Partners from '@/components/Partners/Partners';
import { changeTitle } from '@/shared/lib/functions/change-title';
import { useSolutionPopup } from '@/stores/common/useSolutionPopup';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Corp = () => {
  const { pathname } = useLocation();
  const { setSolutionModal } = useSolutionPopup();

  useEffect(() => {
    changeTitle(pathname);
  }, []);

  return (
    <>
      <Header />
      <main className="sub corp">
        <div className="wrap">
          <div className="inner">
            <div className="TitBox">
              <h4>고객과 함께 TableManager</h4>
              <h1>
                공공·의료·레저 CRM 파트너
                <br />
                TableManager
              </h1>
              <div className="gap60" />
              <div className="btnBox">
                <button className="defBtn popupOpen" onClick={() => setSolutionModal(true)}>
                  상담 신청하기
                </button>
              </div>
              <div className="gapDef" />
            </div>
          </div>
          <section className="corpPhoneList">
            <ul className="corpMain">
              <li>
                <img src={require('@/assets/images/corp/others01.jpg')} className="phone_xs" alt="액티비티 오산버드파크 이미지" />
                <h3>액티비티, 체험</h3>
              </li>
              <li data-wow-delay="0.2s">
                <img src={require('@/assets/images/corp/others02.png')} className="phone_xs" alt="대한법률구조공단 법률상담 이미지" />
                <h3>법률상담</h3>
              </li>
              <li data-wow-delay="0.4s">
                <img src={require('@/assets/images/corp/others03.jpg')} className="phone_xs" alt="서울노원 설명한의원 의료기관 예약 이미지" />
                <h3>의료기관</h3>
              </li>
            </ul>
          </section>
          <div className="gapDef" />
          <Partners srcType="corp" />
        </div>
        <div className="wrap">
          <div className="gapDef" />
          <div className="inner">
            <h2>
              모든 예약 비즈니스에서 기능하는 <br />
              간편한 모바일 예약
            </h2>
            <div className="imgTxt corpImgTxt">
              <img src={require('@/assets/images/corp/others_func@2x.jpg')} className="w100" alt="카카오톡을 통한 대한법률구조공단 예약화면" />
              <div className="box">
                <h3>테이블매니저는 예약정책과 CRM이 필요한 모든 영역에 적용 가능한 솔루션입니다.</h3>
                <p>기존 예약관리 채널에 더해서 카카오 챗봇과 같은 온라인 예약 채널을 신설하세요.</p>
                <p>더 많은 고객이 더 편리하게 이용할 수 있습니다. 기존에 예약이 인입되던 채널과 테이블매니저의 새로운 서비스로 추가되는 채널이 충돌 없이 구동됩니다.</p>
              </div>
            </div>
            <div className="gapDef" />
          </div>
        </div>

        <div className="wrap colorBack">
          <div className="gapDef" />
          <div className="inner">
            <div className="imgTxt reverse">
              <img src={require('@/assets/images/fran01.jpg')} className="tablet" alt="수요예측 AI 분석화면" />
              <div className="box">
                <h2>
                  효과적인 고객 관리
                  <br />
                  강력한 마케팅 효과
                </h2>
                <p>테이블매니저를 통해 더 많은 고객과 친구를 맺고 고객의 방문일자와 특성에 따른 고객 관리 서비스를 제공하세요.</p>
                <p>카카오 챗봇 예약을 이용하는 고객들에게는 카카오톡 메시지 기능으로 효율적인 CRM을 통해 마케팅 효과를 높일 수 있습니다.</p>
              </div>
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap">
          <div className="gapDef" />
          <div className="inner">
            <div className="imgTxt">
              <div className="box">
                <h2>
                  도입 분야별로 특화된 <br />
                  컨설팅과 기획 서비스 제공
                </h2>
                <p>테이블매니저는 이미 법률상담, 공공, 의료, 레저, 식품 분야의 파트너들과 협업하여 각 분야에 특화된 서비스를 제공한 경험을 가지고 있습니다.</p>
                <p>테이블매니저의 예약 관리 솔루션은 예약, 고객관리, 데이터 그리고 마케팅이 연결되는 모든 비즈니스에 적용할 수 있습니다.</p>
                <p>파트너십 체결을 희망하시는 분들은 상담 신청을 남겨주세요.</p>
                <div className="gap60" />
                <button className="defBtn popupOpen" onClick={() => setSolutionModal(true)}>
                  상담 신청하기
                </button>
              </div>
              <img src={require('@/assets/images/icon/others-function-03.svg').default} className="corpServiceImg" alt="다양한 예약을 통합관리하는 테이블매니저" />
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap">
          <div className="parallax para03">
            <div className="paraBox">
              <h4 className="point">성공 스토리</h4>
              <p>
                최근 가장 빠르게 성장하는 공공·의료·레저분야도 <br />
                테이블매니저를 선택했습니다.
              </p>
              <img className="serif" src={require('@/assets/images/icon/icon@2x.png')} alt="따옴표 아이콘" />
              <h2>
                테이블매니저 솔루션에 만족하고 있습니다. 특히 환자분 전화를 받기 전에 어느 원장님께 어떤 진료를 받았는지를 바로 확인할 수 있고 진료 예약 완료 메시지를 보낼 수
                있다는 점도 만족스럽습니다.
              </h2>
              <div className="picReview">
                <div className="left">
                  <h5>
                    설명한의원 노원점
                    <br />
                    이정림 실장
                  </h5>
                </div>
                <div className="right">
                  <img src={require('@/assets/images/logo/others-sub-logo.png')} alt="설명 한의원 로고" />
                </div>
              </div>
            </div>
          </div>
          <div className="gapDef" />
        </div>
        <Consultation />
      </main>
      <Footer />
    </>
  );
};

export default Corp;
