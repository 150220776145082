import React from 'react';
import { IEffectContainer } from './EffectContainer.type';
import {} from './EffectContainer.constants';
import { EffectContainerContainer } from './EffectContainer.styles';
//import classnames from 'classnames';

const EffectContainer = (): React.ReactElement => {
  return (
    <EffectContainerContainer id="effect">
      <div className="containerInner effect-box">
        <div className="title">효과</div>
        <div className="sub-title">예약접수, 고객관리, 브랜드 마케팅까지 한 번에</div>
        <div className="description">카카오톡 채널 매장 연동, 카카오톡을 활용한 마케팅, 편리한 예약 관리 등 사장님 입장에서 실질적인 도움이 되는 다양한 서비스 제공</div>
        <div className="containerBody icon-box-list">
          <div className="icon-box">
            <div className="icon computer" />
            <div className="icon-description">카카오톡 채널을 통해 매장 소식을 홍보할 수 있고, 예약 접수부터 리마인드 메시지까지 실시간으로 알림톡을 발송해 드립니다.
            </div>
          </div>
          <div className="icon-box">
            <div className="icon phone2" />
            <div className="icon-description">전화, 카카오톡 챗봇 등 여러 경로로 들어오는 예약을 기존에 사용하던 컴퓨터나 휴대폰에서 한눈에 관리하세요.
            </div>
          </div>
          <div className="icon-box">
            <div className="icon zero" />
            <div className="icon-description">수수료 0원으로 입점 부담은 물론 비용 걱정 없이 이용하세요.</div>
          </div>
        </div>
      </div>
    </EffectContainerContainer>
  );
};

export default EffectContainer;
