import {} from './ChatbotImageContainer.constants';

import { ChatbotImageContainerContainer } from './ChatbotImageContainer.styles';
import { IChatbotImageContainer } from './ChatbotImageContainer.type';
import React from 'react';
//import classnames from 'classnames';

const ChatbotImageContainer = (props: IChatbotImageContainer): React.ReactElement => {
  function moveHomepage() {
    document.querySelector('#form').scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <ChatbotImageContainerContainer>
      <div className="left-area">
        <div className="title">카카오톡 채널 챗봇 공식 에이전시</div>
        <div className="description">
          챗봇 예약 비즈니스의
          <br />
          성공 파트너
        </div>
        <div className="bottom-move-button on-desktop" onClick={moveHomepage}>
          <span>솔루션 체험하기</span>
          <img src="/kakao/img/animation_arrow.gif" className="arrow-icon" />
        </div>
        <button className="bottom-move-button on-handheld" onClick={moveHomepage}>
          카카오톡 챗봇예약 도입하기
        </button>
      </div>
      <div className="right-area">
        <video className="image" width="321" autoPlay={true} loop={true} playsInline muted>
          <source src="/kakao/videos/video1.mp4" type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
    </ChatbotImageContainerContainer>
  );
};

export default ChatbotImageContainer;
