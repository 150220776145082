import { MediaQuery } from '@/shared/styles';
import React from 'react';
import styled from '@emotion/styled';

export const StepContainerContainer = styled.div<React.CSSProperties>`
  max-width: 700px;
  margin: 200px auto;
  ${MediaQuery.tablet} {
    padding: 0 24px 140px;
    box-sizing: border-box;
  }
  ${MediaQuery.mobile} {
    margin-top: 140px;
    margin-bottom: 0;
  }

  &#howToUse {
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 200px;

    ${MediaQuery.mobile} {
      max-width: calc(100% - 6.66% - 6.66%);
      padding-inline: 0;
      padding-top: 140px;
      margin-bottom: 140px;
      margin-inline: auto;
    }
  }

  & > .chatbot-introduction-container {
    margin-bottom: 120px;

    ${MediaQuery.mobile} {
      margin-bottom: 56px;
    }
    .title {
      padding-bottom: 24px;
      font-weight: 700;
      font-size: 51.2px;
      line-height: 67px;
      text-align: center;
      letter-spacing: -0.04em;
      color: #12233a;

      ${MediaQuery.tablet} {
        font-size: 44.8px;
        line-height: 58px;
      }
      ${MediaQuery.mobile} {
        > br {
          display: none;
        }
        padding-bottom: 28px;
        font-size: 28px;
        line-height: 36px;
        text-align: left;
      }
    }

    .description {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #12233a;

      ${MediaQuery.tablet} {
        font-size: 21px;
        line-height: 30px;
      }
      ${MediaQuery.mobile} {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: rgba(18, 35, 58, 0.6);
        text-align: left;
      }
    }
  }

  .step-box {
    padding: 40px 0px;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 0 auto;
    ${MediaQuery.tablet} {
      align-items: flex-start;
      text-align: left;
      padding: 0 0 30px 0;
      margin: 0 auto 30px;
    }

    &:first-of-type {
      border-top: 1px solid #e5e5e5;
    }

    .icon {
      flex-shrink: 0;
      flex-grow: 0;
      width: 48px;
      height: 48px;
      background-color: #12233a;
      border-radius: 100px;
      margin-right: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      ${MediaQuery.mobile} {
        width: 32px;
        height: 32px;
        font-size: 16px;
        margin-right: 16px;
      }
      &.yellow {
        background-color: #fece38;
        background-image: url("data:image/svg+xml,%3Csvg width='28' height='21' viewBox='0 0 28 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.5758 3.28215L24.666 0.372437L10.5239 14.5146L3.25128 7.24195L0.422852 10.0704L10.6045 20.252L27.5758 3.28215Z' fill='white'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 27.15px 19.88px;
        font-size: 0;
        ${MediaQuery.mobile} {
          background-size: 18.1px 13.25px;
        }
      }
    }

    .content {
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.03em;
        color: #12233a;
        margin-bottom: 18px;
        ${MediaQuery.tablet} {
          margin-bottom: 12px;
          font-size: 21px;
          line-height: 30px;
        }
        ${MediaQuery.mobile} {
          font-size: 17.5px;
          line-height: 24px;
        }
      }

      .description {
        color: rgba(8, 35, 58, 0.6);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        ${MediaQuery.tablet} {
          font-size: 14px;
          line-height: 21px;
        }
        ${MediaQuery.mobile} {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  .bottom-move-button {
    display: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    width: 197px;
    height: 48px;

    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 8px;
    background-color: #12233a;
    cursor: pointer;
    padding: 0 5px !important;

    .arrow-icon {
      margin-left: 16px;
      width: 48px;
    }
    ${MediaQuery.tablet} {
      display: flex;
      bottom: 40px;
      left: 20px;
      right: 20px;
      width: unset;
      z-index: 2;
      & > span {
        margin-left: 20px;
      }
      & > img {
        margin-left: auto !important;
      }
    }
  }
`;
