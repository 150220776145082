import HeadTitle from '@/components/HeadTitle/HeadTitle';
import DownloadIntroduction from '../DownloadIntroduction/DownloadIntroduction';
import DataUtilizationImg from '@/assets/images/waiting/data_utilization.png';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import { WaitingDetailImg } from '../WaitingDetailLayout.styles';

const DataUtilization = () => {
  return (
    <SkillContainer>
      <HeadTitle subTitle="대기 데이터 활용" title="대기 데이터 주체는 레스토랑" desc={'대기고객의 정보를 레스토랑이 직접 \n활용할 수 있어요.'} />
      <WaitingDetailImg className="data-utilization">
        <ul className="img-content">
          <li>
            <img src={DataUtilizationImg} alt="대기 데이터 활용 서비스 소개 영상" />
          </li>
        </ul>
      </WaitingDetailImg>
      <DownloadIntroduction />
    </SkillContainer>
  );
};

export default DataUtilization;
