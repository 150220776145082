type TPageType = 'A' | 'B';

type TCategory = {
  [key in TPageType]: string;
};

export const CATEGORY_DATA: TCategory = {
  A: '보도자료',
  B: '업계뉴스',
};

export const CATEGORY_MAP: TCategory = {
  A: 'press',
  B: 'newslist',
};
