import '@/styles/restaurant.scss';

import Estimate from '@/components/Estimate/Estimate';
import Footer from '@/components/Footer/Footer';
import FreeExperience from '@/components/FreeExperience/FreeExperience';
import Header from '@/components/Header/Header';
import { changeTitle } from '@/shared/lib/functions/change-title';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSolutionPopup } from '@/stores/common/useSolutionPopup';

const Restaurant = () => {
  const { pathname } = useLocation();
  const { setSolutionModal } = useSolutionPopup();

  useEffect(() => {
    changeTitle(pathname);
  }, []);

  return (
    <>
      <Header />
      <main className="sub">
        <div className="wrap">
          <div className="inner">
            <div className="TitBox">
              <div className="left">
                <h4>예약 관리 솔루션</h4>
                <h1>
                  레스토랑 예약
                  <br />
                  비즈니스 파트너
                </h1>
                <p>
                  언제 어디서나 어떤 기기로든 사용 가능한 <br />
                  예약 관리 솔루션 TableManager는 별도 기기나 <br />
                  POS 없이 바로 시작할 수 있습니다.
                </p>
                <div className="btnBox">
                  <button className="defBtn popupOpen" onClick={() => setSolutionModal(true)}>
                    상담 신청하기
                  </button>
                </div>
              </div>
              <div className="right">
                <div className="setImg">
                  <img src={require('@/assets/images/reservTablet.png')} className="tablet" alt="테블릿 예약화면" />
                  <img src={require('@/assets/images/reservPhone1.png')} className="phone_xs" alt="스마트폰 예약화면" />
                </div>
              </div>
              <div className="gapDef" />
            </div>
          </div>
          <img src={require('@/assets/images/restaurant/rest-main-big.jpg')} className="w100" alt="만요 음식 사진" />
        </div>
        <div className="wrap">
          <div className="gapDef" />
          <div className="inner">
            <h2>
              수기 장부를 없애 주는 <br />
              스마트한 예약관리와 고객 서비스
            </h2>
            <div className="imgTxt reverse restaurantService">
              <img src={require('@/assets/images/restaurant/rest01.png')} className="phone_s" alt="문자 안내발송" />
              <img src={require('@/assets/images/restaurant/rest02.png')} className="phone_s" alt="카카오 안내발송" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>실시간 예약확정 시스템</h3>
                    </dt>
                    <dd>레스토랑에서 예약을 하나 하나 컨펌할 필요 없이 실시간으로 예약이 확정됩니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>예약이 확정되면 자동 발송되는 안내 메시지</h3>
                    </dt>
                    <dd>주차 공간 안내, 주의사항 고지 등 고객에게 전달하고 싶은 사항을 자유롭게 반영할 수 있습니다.</dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>

          <div className="gapDef" />
          <div className="gapDef" />
          <div className="inner sectionTitleWrap">
            <h4 className="point">예약 통합관리</h4>
            <h2>
              오버부킹 없는
              <br />
              예약채널 통합관리
            </h2>
            <div className="imgTxt managerReserv">
              <div className="setImg">
                <img src={require('@/assets/images/reservPhone1.png')} className="phoneImg" alt="스마트폰 예약화면" />
                <img src={require('@/assets/images/reservPhone2.png')} className="phoneImg frontPhone" alt="스마트폰 예약화면" />
              </div>
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>네이버나 카카오톡으로 쉽게 예약</h3>
                    </dt>
                    <dd>네이버, 카카오 챗봇 예약으로 더 많은 예약을 프라이빗하게 받아 보세요. </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>예약을 자동으로 확정하여 이중 예약 방지</h3>
                    </dt>
                    <dd>예약 관리 솔루션 TableManager로 네이버, 카카오톡 등 온라인뿐만 아니라 전화까지 모든 채널의 예약을 간편하게 관리하세요.</dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap colorBack">
          <div className="gapDef" />
          <div className="inner">
            <div className="col3box">
              <h2>방문 고객을 단골로 만드는 주소록 생성과 메시지 마케팅</h2>
              <img src={require('@/assets/images/restaurant/rest03.png')} className="phone" alt="카카오톡을 통한 이벤트 발송" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>고객 주소록 자동생성</h3>
                    </dt>
                    <dd>수동으로 작업할 필요 없이 예약관리 솔루션 TableManager에서 고객 휴대폰 번호를 자동으로 수정합니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>쉽게 보내는 이벤트와 공지사항</h3>
                    </dt>
                    <dd>
                      별도의 엑셀 작업 없이 자동으로 고객 특성(방문 횟수, 방문 일자 등)이 분류됩니다. 한 번의 클릭으로 카카오톡 친구에게 프로모션 메시지를 발송할 수 있습니다.
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap">
          <div className="gapDef" />
          <div className="inner">
            <h2>
              예약 · 판매 데이터에 기반한 <br />
              최적의 의사 결정 지원
            </h2>
            <div className="imgTxt reverse">
              <img src={require('@/assets/images/restaurant/rest04.png')} className="tablet" alt="예약, 판매 테이터 분석" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>예약상품권 마케팅으로 매장 매출 극대화</h3>
                    </dt>
                    <dd>
                      예약 · 판매 흐름을 파악하고 수요예측AI를 통해 일주일 뒤의 빈자리 수를 예측할 수 있습니다. 최적화된 빈자리 가격 혜택을 제공하는 예약상품권 마케팅으로 매출을
                      올려보세요.
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>판매 데이터 분석을 통한 효율적인 매장 운영</h3>
                    </dt>
                    <dd>
                      특허 받은 수요예측AI를 활용해 판매 예측 결과에 따라 필요한 만큼 식자재를 주문하고, 매장 인력을 운용할 수 있습니다. 효율적인 매장 운영으로 비용 절감 효과를
                      누려보세요.
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap">
          <div className="gapDef" />
          <div className="inner sectionTitleWrap">
            <h4 className="point">빠른 적용과 쉬운 사용법</h4>
            <h2>
              언제 어디서든 누구나 <br />
              쉽게 사용
            </h2>
            <article className="easyUseProduct inner">
              <section>
                <img src={require('@/assets/images/main_dashboard2.png')} alt="테이블 매니저 사용화면" />
                <dl>
                  <dt>
                    <h3>익숙하고 쉬운 UX</h3>
                  </dt>
                  <dd>익숙한 POS 화면과 유사한 인터페이스로 누구나 쉽게 솔루션을 사용할 수 있습니다.</dd>
                </dl>
              </section>
              <section>
                <dl>
                  <dt>
                    <h3>본인 기기로 어디서나 접속 가능</h3>
                  </dt>
                  <dd>
                    모바일, 태블릿, 노트북 등 가지고 계신 기기를 이용해서 테이블매니저를 사용할 수 있습니다. 사무실에 있는 매니저도, 출장 중인 사장님도 인터넷만 되면 테이블매니저의
                    예약현황을 확인하고 관리할 수 있습니다.
                  </dd>
                </dl>
                <img src={require('@/assets/images/main_dashboard1.png')} alt="테이블 매니저 사용화면" />
              </section>
            </article>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap">
          <div className="parallax para04">
            <div className="paraBox">
              <h4 className="point">
                TableManager, <br />
                사용해 보니 어떠셨나요?
              </h4>
              <p>다시는 종이 예약 장부로 돌아가지 못할 것 같습니다. 고객 관리가 훨씬 편해졌고, 특히 저희의 요구사항을 처리해주시는 테이블매니저의 업무 속도는 최고였습니다!</p>
              <p>
                고객들의 취향, 이전 요청사항, 방문 이력 등을 미리 파악하고 고객 응대를 할 수 있다는 점이 테이블매니저 솔루션의 가장 큰 장점 같습니다. 맞춤형 고객 응대를 해드리면
                단골 손님들이 너무 좋아하시거든요.
              </p>
              <div className="picReview">
                <div className="left">
                  <h5>
                    수담한정식
                    <br />
                    최세정 예약실장
                  </h5>
                </div>
                <div className="right">
                  <img src={require('@/assets/images/logo/rest-logo.png')} alt="수담 로고" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap">
          <div className="gapDef" />
          <Estimate />
        </div>
        <div className="gapDef" />
        <FreeExperience />
      </main>
      <Footer />
    </>
  );
};

export default Restaurant;
