import React from 'react';
import { IEnterContainer } from './EnterContainer.type';
import {} from './EnterContainer.constants';
import { EnterContainerContainer } from './EnterContainer.styles';
//import classnames from 'classnames';

const EnterContainer = (props: IEnterContainer): React.ReactElement => {
  return (
    <EnterContainerContainer>
      <div className="containerInner introduce">
        <div className="title">테이블매니저 소개</div>
        <div className="sub-title">인공지능기반(AI) 디지털 예약 관리,<br />테이블매니저</div>
        <div className="description">카카오톡 Biz 채널에서 예약 기능을 제공하세요. 고객은 간단한 동작으로<br/>방문 장소, 시간, 인원을 선택하여 예약하고, 확정된 예약에 대해서는 알림톡 기능을 제공합니다.</div>
        <div className="containerBody">
          <video className="image" width="321" autoPlay={true} loop={true} playsInline muted>
            <source src="/kakao/videos/video2.mp4" type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>
      </div>
    </EnterContainerContainer>
  );
};

export default EnterContainer;
