const CountNumber = () => {
  return (
    <div className="aboutCount" id="aboutCount">
      {/* <li>
          <h2 className="point">
            <span>1</span>위
          </h2>
          <h3>예약 관리 서비스 6년 연속</h3>
        </li> */}
      <ul>
        <li>
          <h6>누적 예약금 거래액</h6>
          <div className="countWrap">
            <h3 className="point" id="cnt01" />
            <h3 className="point">+</h3>
          </div>
        </li>
        <li>
          <h6>레스토랑 고객</h6>
          <div className="countWrap">
            <h3 className="point" id="cnt02" />
            <h3 className="point">+</h3>
          </div>
        </li>
        <li>
          <h6>누적 예약 수</h6>
          <div className="countWrap">
            <h3 className="point" id="cnt03" />
            <h3 className="point">+</h3>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CountNumber;
