import { MediaQuery } from '@/shared/styles';
import React from 'react';
import styled from '@emotion/styled';

export const ChatbotImageContainerContainer = styled.div<React.CSSProperties>`
  display: flex;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    bottom: 0;
    background-color: #eaeef8;
    z-index: 0;
    width: 100vw;
  }
  & > * {
    z-index: 1;
  }

  .left-area {
    margin-right: 275px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #2d60b8;
      margin-bottom: 20px;
      ${MediaQuery.tablet} {
        text-align: center;
        font-size: 17.5px;
        line-height: 26px;
      }
    }

    .description {
      font-weight: 700;
      font-size: 51.2px;
      line-height: 67px;
      letter-spacing: -0.05em;
      color: #12233a;
      margin-bottom: 40px;
      ${MediaQuery.tablet} {
        text-align: center;
        font-size: 33.6px;
        line-height: 44px;
        color: #12233a;
      }
    }

    .bottom-move-button {
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      height: 48px;
      display: flex;
      align-self: flex-start;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 8px;

      cursor: pointer;
      padding: 0 5px;
      padding-left: 15px;
      border: 0;
      &:is(div) {
        background-color: #12233a;
      }
      .arrow-icon {
        margin-left: 16px;
        width: 48px;
      }
      ${MediaQuery.tablet} {
        position: absolute;
        bottom: 40px;
        left: 20px;
        right: 20px;
        width: unset;
        padding: 0 40px;
        box-sizing: border-box;
        z-index: 2;
        & > span {
          margin-left: 20px;

          ${MediaQuery.mobile} {
            margin-left: 0;
          }
        }
        & > img {
          margin-left: auto !important;
        }
      }
    }
  }

  .right-area {
    display: flex;
    padding: 100px 0;
    box-sizing: border-box;
    ${MediaQuery.tablet} {
      padding: 50px 0;
      z-index: 0;
    }
    & > video {
    }
    .image {
      height: 647px;
      margin: auto auto 0;
      border-radius: 16px;
      ${MediaQuery.tablet} {
        height: 290px !important;
      }
    }
  }

  // kt pages css
  &.kt-head-img-wrapper {
    justify-content: center;
    padding-top: 100px;

    ${MediaQuery.tablet} {
      padding-bottom: 148px;
    }

    ${MediaQuery.mobile} {
      padding-bottom: 88px;
    }

    .left-area {
      margin-right: 0;
      ${MediaQuery.mobile} {
        align-items: center;
      }

      .title {
        ${MediaQuery.mobile} {
          margin-bottom: 6px;
          font-size: 17.5px;
          line-height: 24px;
        }
      }

      .description {
        ${MediaQuery.tablet} {
          font-size: 44.8px;
          line-height: 58px;
        }

        ${MediaQuery.mobile} {
          font-size: 33.6px;
          line-height: 44px;
        }
      }

      .bottom-move-button {
        ${MediaQuery.tablet} {
          left: 6%;
          right: 6%;
          bottom: 100px;

          .arrow-icon {
            display: none;
          }
        }

        ${MediaQuery.mobile} {
          left: 6.66%;
          right: 6.66%;
          bottom: 40px;
          padding-inline: 20px;
          overflow-y: hidden;

          .arrow-icon {
            margin: -9px;
            display: block;
          }
        }
      }
    }

    .right-area {
      ${MediaQuery.mobile} {
        justify-content: center;
      }

      &.kt-image-wrapper {
        padding: 0px;
        .image {
          width: 713px;
          height: auto !important;
          border-radius: 0px;
          ${MediaQuery.tablet} {
            width: 476.6px;
            height: auto !important;
          }

          ${MediaQuery.mobile} {
            width: unset;
            max-width: 256px;
            margin: 0;
            margin-right: 40px;
            padding-inline: 8px;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  &.kt-head-img-wrapper.waiting-intro-header {
    padding-top: 48px;

    ${MediaQuery.tablet} {
      padding-block: 100px;
    }

    ${MediaQuery.mobile} {
      padding-bottom: 40px;
    }

    .right-area {
      .image {
        width: 680px;

        ${MediaQuery.tablet} {
          max-width: 576px;
        }

        ${MediaQuery.tablet} {
          max-width: 280px;
        }
      }
    }
  }

  &.kt-head-img-wrapper.waiting-intro-header.pickup-intro-header {
    padding-block: 100px;
    gap: 288px;

    ${MediaQuery.tablet} {
      gap: 0;
      padding-bottom: calc(100px + 48px - 12.5px); // 여백 100px + 버튼 height 48px - 이미지를 가린 사이즈 12.5px
    }

    ${MediaQuery.mobile} {
      padding-bottom: calc(40px + 48px - 12.5px); // 여백 40px + 버튼 height 48px - 이미지를 가린 사이즈 12.5px
    }

    .right-area {
      .image {
        width: 320px;

        > svg {
          display: block;
          overflow: hidden;
          border-radius: 16px;

          ${MediaQuery.mobile} {
            border-radius: 8px;
          }
        }

        ${MediaQuery.tablet} {
          width: auto;
          max-width: 244px;
        }

        ${MediaQuery.mobile} {
          max-width: 160px;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  &.kt-head-img-wrapper.survey-intro-header {
    padding-block: 100px;
    gap: 86px;

    ${MediaQuery.tablet} {
      gap: 0;
      padding-bottom: calc(100px + 48px - 12.5px); // 여백 100px + 버튼 height 48px - 이미지를 가린 사이즈 12.5px
    }

    ${MediaQuery.mobile} {
      padding-bottom: calc(40px + 48px - 12.5px); // 여백 40px + 버튼 height 48px - 이미지를 가린 사이즈 12.5px
    }

    .right-area {
      .image {
        width: 548px;

        ${MediaQuery.tablet} {
          max-width: 418px;
        }

        ${MediaQuery.mobile} {
          max-width: 225px;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  ${MediaQuery.tablet} {
    display: flex;
    flex-direction: column;
    padding: 130px 24px 0 24px;
    box-sizing: border-box;

    .left-area {
      margin-right: 0;
      z-index: 1;
    }
  }
`;
