import create from 'zustand';

interface IUseSolutionPopup {
  solutionModal: boolean;
  setSolutionModal: (props) => void;
}

export const useSolutionPopup = create<IUseSolutionPopup>((set) => ({
  solutionModal: false,

  setSolutionModal: (solutionModal) => set({ solutionModal }),
}));
