import '@/styles/index.scss';
import '@/styles/presets/aboutCount.scss';

import CountNumber from '@/components/CountNumber/CountNumber';
import Estimate from '@/components/Estimate/Estimate';
import Footer from '@/components/Footer/Footer';
import FreeExperience from '@/components/FreeExperience/FreeExperience';
import HeadTitle from '@/components/HeadTitle/HeadTitle';
import Header from '@/components/Header/Header';
import Partners from '@/components/Partners/Partners';
import { changeTitle } from '@/shared/lib/functions/change-title';
import { onIncreaseCount } from '@/shared/lib/functions/scroll/count-number';
import { useEffect } from 'react';
declare global {
  interface Window {
    wcs_add: any;
    _nasa: any;
    wcs: any;
    wcs_do: any;
  }
}

// eslint-disable-next-line max-lines-per-function
const IndexPage = () => {
  useEffect(() => {
    onIncreaseCount();
    changeTitle(location.search.includes('form') ? 'form' : undefined);
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <div className="wrap">
          <div className="inner">
            <div className="TitBox">
              <h1>
                테이블매니저는 <br />
                예약 비즈니스 성공의 파트너입니다.
              </h1>
              <p>레스토랑, 프랜차이즈, 공공・의료・레저 분야에서 솔루션 효과를 경험하고 있습니다.</p>
            </div>
          </div>
          <div className="TitImg" />
          <div className="gapDef" />
          <div className="inner main">
            <section className="sectionTitleWrap">
              <h4 className="point fadeInUp wow">테이블매니저 소개</h4>
              <h2>
                인공지능(AI) 기반 디지털 예약관리, <br />
                테이블매니저
              </h2>
            </section>
            <ul className="introBox">
              <li>
                <dl>
                  <dt>
                    <h3>네이버·카카오·KT 동시 투자</h3>
                  </dt>
                  <dd>테이블매니저는 네이버·카카오·KT가 동시에 투자한 최초의 스타트업으로 국내에서 유일하게 네이버와 카카오 챗봇 예약을 동시에 연동해 드립니다.</dd>
                </dl>
                <img src={require('@/assets/images/index/about01.png')} alt="네이버,카카오 아이콘" />
              </li>
              <li>
                <dl>
                  <dt>
                    <h3>검증된 데이터 기술력</h3>
                  </dt>
                  <dd>
                    2021 . SW고성장클럽200 과학기술정보통신부 장관상
                    <br />
                    2021 . 벤처창업진흥유공포상 청년기업인 부문 중소벤처기업부 장관상
                    <br />
                    2020 . AI 기반예약관리 및 빅데이터 마케팅 플랫폼으로 특허청장상
                  </dd>
                </dl>
                <img src={require('@/assets/images/index/about02.png')} alt="트로피 아이콘" />
              </li>
              <li>
                <dl>
                  <dt>
                    <h3>고객이 인정한 활용성</h3>
                  </dt>
                  <dd>
                    전화, 네이버, 카카오 챗봇 등 여러 경로를 통해 들어오는 예약을 한 번에 관리하고, 고객의 예약데이터를 활용해 맞춤형 타깃 마케팅을 하세요. 예약 수가 평균 2배 이상
                    늘어납니다.
                  </dd>
                </dl>
                <img src={require('@/assets/images/index/about03.png')} alt="사용자 증가 아이콘" />
              </li>
            </ul>
            <div className="gapDef" />
          </div>
        </div>
        <div className="wrap colorBack center">
          <div className="gapDef" />

          <div className="inner bookingLink">
            <h2>
              국내 최대 예약 연동
              <br />
              TableManager
            </h2>
            <p>
              기존 전화 예약은 물론 <br />
              네이버, 카카오, KT AI통화비서, SKT, 구글맵스, 신한카드까지 <br /> 국내에서 가장 많은 채널에서의 예약연동 서비스를 제공하고 있습니다.
            </p>
            <ul className="mainSync">
              <li>
                <img src={require('@/assets/images/logo/naver.png')} alt="네이버 로고" />
              </li>
              <li>
                <img src={require('@/assets/images/logo/kakao.png')} alt="카카오 로고" />
              </li>
              <li>
                <img src={require('@/assets/images/logo/skt.png')} alt="SKT 로고" />
              </li>
              <li>
                <img src={require('@/assets/images/logo/kt.png')} alt="KT 로고" />
              </li>
              <li>
                <img src={require('@/assets/images/logo/google.png')} alt="Google 로고" />
              </li>
              <li>
                <img src={require('@/assets/images/logo/shc.png')} alt="신한카드 로고" />
              </li>
            </ul>
            <p>
              별도 승인 절차 없이 실시간 예약 확정이 가능하고 <br />
              예약 확인 문자가 자동으로 발송됩니다.
            </p>
            <p>
              다양한 경로의 예약을 하나로 통합 관리하는 TableManager! <br />
              편리한 사용으로 예약 고객이 늘어나고, 레스토랑 매출도 늘어납니다.
            </p>
          </div>
          <div className="gapDef" />
        </div>
        <div className="wrap">
          <div className="gapDef" />
          <div className="inner center sectionTitleWrap">
            <h4 className="point">레스토랑의 이유있는 선택</h4>
            <h2 className="restaurant-title">
              예약관리 서비스 <br />
              6년 연속 <b className="point">1위</b>
            </h2>
          </div>
        </div>
        <div className="wrap">
          <Partners srcType="restaurant" />
        </div>
        <div className="wrap">
          <div className="inner">
            <CountNumber />
          </div>
        </div>

        <div className="gapDef" />
        <div className="wrap">
          <div className="inner sectionTitleWrap">
            <h4 className="point">예약 통합관리</h4>
            <h2>
              오버부킹 없는
              <br />
              예약채널 통합관리
            </h2>
            <div className="imgTxt managerReserv">
              <div className="setImg">
                <img src={require('@/assets/images/reservPhone1.png')} className="phoneImg" alt="스마트폰 예약화면" />
                <img src={require('@/assets/images/reservPhone2.png')} className="phoneImg frontPhone" alt="스마트폰 예약화면" />
              </div>
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>네이버나 카카오톡으로 쉽게 예약</h3>
                    </dt>
                    <dd>네이버, 카카오 챗봇 예약으로 더 많은 예약을 프라이빗하게 받아 보세요. </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>예약을 자동으로 확정하여 이중 예약 방지</h3>
                    </dt>
                    <dd>예약 관리 솔루션 TableManager로 네이버, 카카오톡 등 온라인뿐만 아니라 전화까지 모든 채널의 예약을 간편하게 관리하세요.</dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap colorBack">
          <div className="gapDef" />
          <div className="inner kakaoChannelWrap">
            <section className="sectionTitleWrap">
              <h4 className="point">카카오 채널 운영</h4>
              <h2>
                레스토랑 소유의 <br />
                강력한 마케팅 채널 구축
              </h2>
            </section>
            <ul className="box desc">
              <li>
                <dl>
                  <dt>
                    <h3>카카오 채널 운영을 통한 홍보</h3>
                  </dt>
                  <dd>매장을 방문한 고객들과 카카오톡 친구가 되고, 카카오 채널을 통해 고객들과 소통하면서 자주 찾는 고객들이 늘어납니다.</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>
                    <h3>테이블매니저 단독 제공</h3>
                  </dt>
                  <dd>쉽고 빠르게 고객과 소통할 수 있는 카카오 챗봇 예약은 TableManager 도입 매장에서만 이용할 수 있습니다.</dd>
                </dl>
              </li>
            </ul>
            <div className="kakaoChannelPhoneWrap">
              <img className="phoneImg kakaoChannelPhone" src={require('@/assets/images/main03.png')} alt="카카오톡 마케팅 채널 화면" />
              <img className="subPhone" src={require('@/assets/images/main03_sub.png')} alt="카카오톡 마케팅 채널 화면" />
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap">
          <div className="gapDef" />
          <div className="inner sectionTitleWrap">
            <h4 className="point">빠른 적용 쉬운 사용법</h4>
            <h2>
              언제 어디서든 누구나 <br />
              쉽게 사용
            </h2>
            <article className="easyUseProduct inner">
              <section>
                <img src={require('@/assets/images/main_dashboard1.png')} alt="테이블 매니저 사용화면" />
                <dl>
                  <dt>
                    <h3>익숙하고 쉬운 UX</h3>
                  </dt>
                  <dd>익숙한 POS 화면과 유사한 인터페이스로 누구나 쉽게 솔루션을 사용할 수 있습니다.</dd>
                </dl>
              </section>
              <section>
                <dl>
                  <dt>
                    <h3>본인 기기로 어디서나 접속 가능</h3>
                  </dt>
                  <dd>
                    모바일, 태블릿, 노트북 등 가지고 계신 기기를 이용해서 테이블매니저를 사용할 수 있습니다. 사무실에 있는 매니저도, 출장 중인 사장님도 인터넷만 되면 테이블매니저의
                    예약현황을 확인하고 관리할 수 있습니다.
                  </dd>
                </dl>
                <img src={require('@/assets/images/main_dashboard2.png')} alt="테이블 매니저 사용화면" />
              </section>
            </article>
          </div>
          <div className="gapDef" />
        </div>
        <div className="wrap">
          <div className="parallax para01">
            <div className="paraBox">
              <h2>
                테이블매니저가 만들면 <br />
                다릅니다.
              </h2>
              <ul className="box">
                <li>
                  <h4>모든 예약을 한 곳에서 통합관리</h4>
                  <p>전화, 네이버, 카카오 챗봇 등 다양한 경로로 들어오는 예약을 하나의 화면으로 쉽고 편하게 통합관리</p>
                </li>
                <li>
                  <h4>예약상품권 마케팅으로 매장 매출 증가</h4>
                  <p>방문 횟수, 거주지, 이용 메뉴 등 방문 데이터를 인공지능으로 분석하여 예약가능한 미래의 빈자리를 예측해 가격 최적화 가능</p>
                </li>
                <li>
                  <h4>인공지능 기반 디지털 예약관리</h4>
                  <p>다채널 유입 예약관리, 실시간 예약 확정, 정확한 예약금 관리는 물론 리마인드 메시지 발송까지, 고객에게 최적화된 CRM서비스 제공</p>
                </li>
                <li>
                  <h4>테이블 빈자리 예측으로 효율적인 매장 운영</h4>
                  <p>일주일 후 빈 자리를 정확히 예측하는 수요예측 AI를 활용해 식자재 주문 및 인력 운용으로 효율적인 매장 운영 가능</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="gapDef" />
        </div>
        <div className="wrap colorBack">
          <div className="gapDef" />
          <div className="inner feedbackWrap sectionTitleWrap">
            <HeadTitle className="customer-review-title" subTitle="고객후기" title={'Tablemanager, \n사용해 보니 어떠셨나요?'} />
            <ul className="feedBack">
              <li>
                <dl>
                  <dd>
                    매장에 전화가 오면 전화를 한 고객 정보가 팝업으로 뜨는 기능이 가장 유용합니다. 몇 번 예약한 고객인지, 단골인지 구분해 고객별 맞춤형 대응을 할 수 있기
                    때문입니다.
                  </dd>
                  <dt>
                    <img src={require('@/assets/images/logo/reviewwlogo.png')} alt="파티오42 로고" />
                    <span>
                      파티오42 서초교대점 직원<em>강병한</em>
                    </span>
                  </dt>
                </dl>
              </li>
              <li>
                <dl>
                  <dd>
                    별도 앱을 깔지 않고도 스마트폰으로 언제 어디서든 예약 현황을 확인하고, 다른 직원들과 공유할 수 있어서 편리합니다. 메모 기능으로 손님마다 개별적으로 취향을
                    적어놓을 수 있는 점도 큰 장점 중 하나입니다.
                  </dd>
                  <dt>
                    <img src={require('@/assets/images/index/rl2.png')} alt="만요 로고" />
                    <span>
                      만요 매니저<em>박아름</em>
                    </span>
                  </dt>
                </dl>
              </li>
              <li>
                <dl>
                  <dd>
                    테이블매니저 도입 후 고객님들이 예약을 하면 리마인드 문자를 보낼 수 있게 됐습니다. 고객님들이 리마인드 문자 받는 것을 좋아하셔서 저희 매장에 대한 고객 만족도가
                    이전보다 더 높아졌습니다.
                  </dd>
                  <dt>
                    <img src={require('@/assets/images/logo/review-logo3.png')} alt="중식당 청 로고" />
                    <span>
                      중식당 청 점장<em>송미경</em>
                    </span>
                  </dt>
                </dl>
              </li>
            </ul>
          </div>
          <div className="gapDef" />
        </div>
        <div className="wrap">
          <div className="gapDef" />
          <img src={require('@/assets/images/index/manager.jpg')} className="w100" alt="파트너사 지배인 사진들" />
          <div className="gapDef" />
          <Estimate />
        </div>
        <div className="gapDef" />
        <FreeExperience />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
