import { MediaQuery } from '@/shared/styles';
import React from 'react';
import styled from '@emotion/styled';

interface IOnlyStandard extends React.CSSProperties {
  onlyStandard?: boolean | null;
}

export const KTratePlanContainer = styled.article`
  margin-block: 200px;
  ${MediaQuery.tablet} {
    max-width: calc(100% - 6% - 6%);
    margin-inline: auto;
  }
  ${MediaQuery.mobile} {
    max-width: 100%;
    overflow: hidden;
    margin-block: 140px;
  }
`;

export const Title = styled.h1`
  max-width: 750px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 51.2px;
  line-height: 67px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #12233a;
  padding-bottom: 120px;

  ${MediaQuery.tablet} {
    font-size: 44.8px;
    line-height: 58px;
  }
  ${MediaQuery.mobile} {
    max-width: calc(100% - 6.66% - 6.66%);
    padding-bottom: 56px;
    font-size: 28px;
    line-height: 36px;
    > br {
      display: none;
    }
  }
`;

export const KTratePlanWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;

  ${MediaQuery.mobile} {
    padding-inline: 6.66%;
    gap: 16px;
    box-sizing: border-box;

    &:not(&.only-standard-wrap) {
      scroll-snap-type: x mandatory;
      overflow-y: auto;
      justify-content: flex-start;

      &::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
      }
      -ms-overflow-style: none; /* 인터넷 익스플로러 */
      scrollbar-width: none; /* 파이어폭스 */
    }
  }
`;

export const RatePlanContent = styled.article<IOnlyStandard>`
  max-width: ${(props) => (props.onlyStandard ? '640px' : '440px')};
  flex: 1;
  background: #ffffff;
  box-shadow: inset 0px 0px 0px 2px #e5e5e5;
  border-radius: 16px;
  box-sizing: border-box;

  ${MediaQuery.tablet} {
    max-width: 400px;
  }
  ${MediaQuery.mobile} {
    scroll-snap-align: center;
    min-width: 82.2222vw;
  }
`;

export const HeadContent = styled.section`
  padding: 28px 20px;
  border-radius: 16px 16px 0px 0px;
  background: #05153c;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${MediaQuery.tablet} {
    padding-block: 24px;
  }

  &.light-header {
    background: #eaeef8;

    h3 {
      color: #12233a;
    }

    span {
      color: rgba(18, 35, 58, 0.6);
    }
  }

  h3 {
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #ffffff;

    ${MediaQuery.tablet} {
      font-size: 21px;
      line-height: 30px;
    }
    ${MediaQuery.mobile} {
      font-size: 17.5px;
      line-height: 24px;
    }
  }

  span {
    display: block;
    max-width: 246px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);

    ${MediaQuery.tablet} {
      font-size: 14px;
      line-height: 21px;
    }
    ${MediaQuery.mobile} {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const PriceContent = styled.section`
  padding-block: 36px;
  border-bottom: 2px solid #f4f4f4;
  margin-inline: 2px; // border 겹치는 부분 수정

  ${MediaQuery.tablet} {
    padding-block: 28px;
  }

  ${MediaQuery.mobile} {
    padding-block: 20px;
  }

  h5 {
    padding-bottom: 4px;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #12233a;

    ${MediaQuery.tablet} {
      font-size: 21px;
      line-height: 30px;
    }
    ${MediaQuery.mobile} {
      font-size: 17.5px;
      line-height: 24px;
    }
  }

  span {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(18, 35, 58, 0.6);

    ${MediaQuery.tablet} {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .ordered-lists {
    counter-reset: item;
    padding-inline: 62px;
    padding-top: 36px;
    word-break: break-all;

    ${MediaQuery.tablet} {
      padding-top: 28px;
    }

    ${MediaQuery.mobile} {
      padding-top: 20px;
      padding-inline: 22px;
    }

    li {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #12233a;

      ${MediaQuery.tablet} {
        font-size: 14px;
        line-height: 21px;
      }

      &:not(&:last-of-type) {
        padding-bottom: 24px;
      }

      &::before {
        content: counter(item);
        margin-right: 16px;
        counter-increment: item;
        flex-shrink: 0;
        flex-grow: 0;
        width: 32px;
        height: 32px;
        background-color: #12233a;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        ${MediaQuery.mobile} {
          width: 28px;
          height: 28px;
          margin-right: 12px;
        }
      }
    }
  }
`;
export const ConfigureContent = styled.section`
  padding: 36px 0 42px;

  ${MediaQuery.tablet} {
    padding: 28px 0 32px;
  }
  ${MediaQuery.mobile} {
    padding: 20px 0 26px;
  }

  .unordered-lists {
    padding-inline: 62px;

    ${MediaQuery.mobile} {
      padding-inline: 22px;
    }

    li {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #12233a;

      ${MediaQuery.tablet} {
        font-size: 14px;
        line-height: 21px;
      }

      strong {
        font-weight: 500;
        color: #2d60b8;
      }

      &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        margin-right: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='28' height='21' viewBox='0 0 28 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.5758 3.28215L24.666 0.372437L10.5239 14.5146L3.25128 7.24195L0.422852 10.0704L10.6045 20.252L27.5758 3.28215Z' fill='%2312233A' /%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px 11px;
      }

      &:not(&:last-of-type) {
        padding-bottom: 16px;

        ${MediaQuery.tablet} {
          padding-bottom: 12px;
        }
      }

      &.not-provided {
        color: rgba(18, 35, 58, 0.6);

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.00008 9.41429L14.293 15.7072L15.7072 14.293L9.41429 8.00008L15.7072 1.70718L14.293 0.292969L8.00008 6.58586L1.70718 0.292969L0.292969 1.70718L6.58586 8.00008L0.292969 14.293L1.70718 15.7072L8.00008 9.41429Z' fill='%2312233A' fill-opacity='0.6' /%3E%3C/svg%3E%0A");
          background-size: 14px 14px;
        }
      }
    }
  }
`;
