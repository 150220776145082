import { BenefitItems, BenefitsArrow, BenefitsContainer, BenefitsDesc, InformationContainer } from './BenefitsContainer.styles';

import { ReactComponent as BottomArrow } from '@/assets/images/KTcallAssistant/icon/bottomArrow.svg';
import Details from './Informations/Details/Details';
import { KT_AI_CALL_MANAGER_PAGE_TYPE } from '@/shared/utils/ktAiCallManager.constants';
import Notice from './Informations/Notice/Notice';

const BENEFITS_CONTENTS = {
  [KT_AI_CALL_MANAGER_PAGE_TYPE.KB]: {
    desc: '지금 바로 KB 스타샵에서 AI 통화비서를 신청하세요. \n통화비서 무료체험 6개월 혜택을 드립니다.',
    list: null,
  },
  [KT_AI_CALL_MANAGER_PAGE_TYPE.TM]: {
    desc: '지금 바로 AI 통화비서를 신청하세요. \n통화비서 무료체험 최대 6개월 혜택을 드립니다.',
    list: [
      ['지금 바로 신청하면', '통화비서 1개월 무료'],
      ['테이블 매니저 솔루션 1년 약정 시', '통화비서 추가 5개월 무료'],
    ],
  },
  [KT_AI_CALL_MANAGER_PAGE_TYPE.CASH_NOTE]: {
    desc: '지금 바로 AI 통화비서를 신청하세요. \n오직 캐시노트 사장님에게만 통화비서 무료체험 1개월 혜택과 무료기간 만료 후 3만원 혜택의 상품권을 드려요.',
    list: [
      ['지금 바로 신청하면', '통화비서 1개월 무료'],
      ['1개월 무료 기간 만료 후', '3만원 혜택의 상품권 증정'],
    ],
  },
};

const Benefits = ({ type }) => {
  // type에 따라 switch문으로 데이터 보여주도록 하기
  const { desc, list } = BENEFITS_CONTENTS[type];

  return (
    <>
      <BenefitsContainer>
        <BenefitsDesc isCashNotePage={type === KT_AI_CALL_MANAGER_PAGE_TYPE.CASH_NOTE}>
          <h3>KT AI 통화비서 신규 도입</h3>
          <h2>매장을 위한 특별한 혜택</h2>
          <p>{desc}</p>
        </BenefitsDesc>
        {type !== KT_AI_CALL_MANAGER_PAGE_TYPE.KB && (
          <>
            <BenefitsArrow>
              <BottomArrow />
            </BenefitsArrow>
            <BenefitItems>
              <ul>
                {list?.length &&
                  list.map((itemContent, idx) => (
                    <li key={idx} className="content">
                      <div className="text-wrap">
                        {itemContent[0]}
                        <em>{itemContent[1]}</em>
                      </div>
                    </li>
                  ))}
              </ul>
            </BenefitItems>
          </>
        )}
      </BenefitsContainer>
      <InformationContainer>
        <Details type={type} />
        <Notice type={type} />
      </InformationContainer>
    </>
  );
};

export default Benefits;
