import HeadTitle from '@/components/HeadTitle/HeadTitle';
import PreventNoShowImg from '@/assets/images/waiting/prevent_noshow.json';
import Lottie from 'lottie-react';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import { WaitingDetailImg } from '../WaitingDetailLayout.styles';

const PreventCustomerChurn = () => {
  return (
    <SkillContainer>
      <HeadTitle subTitle="고객 이탈 방지" title="노쇼 없는 대기 서비스" desc={'대기 신청 시 메뉴 주문과 결제도 한 번에 진행, \n대기 중 고객 이탈을 방지하세요.'} />
      <WaitingDetailImg className="lottie-animation">
        <ul className="img-content">
          <li>
            <Lottie animationData={PreventNoShowImg} />
          </li>
        </ul>
      </WaitingDetailImg>
    </SkillContainer>
  );
};

export default PreventCustomerChurn;
