import React from 'react';
import { IChatbotDescription } from './ChatbotDescription.type';
import {} from './ChatbotDescription.constants';
import { ChatbotDescriptionContainer } from './ChatbotDescription.styles';
//import classnames from 'classnames';

const ChatbotDescription = (props: IChatbotDescription): React.ReactElement => {
  return (
    <ChatbotDescriptionContainer>
      <div className="title">카카오톡 챗봇예약</div>
      <div className="description">전국민이 가진 카카오톡으로 예약 접수가 더 쉬워집니다.<br/>채널 친구 대상 고객관리, 마케팅 도구를 제공합니다.<br/>레스토랑, 공공, 의료, 레저등 모든 비즈니스에 적용 가능합니다.
      </div>
    </ChatbotDescriptionContainer>
  );
};

export default ChatbotDescription;
