import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  useEffect(() => {
    // wcs_add 객체를 초기화합니다.
    if (typeof window.wcs_add === 'undefined') {
      window.wcs_add = {};
      window.wcs_add.wa = 's_53d3931555f4';
    }

    // _nasa 객체를 초기화합니다.
    if (typeof window._nasa === 'undefined') {
      window._nasa = {};
    }

    // 스크립트 태그를 동적으로 생성하고 설정합니다.
    const script = document.createElement('script');
    script.src = '//wcs.naver.net/wcslog.js';
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {
      // 스크립트가 로드되면 실행할 코드
      if (window.wcs) {
        window.wcs.inflow('tablemanager.io');
        window.wcs_do(window._nasa);
      }
    };

    // body의 마지막 부분에 스크립트 태그를 추가합니다.
    document.body.appendChild(script);

    // 컴포넌트 언마운트 시 스크립트 태그를 제거합니다.
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <footer>
      <div className="wrap">
        <div className="inner">
          <div className="left">
            <h2 className="tel">
              <a href="tel:1544-2017">1544-2017</a>
            </h2>
            <div className="address">
              <ul>
                <li>테이블메니저 주식회사</li>
                <li>서울특별시 성수이로20길 16(성수동2가)</li>
                <li>대표자 안병준</li>
              </ul>

              <ul>
                <li>사업자등록번호 751-81-00830</li>
                <li>FAX 02-322-9864</li>
                <li>
                  <a href="mailto:info@tablemanager.io">info@tablemanager.io</a>
                </li>
              </ul>
              <p className="copyright">Copyright ⓒ TableManager Inc. All rights reserved.</p>
              <p />
            </div>
          </div>

          <div className="right">
            <a className="logo" href="#">
              <img src={require('@/assets/images/logo/footer-logo.svg').default} alt="테이블매니저 로고" />
            </a>
            <ul>
              <li>
                <Link to="//www.tablemanager.io/terms.html" target="_blank" className="policy-link">
                  이용약관
                </Link>
              </li>
              <li>
                <Link to="//theyeyak.com/policy/privacy" target="_blank" className="policy-link">
                  개인정보처리방침
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
