import { postSlackMessage, validationForm } from '@/hooks/postSlackMessage.hook';
import { putPrivacyData } from '@/hooks/usePrivacyForm.hook';
import { PAGE_TYPE } from '@/shared/utils/common.constants';
import { SELECT_PRIVACY_TYPE } from '@/shared/utils/privacy/privacy.constants';
import { useState } from 'react';
import CheckPersonalInfoAgreement from '../CheckPersonalInfoAgreement/CheckPersonalInfoAgreement';

interface ISolutionExperienceForm {
  setIsShowSolution: Function;
  isShowSolution: boolean;
}

const SolutionExperienceForm = ({ setIsShowSolution, isShowSolution }: ISolutionExperienceForm) => {
  const [form, setForm] = useState({ name: '', job: '', email: '', tel: '', coName: '', coAddr: '' });
  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState<boolean>(false);

  const { mutateAsync: solutionPrivacyMutate } = putPrivacyData();
  const { mutateAsync: solutionSendMessageMutate } = postSlackMessage(PAGE_TYPE.DEFAULT);

  const submit = async () => {
    try {
      validationForm(PAGE_TYPE.DEFAULT, form, isCheckedPrivacy);
      await solutionPrivacyMutate({
        phone: form.tel.replace(/-/g, ''),
        email: form.email,
        privacyType: SELECT_PRIVACY_TYPE.DEFAULT,
        isCheckedAgreement: isCheckedPrivacy,
      });

      await solutionSendMessageMutate(form).then(() => {
        setForm({ name: '', job: '', email: '', tel: '', coName: '', coAddr: '' });
        return setIsShowSolution(false);
      });

      // 신청/예약 완료 페이지 로그수집
      window._nasa = window._nasa || {};
      if (window.wcs) {
        window._nasa.cnv = window.wcs.cnv('4', 0);
        window.wcs_do(window._nasa);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="layerPopupWrap">
      <div className={`wrap layerPopup ${isShowSolution ? 'active' : ''}`}>
        <img src={require('@/assets/images/icon/closeBtn.svg').default} className="closeBtn" alt="닫기버튼" onClick={() => setIsShowSolution(false)} />
        <div className="inner form">
          <div className="leftTit left">
            <h2>
              예약 관리 솔루션
              <br />
              TableManager
            </h2>
            <h3>
              비즈니스별 예약 시스템을
              <br />
              맞춤형으로 설계해 드립니다.
            </h3>
            <ul>
              <li>
                <span>01.</span>
                <p>무료 체험 솔루션도 비즈니스별 맞춤형으로 제공됩니다.</p>
              </li>
              <li>
                <span>02.</span>
                <p>상담을 신청하시면 24시간 이내에 연락드립니다.</p>
              </li>
            </ul>
          </div>
          <div className="right">
            <div className="inputWrap required">
              <label htmlFor="solution-name">이름</label>
              <input
                type="text"
                id="solution-name"
                value={form.name}
                onChange={(e) => setForm((state) => ({ ...state, [e.target.id.replace('solution-', '')]: e.target.value }))}
              />
            </div>
            <div className="inputWrap">
              <label htmlFor="solution-job">직함</label>
              <input type="text" id="solution-job" value={form.job} onChange={(e) => setForm((state) => ({ ...state, [e.target.id.replace('solution-', '')]: e.target.value }))} />
            </div>
            <div className="inputWrap required">
              <label htmlFor="solution-email">이메일</label>
              <input
                type="email"
                id="solution-email"
                value={form.email}
                onChange={(e) => setForm((state) => ({ ...state, [e.target.id.replace('solution-', '')]: e.target.value }))}
              />
            </div>
            <div className="inputWrap required">
              <label htmlFor="solution-tel">휴대폰</label>
              <input type="tel" id="solution-tel" value={form.tel} onChange={(e) => setForm((state) => ({ ...state, [e.target.id.replace('solution-', '')]: e.target.value }))} />
            </div>
            <div className="inputWrap required">
              <label htmlFor="solution-coName">매장명</label>
              <input
                type="text"
                id="solution-coName"
                value={form.coName}
                onChange={(e) => setForm((state) => ({ ...state, [e.target.id.replace('solution-', '')]: e.target.value }))}
              />
            </div>
            <div className="inputWrap">
              <label htmlFor="solution-coAddr">매장주소</label>
              <input
                type="text"
                id="solution-coAddr"
                value={form.coAddr}
                onChange={(e) => setForm((state) => ({ ...state, [e.target.id.replace('solution-', '')]: e.target.value }))}
              />
            </div>
            <CheckPersonalInfoAgreement type="DEFAULT" htmlFor="solutionForm" setChecked={setIsCheckedPrivacy} />
            <div className="btnBox">
              <button className="defBtn onPopupBtn" onClick={submit}>
                신청하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SolutionExperienceForm;
