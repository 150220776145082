import React from 'react';
import styled from '@emotion/styled';
import { StyledContainer } from '@/components/kakao/StyledContainer/StyledContainer.styles';
import { MediaQuery } from '@/shared/styles';

export const ReservationContainerContainer = styled.div<React.CSSProperties> `
  ${StyledContainer}
  .containerInner.reservation {
    .containerBody {
      background-color:#F2F3F4;
      padding: 106px 0 68px 0;
      ${MediaQuery.tablet} {
        img {
          width: 268px!important;
        }
      }
    }
  }
`;
