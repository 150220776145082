import { InformationContentWrapper, InformationDetails, InformationSummary, InformationTable, InformationTableWrap } from '../Details/Details.styles';
import { NoticeContainer, NoticeContent, TableWithDesc } from './Notice.styles';

import { KT_AI_CALL_MANAGER_PAGE_TYPE } from '@/shared/utils/ktAiCallManager.constants';

const Notice = ({ type }) => {
  const isKBType = type === KT_AI_CALL_MANAGER_PAGE_TYPE.KB;

  return (
    <NoticeContainer>
      <InformationDetails>
        <InformationSummary>가입 및 유의사항</InformationSummary>
        <InformationContentWrapper>
          <NoticeContent className="notice-content">
            <li>
              <div>
                <h5>가입가능 고객</h5>
                <ul className="inner-list">
                  <li>유선전화 : KT PSTN/홈인터넷전화 보유 고객</li>
                  <li>
                    스마트폰 : 이동통신 3사 및 알뜰폰 모두 가능 (안드로이드OS(4.0이상) 및 iOS) Wi-Fi only폰도 가능 <br />
                    (단, 인증번호 수신을 위한 본의명의 모바일 번호 별도 필요)
                  </li>
                </ul>
              </div>
            </li>
            <li className="dotted-one-line notice-table-wrapper">
              <h5>서비스 이용 제한</h5>
              <InformationTableWrap className="notice-table-wrap">
                <InformationTable>
                  <tbody>
                    <tr>
                      <th rowSpan={2}>일반전화</th>
                      <th>가입충돌 상품</th>
                      <td>배타관계 : 패스콜, 알림콜, 일반전화 ARS, 동시착신(무료형, 착신전용, 스마트착신, 멀티넘버서비스, 통화매니저APP, 웹Say (스마트메시지PLUS)</td>
                    </tr>
                    <tr>
                      <th>기능제한 상품</th>
                      <td>1) 대표장치(대표번호), 2) 착신통화전환, 3) 통화매니저 PC, API</td>
                    </tr>
                    <tr>
                      <th rowSpan={2}>인터넷전화</th>
                      <th>가입충돌 상품</th>
                      <td>배타관계 : 부재중안내, 지정번호착신금지, 동시착신(유료형), 대표장치(대표번호/종속), 발신번호제한, 통화매니저APP, 웹Say(스마트메시지PLUS)</td>
                    </tr>
                    <tr>
                      <th>기능제한 상품</th>
                      <td>착신통화전환, 통화매니저 PC, API</td>
                    </tr>
                  </tbody>
                </InformationTable>
              </InformationTableWrap>
              <TableWithDesc>
                <div className="caution">※ AI 통화비서 스탠다드 상품과 AI 통화비서 라이트 상품은 중복 가입이 불가합니다.</div>
                <ol className="list-wrapper">
                  <li>
                    1) 대표장치 및 종속번호까지 모두 AI 통화비서 가입해야 정상적으로 서비스 이용 가능
                    <div className="dotted-wrap">
                      <span>
                        예) 대표장치(1회선)/종속번호(3회선) 사용 중 대표장치로 발신 시 각 회선 상태가 “대표(통화중)-종속1(통화중)-종속2(통화중)-종속3(통화 가능)”일 경우 최종
                        종속3으로 착신 연결되며, 이런경우 종속3까지 본 서비스를 가입해야 정상적으로 통화비서 서비스 동작
                      </span>
                    </div>
                  </li>
                  <li>
                    2) 착신통화전환 설정 해제한 경우에만 정상적으로 동작
                    <div className="dotted-wrap">
                      <span>
                        착신통화전환(착신전환) 서비스에 가입되어 있고 전화기상에서 *88로 설정 시 본 서비스 기능은 동작되지 않으며, 착신통화전환(착신전환) 설정해제한 경우에만
                        정상적으로 동작
                      </span>
                    </div>
                  </li>
                  <li>
                    3) 통화매니저 사용고객 중 수신설정 기능(착신전환, ARS, 안내멘트) 사용 시 AI 통화비서 동작되지 않으며, AI통화비서가 통화하는 내용에 대하여 통화매니저의
                    통화레코딩이 동작하지 않는다.
                  </li>
                </ol>
              </TableWithDesc>
            </li>
            {isKBType ? (
              <li>
                <div>
                  <h5>무료 혜택 제공 방법</h5>
                  <ul className="inner-list">
                    <li>6개월 무료 혜택은 테이블매니저가 제휴 혜택으로 요금을 제공하는 방식입니다.</li>
                  </ul>
                </div>
              </li>
            ) : null}
            <li>
              <div>
                <h5>무료 혜택 제공 제한</h5>
                <ul className="inner-list">
                  <li>최초 가입자가 아닐 시 혜택 제공 불가</li>
                  <li>가입 후 5일 내 통화비서 앱 미로그인 시 혜택 제공 불가</li>
                </ul>
              </div>
            </li>
            <li>
              <div>
                <h5>무료 혜택 만료 후</h5>
                <ul className="inner-list">
                  <li>무료 혜택 만료 후 월단위로 요금 자동 출금</li>
                </ul>
              </div>
            </li>
          </NoticeContent>
        </InformationContentWrapper>
      </InformationDetails>
    </NoticeContainer>
  );
};

export default Notice;
