import { postSlackMessage, validationForm } from '@/hooks/postSlackMessage.hook';
import { putPrivacyData } from '@/hooks/usePrivacyForm.hook';
import { PAGE_TYPE } from '@/shared/utils/common.constants';
import { SELECT_PRIVACY_TYPE } from '@/shared/utils/privacy/privacy.constants';
import CheckPersonalInfoAgreement from '../CheckPersonalInfoAgreement/CheckPersonalInfoAgreement';
import FormTitle from '../FormTitle/FormTitle';
import { FreeExperienceContainer } from '../kakao/FreeExperience/FreeExperience.styles';
import { useState } from 'react';

const FreeExperience = () => {
  const [form, setForm] = useState({ name: '', job: '', email: '', tel: '', coName: '', coAddr: '' });
  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState<boolean>(false);

  const { mutateAsync: privacyMutate } = putPrivacyData();
  const { mutateAsync: sendMessageMutate } = postSlackMessage(PAGE_TYPE.DEFAULT);

  const submit = async () => {
    try {
      validationForm(PAGE_TYPE.DEFAULT, form, isCheckedPrivacy);
      await privacyMutate({
        phone: form.tel.replace(/-/g, ''),
        email: form.email,
        privacyType: SELECT_PRIVACY_TYPE.DEFAULT,
        isCheckedAgreement: isCheckedPrivacy,
      });

      await sendMessageMutate(form).then(() => {
        setForm({ name: '', job: '', email: '', tel: '', coName: '', coAddr: '' });
      });

      // 신청/예약 완료 페이지 로그수집
      window._nasa = window._nasa || {};
      if (window.wcs) {
        window._nasa.cnv = window.wcs.cnv('4', SELECT_PRIVACY_TYPE.DEFAULT);
        window.wcs_do(window._nasa);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FreeExperienceContainer id="form" className="wrap colorBack noFloat">
      <div className="gapDef" />
      <div className="inner form form-content-wrap">
        <FormTitle />
        <div className="right">
          <div className="inputWrap required">
            <label htmlFor="name">이름</label>
            <input type="text" id="name" value={form.name} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap">
            <label htmlFor="job">직함</label>
            <input type="text" id="job" value={form.job} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap required">
            <label htmlFor="email">이메일</label>
            <input type="email" id="email" value={form.email} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap required">
            <label htmlFor="tel">휴대폰</label>
            <input type="tel" id="tel" value={form.tel} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap required">
            <label htmlFor="coName">매장명</label>
            <input type="text" id="coName" value={form.coName} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <div className="inputWrap">
            <label htmlFor="coAddr">매장주소</label>
            <input type="text" id="coAddr" value={form.coAddr} onChange={(e) => setForm((state) => ({ ...state, [e.target.id]: e.target.value }))} />
          </div>
          <CheckPersonalInfoAgreement type="DEFAULT" htmlFor="defaultForm" setChecked={setIsCheckedPrivacy} />
          <div className="btnBox">
            <button className="defBtn" onClick={submit}>
              신청하기
            </button>
          </div>
        </div>
      </div>
      <div className="gapDef" />
    </FreeExperienceContainer>
  );
};

export default FreeExperience;
