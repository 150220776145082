import React from 'react';
import { ChatbotImageContainerContainer } from '@/components/kakao/ChatbotImageContainer/ChatbotImageContainer.styles';
import SatisfactionSurveyImg from '@/assets/images/survey/intro_header.png';
import { useSolution } from '@/stores/common/useSolution';
import { TM_POST_CHANNEL } from '@/constants';

const IntroHeader = (): React.ReactElement => {
  const { isSolutionFrame } = useSolution();

  function submit() {
    if (isSolutionFrame) window.parent.postMessage({ type: TM_POST_CHANNEL, data: 'Submit:survey' }, '*');
    else document.querySelector('#form').scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <ChatbotImageContainerContainer className="survey-intro-header kt-head-img-wrapper">
      <section className="left-area">
        <h4 className="title">카카오로 더 쉬워진 데이터 수집</h4>
        <h1 className="description">
          방문 후 고객 평가도 <br />
          카카오톡으로 관리
        </h1>
        <div className="bottom-move-button" onClick={submit}>
          <span>솔루션 체험하기</span>
          <img src="/kakao/img/animation_arrow.gif" className="arrow-icon" />
        </div>
      </section>
      <section className="right-area kt-image-wrapper">
        <img className="image" src={SatisfactionSurveyImg} alt="만족도 조사 소개 이미지" />
      </section>
    </ChatbotImageContainerContainer>
  );
};

export default IntroHeader;
