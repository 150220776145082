import { HeadTitleContainer } from './HeadTitle.styles';

interface IHeadTitle {
  subTitle: string;
  title: string;
  desc?: string;
  className?: string;
}

const HeadTitle = ({ subTitle, title, desc, className }: IHeadTitle) => {
  return (
    <HeadTitleContainer className={className}>
      <h5>{subTitle}</h5>
      <h2>{title}</h2>
      {desc ? <p>{desc}</p> : null}
    </HeadTitleContainer>
  );
};

export default HeadTitle;
