import React from 'react';
import { INewbieContainer } from './NewbieContainer.type';
import {} from './NewbieContainer.constants';
import { NewbieContainerContainer } from './NewbieContainer.styles';
//import classnames from 'classnames';

const NewbieContainer = (props: INewbieContainer): React.ReactElement => {
  return (
    <NewbieContainerContainer>
      <div className="title">카카오톡 챗봇 예약 신규 도입 매장을 위한</div>
      <div className="sub-title">특별한 혜택</div>
      <div className="description">지금 바로 챗봇 예약을 신청하세요.<br/>신규 도입 매장을 위해 마케팅 지원을 해 드립니다.</div>
    </NewbieContainerContainer>
  );
};

export default NewbieContainer;
