const IMOJI_ARRAY = ['💤', '😆', '🛠', '👩🏻‍💻', '🧶', '✨'];

const EmptyCategory = () => {
  return (
    <article className="emptyCategoryWrap">
      <h1>{IMOJI_ARRAY[Math.floor(Math.random() * IMOJI_ARRAY.length)]}</h1>
      <p>준비하고 있어요.</p>
    </article>
  );
};

export default EmptyCategory;
