import { MediaQuery } from '@/shared/styles';
import styled from '@emotion/styled';

export const IntroduceContainer = styled.article`
  margin-bottom: 200px;
  padding-top: 200px;
  ${MediaQuery.tablet} {
    max-width: calc(100% - 6% - 6%);
    margin-inline: auto;
  }
  ${MediaQuery.mobile} {
    max-width: 100%;
    padding-inline: 6.66%;
    margin-bottom: 140px;
    padding-block: 140px;
    background: #eaeef8;
    box-sizing: border-box;
  }
`;

export const IntroduceImgWrapper = styled.section`
  padding: 65.08px 210px;
  background: #eaeef8;
  border-radius: 20px;

  ${MediaQuery.tablet} {
    padding: 68px 0;
  }
  ${MediaQuery.mobile} {
    padding: 0;
  }

  .introduce-img-wrap {
    display: flex;
    gap: 120px;

    ${MediaQuery.tablet} {
      justify-content: center;
      gap: 12.3966%;
    }
    ${MediaQuery.mobile} {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }

    li {
      max-width: 280px;
      ${MediaQuery.mobile} {
        max-width: 256px;
      }

      img {
        max-width: 100%;
      }
      h4 {
        padding-bottom: 30px;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.03em;
        color: #12233a;
      }
    }
  }
`;
