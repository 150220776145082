import {
  CheckReservationManagement,
  ReservationManagementContainer,
  ReservationManagementInfo,
  ReservationManagementView,
  ReservationManagementViewWrap,
  ReservationManagementViewWrapper,
} from './ReservationManagement.styles';

import HeadTitle from '@/components/HeadTitle/HeadTitle';
import ReservationManagementImage from '@/assets/images/KTcallAssistant/reservationManagement.png';

const ReservationManagement = () => {
  return (
    <ReservationManagementContainer>
      <HeadTitle
        subTitle="예약 관리"
        title="모든 예약이 100% 자동으로 해결됩니다"
        desc="전화예약은 물론 네이버, 카카오 예약까지 실시간으로 충돌 없는 자동 확정 처리를 통해 통합 관리가 가능합니다."
      />
      <ReservationManagementViewWrapper>
        <ReservationManagementViewWrap>
          <ReservationManagementView>
            <ReservationManagementInfo>
              <CheckReservationManagement>
                <div className="desc-wrap">
                  <h4>100% 예약 자동화</h4>
                  <span>확인이 필요없는 실시간 예약 통합 관리. 전화예약, 네이버, 카카오 예약까지 실시간으로 자동 확정 처리해 줘요.</span>
                </div>
              </CheckReservationManagement>
              <CheckReservationManagement>
                <div className="desc-wrap">
                  <h4>노쇼 방지</h4>
                  <span>고객에게 알림 메시지를 발송해서 노쇼 위험을 줄일 수 있어요.</span>
                </div>
              </CheckReservationManagement>
              <CheckReservationManagement>
                <div className="desc-wrap">
                  <h4>쉬운 예약 설정</h4>
                  <span>간단한 튜토리얼로 쉽고 빠르게 설정할 수 있어요.</span>
                </div>
              </CheckReservationManagement>
            </ReservationManagementInfo>
          </ReservationManagementView>
          <ReservationManagementView className="phone-img-wrap">
            <img src={ReservationManagementImage} alt="kt ai 통화비서 예약 관리 이미지" />
          </ReservationManagementView>
        </ReservationManagementViewWrap>
      </ReservationManagementViewWrapper>
    </ReservationManagementContainer>
  );
};

export default ReservationManagement;
