import { MediaQuery } from '@/shared/styles';
import styled from '@emotion/styled';

export const HeadTitleContainer = styled.section`
  max-width: 560px;
  margin-bottom: 40px;
  white-space: pre-line;

  ${MediaQuery.tablet} {
    max-width: 520px;
  }
  ${MediaQuery.mobile} {
    margin-bottom: 56px;
  }

  h5 {
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #2d60b8;

    ${MediaQuery.tablet} {
      font-size: 14.4px;
      line-height: 21px;
    }
    ${MediaQuery.mobile} {
      padding-bottom: 12px;
      font-size: 15.4px;
      line-height: 23px;
    }
  }
  h2 {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 41px;
    line-height: 54px;
    color: #12233a;

    ${MediaQuery.tablet} {
      font-size: 36.4px;
      line-height: 47px;
    }
    ${MediaQuery.mobile} {
      margin-bottom: 28px;
      font-size: 28px;
      line-height: 36px;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(18, 35, 58, 0.6);
    margin-bottom: 0;

    ${MediaQuery.tablet} {
      font-size: 14px;
      line-height: 21px;
      word-break: break-all;
    }
  }

  &.evaluation-results-head-title p {
    width: 491px;

    ${MediaQuery.tablet} {
      width: 430px;
    }

    ${MediaQuery.mobile} {
      width: 100%;
    }
  }

  &.order-head-title h2 {
    ${MediaQuery.mobile} {
      width: 240px;
    }
  }

  &.convenient-use-head-title h2 {
    ${MediaQuery.mobile} {
      width: 240px;
    }
  }
`;
