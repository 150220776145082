import * as React from 'react';

import Footer from '@/components/Footer/Footer';
import { Wrapper } from '@/components/kakao/Wrapper/Wrapper';
import BenefitsContainer from '@/components/KTcallAssistant/Benefits/BenefitsContainer';
import HowToUse from '@/components/KTcallAssistant/HowToUse/HowToUse';
import Introduce from '@/components/KTcallAssistant/Introduce/Introduce';
import KTdescription from '@/components/KTcallAssistant/KTdescription/KTdescription';
import KTratePlan from '@/components/KTcallAssistant/KTratePlan/KTratePlan';
import MailHeaderImage from '@/components/KTcallAssistant/MainHeaderImage/MainHeaderImage';
import ReservationManagementContainer from '@/components/KTcallAssistant/ReservationManagement/ReservationManagement';
import SkillContainer from '@/components/KTcallAssistant/Skill/Skill';
import SubmitFreeExperience from '@/components/KTcallAssistant/SubmitFreeExperience/SubmitFreeExperience';
import TabList from '@/components/KTcallAssistant/TabList/TabList';
import { KT_AI_CALL_MANAGER_PAGE_TYPE } from '@/shared/utils/ktAiCallManager.constants';

const KBHome = (): React.ReactElement => {
  return (
    <>
      <Wrapper>
        <MailHeaderImage />
        <KTdescription />
        <TabList />
        <Introduce />
        <SkillContainer />
        <ReservationManagementContainer />
        <KTratePlan />
        <BenefitsContainer type={KT_AI_CALL_MANAGER_PAGE_TYPE.KB} />
        <HowToUse />
      </Wrapper>
      <SubmitFreeExperience type={KT_AI_CALL_MANAGER_PAGE_TYPE.KB} />
      <Footer />
    </>
  );
};

export default KBHome;
