import { MediaQuery } from '@/shared/styles';
import styled from '@emotion/styled';
import React from 'react';

export const FreeExperienceContainer = styled.div<React.CSSProperties>`
  background-color: rgba(45, 80, 184, 0.1);
  width: 100%;
  float: none !important;

  ${MediaQuery.tablet} {
    padding: 0 24px;
    box-sizing: border-box;
  }
  ${MediaQuery.mobile} {
    padding-inline: 6.66%;
  }

  & > .inner.form-content-wrap {
    ${MediaQuery.tablet} {
      flex-direction: column;
    }

    ${MediaQuery.mobile} {
      padding: 0;
      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .leftTit {
      ${MediaQuery.tablet} {
        max-width: 100%;
      }
    }
  }

  .right {
    ${MediaQuery.tablet} {
      max-width: unset;
      width: 100%;
    }

    .inputWrap {
      padding-right: 16px;
      box-sizing: border-box;

      input::placeholder {
        color: rgba(18, 35, 58, 0.6);
      }

      &.co-carrier-input-wrap {
        .form-radio-group {
          ${MediaQuery.mobile} {
            max-width: 166px;
            padding-block: 10px;
          }
        }
      }

      .form-radio-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-block: 5px;
        column-gap: 20px;
        row-gap: 8px;
        box-sizing: border-box;

        .radio-wrap {
          display: flex;
          align-items: center;

          font-weight: 500;
          font-size: 16px;
          line-height: 21px;

          label {
            display: flex;
            align-items: center;
            gap: 6px;

            ${MediaQuery.mobile} {
              font-size: 14px;
            }
          }

          input {
            width: auto;

            &[type='radio'] {
              display: none;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);

              & + label:before {
                content: '';
                display: block;
                width: 18px;
                height: 18px;
                background: #fff;
                box-shadow: 0 0 0 1.5px rgba(18, 35, 58, 0.6) inset;
                border-radius: 50%;
                box-sizing: border-box;
              }

              &:checked + label:before {
                content: '';
                border: 1.5px solid #2d60b8;
                background: #2d60b8;
                padding: 3px;
                box-shadow: 0 0 0 3px #fff inset;
              }
            }
          }

          &.etc-wrap {
            > input:first-of-type {
              flex: none;
            }

            .etc-text-input {
              display: block;
              margin-left: 8px;
              max-width: 140px;
              height: 32px;
              padding: 4px 12px;
              box-sizing: border-box;
              border-radius: 3px;
              background-color: #fff;
              border: 1px solid #cfd3d9;
              color: #12233a;

              ${MediaQuery.mobile} {
                width: 44.3%;
                max-width: 106px;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
              }

              &::placeholder {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: rgba(18, 35, 58, 0.6);

                ${MediaQuery.mobile} {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                }
              }

              &:disabled {
                background: #e9ebee;
                &::placeholder {
                  color: rgba(18, 35, 58, 0.2);
                }
              }
            }
          }
        }
      }
    }
  }
  .gapDef {
    width: 100%;
    // float: left;
    height: 200px;
    clear: both;
    ${MediaQuery.mobile} {
      height: 140px;
    }
  }

  .defBtn {
    padding: 0.8em 4em;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    transition: 0.2s ease;
  }

  button:hover,
  .btn-more:hover {
    background-color: #cecfcf;
  }

  .doneBtn {
    background-color: #2d50b8;
  }

  .btn-more {
    transition: 0.2s ease;
  }

  .leftTit {
    max-width: fit-content;
  }

  .form {
    .btnBox {
      margin-top: 20px;
      float: left;
      width: 100%;

      a {
        font-weight: 700;
        color: #12233a;
      }

      .defBtn {
        float: left;
        width: 196px;
        height: 48px;
        font-weight: 500;
        ${MediaQuery.tablet} {
          width: 100%;
          box-sizing: border-box;
        }
        ${MediaQuery.mobile} {
          font-size: 14px;
          line-height: 20px;
        }
      }

      p {
        float: right;
        width: 350px;
        ${MediaQuery.tablet} {
          width: 100%;
          margin-block: 24px;
        }
        ${MediaQuery.mobile} {
          font-size: 14px;
          line-height: 21px;
          margin-block: 20px;
        }
      }

      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(18, 35, 58, 0.6);
    }
  }

  &.change-css-survey {
    background-color: #05153c;

    // form title
    .sub-title {
      color: #fff;
    }
    .description {
      color: rgba(255, 255, 255, 0.6);
    }

    .right {
      input#defaultForm {
        & + label {
          color: rgba(255, 255, 255, 0.6);

          &::before {
            background-color: transparent;
            border: 1.5px solid rgba(255, 255, 255, 0.6);
          }
          em {
            color: #cddde6;
          }
        }

        &:checked + label:before {
          background-color: #fff;
          background-image: url("data:image/svg+xml,%3Csvg width='28' height='21' viewBox='0 0 28 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.5758 3.28215L24.666 0.372437L10.5239 14.5146L3.25128 7.24195L0.422852 10.0704L10.6045 20.252L27.5758 3.28215Z' fill='%2312233A' /%3E%3C/svg%3E%0A");
        }
      }

      .btnBox {
        p {
          color: rgba(255, 255, 255, 0.6);
        }
        .defBtn {
          background-color: #fff;
          color: #12233a;
        }
      }
    }
  }
`;
