import '@/styles/franchise.scss';

import Estimate from '@/components/Estimate/Estimate';
import Footer from '@/components/Footer/Footer';
import FreeExperience from '@/components/FreeExperience/FreeExperience';
import Header from '@/components/Header/Header';
import Partners from '@/components/Partners/Partners';
import { changeTitle } from '@/shared/lib/functions/change-title';
import { useSolutionPopup } from '@/stores/common/useSolutionPopup';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Franchise = () => {
  const { pathname } = useLocation();
  const { setSolutionModal } = useSolutionPopup();

  useEffect(() => {
    changeTitle(pathname);
  }, []);

  return (
    <>
      <Header />
      <main className="sub">
        <div className="wrap">
          <div className="inner">
            <div className="TitBox">
              <div className="left">
                <h4>고객과 함께 TableManager</h4>
                <h1>
                  프랜차이즈 마케팅 파트너
                  <br />
                  TableManager
                </h1>
                <p>
                  TableManager는 흩어져 있는 지점별 고객 관리를 통해 효율이 높을뿐만 아니라, <br />
                  브랜드의 매출 경쟁력을 높이는 <br />
                  데이터 마케팅 프로그램을 제공합니다.
                </p>
                <div className="btnBox">
                  <button className="defBtn popupOpen" onClick={() => setSolutionModal(true)}>
                    상담 신청하기
                  </button>
                  <button onClick={() => window.open('https://theyeyak.com/voucher/main', '_blank')} className="defBtn doneBtn theyeyakOpen">
                    더예약 바로가기
                  </button>
                </div>
              </div>
              <div className="gapDef" />
            </div>
          </div>
          <img src={require('@/assets/images/franchise/corporate-main.png')} className="w100" alt="테이블매니저 예약관리 시스템" />
          <Partners srcType="restaurant" />
        </div>
        <div className="wrap">
          <div className="gapDef" />
          <div className="inner">
            <h2>
              프랜차이즈 지점 관리, <br />
              특화된 예약 관리 솔루션
            </h2>
            <div className="imgTxt tabletWrap">
              <img src={require('@/assets/images/restaurant/rest04.png')} className="tablet bigger" alt="예약 관리 분석화면" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>유명 프랜차이즈 도입으로 검증된 솔루션</h3>
                    </dt>
                    <dd>전 지점에 적용되는 통합 예약 관리로 브랜드의 경쟁력을 높이고, 지점별 예약 고객을 효율적으로 관리할 수 있습니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>최고의 컨설턴트가 지점별 예약정책 설계</h3>
                    </dt>
                    <dd>예약 경로에 따른 예약 정책, 예약 테이블 지정, 맞춤형 이벤트 기획까지 전문 컨설턴트가 지점별 특성에 맞게 예약정책을 설계해 드립니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>예약관련 CRM 모든 기능 제공</h3>
                    </dt>
                    <dd>다채널유입 예약 관리, 실시간 예약 확인, 예약금 정책, 예약확인 메시지, 예약 접수-확인-관리 모두 TableManager 하나로 가능합니다.</dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap colorBack">
          <div className="gapDef" />
          <div className="inner">
            <h2>
              카카오 챗봇 예약으로 <br />
              브랜드 마케팅 채널 활성화
            </h2>
            <div className="imgTxt reverse">
              <img className="phone" src={require('@/assets/images/main03.png')} alt="브랜드 마케팅 예제화면" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>카카오 챗봇 예약</h3>
                    </dt>
                    <dd>카카오 챗봇 예약으로 더 많은 예약을 private 하게 받아 보세요. 영업시간 외에도 자동으로 고객 응대가 가능합니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>브랜드 마케팅 경쟁력 확보</h3>
                    </dt>
                    <dd>매장을 방문한 고객들과 카카오톡 친구가 되고, 카카오 채널을 통해 고객들과 소통하면서 자주 찾는 고객들이 늘어납니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>테이블매니저 단독 제공</h3>
                    </dt>
                    <dd>쉽고 빠르게 고객과 소통할 수 있는 카카오 챗봇 예약은 TableManager 도입 매장에서만 이용할 수 있습니다.</dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap">
          <div className="gapDef" />
          <div className="inner">
            <h2>
              수요예측AI를 활용하여 <br />
              데이터에 기반한 마케팅 시행
            </h2>
            <div className="imgTxt tabletWrap">
              <img src={require('@/assets/images/fran01.jpg')} className="tablet" alt="수요예측 AI 분석화면" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>외식업 최초 수요예측AI 상용화</h3>
                    </dt>
                    <dd>
                      테이블매니저는 레스토랑 예약 데이터를 활용하여 ‘수요예측 인공지능(AI) 모델링’을 독자적으로 개발했습니다. <br />
                      (2021.12월 특허등록)
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>예약 가능한 좌석을 예측해서 가격 최적화</h3>
                    </dt>
                    <dd>방문 횟수, 거주지, 이용 메뉴 등 방문객 데이터를 인공지능으로 분석하여 예약가능한 미래의 좌석을 예측하고 가격을 최적화하여 마케팅 합니다.</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <h3>강력한 마케팅 프로그램과 매출 효과</h3>
                    </dt>
                    <dd>
                      요일과 시간대를 예측한 타깃 마케팅 솔루션인 {'<'}예약상품권{'>'}으로 레스토랑의 매출을 올리고, 식자재와 자원을 효율적으로 관리 할 수 있습니다.
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap">
          <img src={require('@/assets/images/franchise/about_main@2x.jpg')} className="w100" alt="스마트폰 예약현황 연출화면" />
        </div>

        <div className="wrap">
          <div className="gapDef" />
          <div className="inner">
            <h2 className="noBr">
              매장을 더 편리하게 운영할 수 있는 <br />
              테이블매니저만의 강력한 기능
            </h2>
            <div className="imgTxt">
              <img src={require('@/assets/images/icon/common-func01.svg').default} className="w50" alt="대기인원 일러스트" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>효율적인 웨이팅 관리</h3>
                    </dt>
                    <dd>매장 앞 대기 고객을 일일이 확인할 필요가 없도록 웨이팅 고객을 TableManager가 관리해 드립니다.</dd>
                  </dl>
                </li>
              </ul>
            </div>
            <div className="gap60" />
            <div className="imgTxt reverse">
              <img src={require('@/assets/images/icon/common-func02.svg').default} className="w50" alt="픽업예약 일러스트" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>스마트한 픽업 예약</h3>
                    </dt>
                    <dd>고객이 원하는 시간에 음식을 픽업할 수 있도록 TableManager가 포장 주문을 도와 드립니다.</dd>
                  </dl>
                </li>
              </ul>
            </div>
            <div className="gap60" />
            <div className="imgTxt">
              <img src={require('@/assets/images/icon/common-func03.svg').default} className="w50" alt="테이블 체크인 일러스트" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>앉아서 하는 테이블체크인</h3>
                    </dt>
                    <dd>고객이 매장 입구에서 기다릴 필요가 없이 테이블에 앉아서 직접 QR 체크인을 할 수 있습니다.</dd>
                  </dl>
                </li>
              </ul>
            </div>
            <div className="gap60" />
            <div className="imgTxt reverse">
              <img src={require('@/assets/images/icon/common-func05.svg').default} className="w50" alt="마케팅 제류 일러스트" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>매출이 올라가는 카드사·통신사 마케팅 제휴</h3>
                    </dt>
                    <dd>카드사 결제 데이터를 활용한 맞춤형 타깃 마케팅과 통신사 VIP 고객 프로모션으로 인해 레스토랑을 방문하는 고객들이 더 많이 늘어납니다.</dd>
                  </dl>
                </li>
              </ul>
            </div>
            <div className="gap60" />
            <div className="imgTxt">
              <img src={require('@/assets/images/icon/common-func04.svg').default} className="w50" alt="만족도 조사 일러스트" />
              <ul className="box">
                <li>
                  <dl>
                    <dt>
                      <h3>더 나은 서비스를 위한 고객 만족도 조사</h3>
                    </dt>
                    <dd>
                      문자와 알림톡으로 진행한 고객 만족도 조사 결과를 바로 다음날 만족도 평가 리포트로 확인할 수 있습니다. 데이터 기반 비즈니스 인사이트로 고객 만족도가
                      올라갑니다.
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <div className="gapDef" />
        </div>

        <div className="wrap">
          <div className="parallax para02" />
        </div>

        <div className="wrap">
          <div className="gapDef" />
          <Estimate />
        </div>
        <div className="gapDef" />
        <FreeExperience />
      </main>
      <Footer />
    </>
  );
};

export default Franchise;
