import HeadTitle from '@/components/HeadTitle/HeadTitle';
import ViewModeImg from '@/assets/images/waiting/view_mode.png';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import { WaitingDetailImg } from '../WaitingDetailLayout.styles';

const ViewMode = () => {
  return (
    <SkillContainer>
      <HeadTitle
        subTitle="두가지 뷰모드 제공"
        title={'매장 상황에 맞게 \n관리 타입 선택 가능'}
        desc={'예약과 대기를 함께 관리할 때는 테이블 모드, 대기 \n위주로 관리할 때는 리스트 뷰, 자유롭게 선택하세요.'}
      />
      <WaitingDetailImg className="view-mode">
        <ul className="img-content">
          <li>
            <img src={ViewModeImg} alt="두가지 뷰모드 제공 서비스 소개 영상" />
          </li>
        </ul>
      </WaitingDetailImg>
    </SkillContainer>
  );
};

export default ViewMode;
