export const REQUESTS_OPERATION = {
  GET: 'get',
  POST: 'post',
  PATCH: 'patch',
  PUT: 'put',
  DELETE: 'delete',
} as const;

export const THROTTLE_DELAY = 300;

export const PAGE_TYPE = {
  DEFAULT: 'default',
  KT: 'kt',
};
