import {} from './StepContainer.constants';

import { IStepContainer } from './StepContainer.type';
import React from 'react';
import { StepContainerContainer } from './StepContainer.styles';
//import classnames from 'classnames';

const StepContainer = (props: IStepContainer): React.ReactElement => {
  function moveHomepage() {
    document.querySelector('#form').scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <StepContainerContainer id="step">
      <div className="chatbot-introduction-container">
        <div className="title">
          카카오톡 챗봇예약을 <br />
          도입해 보세요.
        </div>
        <div className="description">챗봇예약 도입만으로 카카오톡의 수많은 이용자를 내 고객으로 만들어 보세요.</div>
      </div>
      <div className="step-box">
        <div className="icon">1</div>
        <div className="content">
          <div className="title">도입 문의</div>
          <div className="description">테이블매니저 홈페이지에 접속해 간단한 매장 정보를 입력한 뒤 도입을 문의해주세요.</div>
        </div>
      </div>
      <div className="step-box">
        <div className="icon">2</div>
        <div className="content">
          <div className="title">도입 신청</div>
          <div className="description">카카오톡 챗봇예약 서비스 파트너사인 테이블매니저와 상담/계약 후 입점을 신청할 수 있습니다.</div>
        </div>
      </div>
      <div className="step-box">
        <div className="icon">3</div>
        <div className="content">
          <div className="title">모든 예약 경로 연동</div>
          <div className="description">
            별도 앱을 다운로드하거나 기기를 구매할 필요 없이 기존에 사용하던 컴퓨터나 스마트폰에서
            <br />
            전화, 카카오톡 챗봇 등 여러 경로에서 들어오는 예약을 한 번에 관리할 수 있습니다.
          </div>
        </div>
      </div>
      <div className="step-box">
        <div className="icon yellow">4</div>
        <div className="content">
          <div className="title">챗봇예약 서비스 시작</div>
          <div className="description">고객들에게 카카오톡 챗봇예약 서비스를 제공해 지금보다 더 많은 신규 고객을 불러보세요.</div>
        </div>
      </div>
      <div className="bottom-move-button" onClick={moveHomepage}>
        <span>챗봇예약 도입 바로가기</span>
        <img src="/kakao/img/animation_arrow.gif" className="arrow-icon" />
      </div>
    </StepContainerContainer>
  );
};

export default StepContainer;
