import React from 'react';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import HeadTitle from '@/components/HeadTitle/HeadTitle';
import { SatisfactionSurveyImg } from '../SatisfactionSurveyLayout.styles';
import DataDownloadImg from '@/assets/images/survey/data_download.png';

const DataDownload = (): React.ReactElement => {
  return (
    <SkillContainer>
      <HeadTitle subTitle="엑셀 파일 다운로드" title="손쉬운 데이터 다운로드" desc={'엑셀로 다운 받아 자유롭게 \n활용 가능합니다.'} />
      <SatisfactionSurveyImg className="data-download">
        <ul className="img-content">
          <li>
            <img src={DataDownloadImg} alt="엑셀 파일 다운로드 서비스 소개" />
          </li>
        </ul>
      </SatisfactionSurveyImg>
    </SkillContainer>
  );
};

export default DataDownload;
