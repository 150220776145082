import '@/styles/detail.scss';
import '@/styles/presets/toast.scss';

import { devServerApi, serverImgURL } from '@/shared/apis/devServerApi';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CATEGORY_DATA } from '@/shared/utils/news/category';
import Consultation from '@/components/Consultation/Consultation';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import { KAKAO_KEY } from '@/shared/apis/kakaoApi';
import ToastUI from '@/components/ToastUI/ToastUI';
import { changeTitle } from '@/shared/lib/functions/change-title';
import classnames from 'classnames';
import { dateFilter } from '@/shared/lib/functions/date-format';
import { goToDetail } from '@/shared/lib/functions/news/navigate-detail';
import { unEscape } from '@/shared/utils/news/unEscape';

const Detail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [detailData, setDetailData] = useState(null);
  const [filterLatestData, setFilterLatestData] = useState([]);

  const newsId = params.id;

  useEffect(() => {
    changeTitle();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    // 디테일 페이지 기사 데이터
    devServerApi.api
      .get(`/news/${newsId}`)
      .then((response) => {
        setDetailData(response.data.message.data);
      })
      .catch((error) => console.log(error));
  }, [newsId]);

  useEffect(() => {
    // 현재 디테일 뉴스 기사를 제외한 가장 최신 기사 3개 데이터 가져오기
    detailData &&
      devServerApi.api
        .get(`/news/more-list/${newsId}?category=${detailData.category}`)
        .then((response) => {
          setFilterLatestData(response.data.message.data);
        })
        .catch((error) => console.log(error));
  }, [detailData]);

  const shareFacebook = () => {
    window.open(
      `http://www.facebook.com/sharer.php?u=${window.location.href}&t=${unEscape(detailData.title)}`,
      '테이블매니저 - 1위 예약•대기•고객관리 솔루션 기업',
      'width=400, height=400'
    );
  };

  const shareKakao = () => {
    try {
      if (window.Kakao) {
        window.Kakao.init(KAKAO_KEY);
      }
    } catch (e) {
      console.log('kakao init error', e);
    }

    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: '테이블매니저 - 1위 예약•대기•고객관리 솔루션 기업',
        description: unEscape(detailData.title),
        imageUrl: serverImgURL + detailData.image.split('.com/').pop(),
        link: {
          webUrl: window.location.href,
          mobileWebUrl: window.location.href,
        },
      },
      buttons: [
        {
          title: '뉴스 기사 보러가기',
          link: {
            webUrl: window.location.href,
            mobileWebUrl: window.location.href,
          },
        },
      ],
    });
  };

  const [isSuccessCopy, setIsSuccessCopy] = useState(false);

  const showToastUI = () => {
    setIsSuccessCopy(true);

    const toastTimer = setTimeout(() => {
      // 토스트 ui로 복사완료되었다고 알려주기
      setIsSuccessCopy(false);
    }, 1100);
    return () => {
      clearTimeout(toastTimer);
    };
  };

  const copyLink = () => {
    if (navigator.clipboard !== undefined) {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          showToastUI();
        })
        .catch((error) => console.log('error', error));
    } else {
      const tempTextarea = document.createElement('textarea');
      document.body.appendChild(tempTextarea);
      tempTextarea.value = window.location.href;
      tempTextarea.select();
      tempTextarea.setSelectionRange(0, 99999);
      document.execCommand('copy');
      tempTextarea.setSelectionRange(0, 0);
      document.body.removeChild(tempTextarea);

      showToastUI();
    }
  };

  return (
    detailData && (
      <>
        {isSuccessCopy && <ToastUI message="링크가 복사되었습니다." />}
        <Header />
        <main className="sub">
          <div className="detailPage">
            <div className="TitBox center">
              <h4 className="point">
                {dateFilter(detailData.date)} {detailData.media.author} 게재
              </h4>
              <h1>{unEscape(detailData.title)}</h1>
              <section className="snsLinks">
                <a onClick={shareFacebook}>
                  <img src={require('@/assets/images/icon/facebook.svg').default} />
                </a>
                <a onClick={shareKakao}>
                  <img src={require('@/assets/images/icon/kakao.svg').default} />
                </a>
                <a onClick={copyLink}>
                  <img src={require('@/assets/images/icon/url.svg').default} />
                </a>
              </section>
            </div>

            <div className="detailImg">
              <img className={classnames({ hideImg: detailData.image === '' })} src={serverImgURL + detailData.image.split('.com/').pop()} alt="뉴스기사 이미지" />
            </div>
            <div className="textArea" dangerouslySetInnerHTML={{ __html: detailData.contents }} />
            <ul className="subTxt">
              <li>
                <h4>테이블매니저 개요</h4>
                <p className="tablemanagerSummary">
                  테이블매니저는 레스토랑의 예약 및 고객관리 혁신을 통해 외식업자와 소비자 모두에게 더 나은 경험을 제공하는 IT 기업이다. 테이블매니저 솔루션 하나로 전화, 네이버,
                  카카오 챗봇 등 여러 경로를 통해 들어오는 예약을 통합 관리하고 고객 데이터를 쌓을 수 있다. 이랜드이츠(애슐리, 자연별곡 등), 가온, 알라프리마, 울프강스테이크하우스,
                  엔타스그룹(경복궁, 삿뽀로, 고구려 등) 등 국내 유명 레스토랑과 프랜차이즈 기업들이 테이블매니저 솔루션을 쓰고 있다. <br />
                  <br />
                  한편, 테이블매니저는 작년 11월 중소벤처기업부가 개최한 ‘도전! 케이(K)-스타트업 2020 왕중왕전에 ‘빈자리 예측 인공지능(AI)기반 예약 관리 및 빅데이터 마케팅
                  플랫폼’으로 출전해 특허청장상을 수상한 바 있다. 테이블매니저가 궁금하시면
                  <a href="http://www.tablemanager.io/" target="_blank">
                    {' '}
                    www.tablemanager.io
                  </a>{' '}
                  를 방문해주세요.
                </p>
              </li>

              <li className="writer">
                <h4 className="subtext-title">언론 연락처</h4>
                <p>
                  <span>{detailData.media.author} </span>
                  <span>{detailData.media.position}</span>
                </p>

                <p className="contact">
                  <a href="mailto:hsj@tablemanager.io">{detailData.media.email}</a>
                  <a href={`tel:${detailData.media.tel}`}>{detailData.media.tel}</a>
                </p>
              </li>
            </ul>
            <div className="gapDef" />
          </div>

          <div className="wrap topBorder">
            <div className="gapDef" />
            <div className="inner">
              <h2>보도자료 더보기</h2>
              <ul className="newsList">
                {filterLatestData &&
                  filterLatestData.map((item) => (
                    <li className={classnames({ bodo: item.category === 'A', news: item.category === 'B', emptyImg: item.image === '' })} key={item.idx}>
                      <div className="newsItem" onClick={() => goToDetail(navigate, item.idx, item.category)}>
                        <div className="newsCon">
                          <span className="cate">{CATEGORY_DATA[item.category]}</span>
                          <h3>{unEscape(item.title)}</h3>
                          <h6>{dateFilter(item.date)}</h6>
                        </div>
                        <div
                          className="newsImg"
                          style={{ backgroundImage: `url(${item.image === '' ? require('@/assets/images/news/emptyImg.png') : serverImgURL + item.image.split('.com/').pop()})` }}
                        />
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="gapDef" />
            <Consultation />
          </div>
        </main>
        <Footer />
      </>
    )
  );
};

export default Detail;
