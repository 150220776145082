import { css } from '@emotion/react';

export const ResetStyles = css `
  * {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  li {
    -webkit-tap-highlight-color: transparent;
  }
`;
