import { MediaQuery } from '@/shared/styles';
import styled from '@emotion/styled';

export const ReservationManagementContainer = styled.article`
  margin-block: 200px;
  ${MediaQuery.tablet} {
    max-width: calc(100% - 6% - 6%);
    margin-inline: auto;
  }
  ${MediaQuery.mobile} {
    max-width: 100%;
    margin-block: 0;
    background: #eaeef8;
    padding: 140px 6.66% 0;
  }
`;
export const ReservationManagementViewWrapper = styled.section`
  display: flex;
  justify-content: center;
  background: #f2f3f4;
  border-radius: 20px;

  ${MediaQuery.mobile} {
    background: transparent;
  }
`;

export const ReservationManagementViewWrap = styled.ul`
  display: flex;
  align-items: center;
  padding-inline: 7.45%;
  box-sizing: border-box;

  ${MediaQuery.tablet} {
    align-items: flex-end;
  }
  ${MediaQuery.mobile} {
    flex-direction: column;
    align-items: center;
    padding-inline: 0;
  }
`;

export const ReservationManagementView = styled.li`
  &.phone-img-wrap {
    ${MediaQuery.mobile} {
      max-width: 256px;
      width: fit-content;
      height: auto;
    }

    img {
      display: block;
      max-width: 100%;
      padding-top: 52px;

      ${MediaQuery.mobile} {
        padding-top: 56px;
      }
    }
  }
`;

export const ReservationManagementInfo = styled.ul`
  max-width: 460px;
  padding-block: 33px;

  ${MediaQuery.mobile} {
    padding-block: 0;
  }
`;

export const CheckReservationManagement = styled.li`
  display: flex;
  align-items: center;
  padding-block: 40px;

  ${MediaQuery.mobile} {
    align-items: flex-start;
    padding-block: 28px;

    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  &:not(&:last-of-type) {
    border-bottom: 1px solid rgba(18, 35, 58, 0.06);
  }

  &::before {
    content: '';
    flex-shrink: 0;
    flex-grow: 0;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #12233a;
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='21' viewBox='0 0 28 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.5758 3.28215L24.666 0.372437L10.5239 14.5146L3.25128 7.24195L0.422852 10.0704L10.6045 20.252L27.5758 3.28215Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 27.15px 19.88px;
    font-size: 0;

    ${MediaQuery.mobile} {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      background-size: 18.1px 13.25px;
    }
  }

  > .desc-wrap {
    h4 {
      padding-bottom: 18px;
      font-weight: 700;
      font-size: 21px;
      line-height: 30px;
      letter-spacing: -0.03em;
      color: #12233a;

      ${MediaQuery.mobile} {
        padding-bottom: 12px;
        font-size: 17.5px;
        line-height: 24px;
      }
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: rgba(18, 35, 58, 0.6);

      ${MediaQuery.mobile} {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
`;
