import React from 'react';
import styled from '@emotion/styled';
import { MediaQuery } from '@/shared/styles';

export const FloatingButtonContainer = styled.div<React.CSSProperties>`
  width: fit-content;
  height: 54px;
  background: #2d60b8;
  border-radius: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  padding: 15px 64px 15px 24px;
  position: fixed;
  bottom: 60px;
  right: 100px;
  cursor: pointer;
  box-sizing: border-box;
  z-index: 999;
  letter-spacing: -0.4px;
  box-shadow: 0 0 3px rgba(45, 96, 184, 1);
  &:after {
    width: 54px;
    height: 54px;
    content: '';
    display: block;
    background-color: #12233a;
    background-image: url('/kakao/img/animation_arrow.gif');
    background-size: 100%;
    border-radius: 50px;
    position: absolute;
    right: -0.5px;
  }
  ${MediaQuery.tablet} {
    display: none;
  }
`;
