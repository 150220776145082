import BottomArrow from '@/assets/images/KTcallAssistant/icon/bottomArrow.svg';
import { MediaQuery } from '@/shared/styles';
import styled from '@emotion/styled';

export const DetailsContainer = styled.article`
  max-width: 1100px;
  margin: 0 auto;
`;

export const InformationDetails = styled.details`
  &[open] {
    > summary {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
`;

export const InformationContentWrapper = styled.section`
  margin: 30px 0px -40px;

  &.details-content-wrapper {
    margin-bottom: 100px;
  }
`;

export const InformationTableWrap = styled.div`
  ${MediaQuery.mobile} {
    width: 100vw;
    overflow-y: hidden;
  }
`;

export const InformationSummary = styled.summary`
  padding: 26px 40px 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  outline: 0;
  font-weight: 700;
  font-size: 35.2px;
  line-height: 46px;
  background: #f2f3f4;
  color: #12233a;
  border-top: 2px solid #12233a;

  &::marker {
    font-size: 0;
  }
  &::-webkit-details-marker {
    display: none;
  }

  &::after {
    content: '';
    width: 40px;
    height: 40px;
    background: url(${BottomArrow}) no-repeat center center;
    background-size: 40px 22.64px;

    ${MediaQuery.mobile} {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      background-size: 20px 11.32px;
    }
  }

  ${MediaQuery.tablet} {
    padding-top: 22.5px;
    padding-bottom: 28.5px;
    font-size: 30.8px;
    line-height: 40px;
  }
  ${MediaQuery.mobile} {
    margin-inline: 6.66%;
    padding: 18px 16px 24px;
    font-size: 21px;
    line-height: 27px;
  }
`;

export const InformationTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  ${MediaQuery.mobile} {
    display: block;
    width: 122.2222%;
    padding-inline: 6.66%;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  thead {
    background: #eaeef8;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #12233a;

    ${MediaQuery.tablet} {
      font-size: 14px;
      line-height: 21px;
    }

    th {
      border-top: 2px solid #12233a;
      border-bottom: 1px solid #12233a;
      padding-block: 12px;
    }
  }
  tbody {
    tr {
      th {
        width: 25.4545%;
        max-width: 280px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: rgba(18, 35, 58, 0.6);
        background: #eaeef8;
        border-bottom: 1px solid #e5e5e5;

        ${MediaQuery.tablet} {
          font-size: 14px;
          line-height: 21px;
        }
        ${MediaQuery.mobile} {
          font-weight: 700;
          padding-inline: 10px;
          box-sizing: border-box;

          &[rowspan] {
            color: #12233a;
          }
        }
      }
      td {
        padding-block: 12px;
        padding-inline: 20px;
        vertical-align: initial;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #12233a;
        border-bottom: 1px solid #e5e5e5;

        ${MediaQuery.tablet} {
          font-size: 14px;
          line-height: 21px;
        }
        ${MediaQuery.mobile} {
          padding: 12px;
        }

        ul {
          text-align: left;

          &.dotted-list {
            li {
              display: flex;
              padding-bottom: 10px;
              &::before {
                content: '•';
                margin-right: 6px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
`;
