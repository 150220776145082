import { ITabList } from '@/components/kakao/TabList/TabList.type';
import React from 'react';
import { TabListContainer } from '@/components/kakao/TabList/TabList.styles';

const TabList = (props: ITabList): React.ReactElement => {
  // header가 있 or 없는 페이지에 따라 가입안내가 스크롤되는 위치가 달라져야 한다
  function moveEffect() {
    document.getElementById('tab-2').classList.remove('selected');
    document.getElementById('tab-1').classList.add('selected');
    document.querySelector('#introduce').scrollIntoView({ behavior: 'smooth' });
  }

  function moveStep() {
    document.getElementById('tab-1').classList.remove('selected');
    document.getElementById('tab-2').classList.add('selected');
    document.querySelector('#howToUse').scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <TabListContainer>
      <div className="tab selected" onClick={moveEffect} id="tab-1">
        주요특징
      </div>
      <div className="tab" onClick={moveStep} id="tab-2">
        가입안내
      </div>
    </TabListContainer>
  );
};

export default TabList;
