import { StepContainerContainer } from '@/components/kakao/StepContainer/StepContainer.styles';

const HowToUse = () => {
  return (
    <StepContainerContainer id="howToUse">
      <div className="chatbot-introduction-container">
        <div className="title">
          혜택과 함께 KT AI 통화비서를 <br />
          누리는 방법
        </div>
        <div className="description">예약은 AI 통화비서에 모두 맡기시고, 사장님은 고객서비스에 집중하세요!</div>
      </div>
      <div className="step-box">
        <div className="icon">1</div>
        <div className="content">
          <div className="title">가입 문의</div>
          <div className="description">무료체험 신청을 통해 간단한 매장 정보를 입력한 뒤 도입을 문의해주세요. 영업일 기준 2일 이내 고객센터를 통해 연락을 드릴 예정입니다.</div>
        </div>
      </div>
      <div className="step-box">
        <div className="icon">2</div>
        <div className="content">
          <div className="title">가입 신청</div>
          <div className="description">KT AI 통화비서 서비스 공식 파트너사인 테이블매니저와 상담/계약 후 도입이 신청 완료됩니다.</div>
        </div>
      </div>
      <div className="step-box">
        <div className="icon">3</div>
        <div className="content">
          <div className="title">앱 로그인</div>
          <div className="description">개통 후 5일 이내 앱 로그인 시 무료 혜택을 제공해 드립니다.</div>
        </div>
      </div>
      <div className="step-box">
        <div className="icon yellow">4</div>
        <div className="content">
          <div className="title">통화비서 서비스 시작</div>
          <div className="description">바쁜 업무로 인해 나도 모르게 놓치고 있는 고객을 KT AI 통화비서와 함께 모두 챙겨보세요.</div>
        </div>
      </div>
    </StepContainerContainer>
  );
};

export default HowToUse;
