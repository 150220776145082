import { DownloadIntroductionContainer, DownloadRightArrowIcon } from './DownloadIntroduction.styles';

const introducePdf =
  'https://dev-tablemanager-starfruit.s3.ap-northeast-2.amazonaws.com/%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%87%E1%85%B3%E1%86%AF%E1%84%86%E1%85%A2%E1%84%82%E1%85%B5%E1%84%8C%E1%85%A5_%E1%84%83%E1%85%A2%E1%84%80%E1%85%B5%E1%84%89%E1%85%A5%E1%84%87%E1%85%B5%E1%84%89%E1%85%B3_%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf';

const DownloadIntroduction = () => {
  const downloadPdf = () => {
    window.open(introducePdf, '_blank', 'noopener, noreferrer');
  };

  return (
    <DownloadIntroductionContainer>
      <ul>
        <li className="waiting-service-introduce-pdf" onClick={downloadPdf}>
          <h5 className="pdf-title">대기 서비스 소개서 다운로드</h5>
          <DownloadRightArrowIcon />
        </li>
      </ul>
    </DownloadIntroductionContainer>
  );
};

export default DownloadIntroduction;
