import HeadTitle from '@/components/HeadTitle/HeadTitle';
import CustomerManagementImg from '@/assets/images/waiting/customer_management.png';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import { WaitingDetailImg } from '../WaitingDetailLayout.styles';

const CustomerManagement = () => {
  return (
    <SkillContainer>
      <HeadTitle subTitle="일관된 고객관리" title={'대기 고객도 예약 \n고객처럼 관리'} desc={'테이블 배치도 위에서 대기 고객을 한눈에 확인하고 \n한번에 관리하세요.'} />
      <WaitingDetailImg className="customer-management">
        <ul className="img-content">
          <li>
            <img src={CustomerManagementImg} alt="일관된 고객관리 서비스 소개 영상" />
          </li>
        </ul>
      </WaitingDetailImg>
    </SkillContainer>
  );
};

export default CustomerManagement;
