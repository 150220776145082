import React from 'react';
import styled from '@emotion/styled';
import { StyledContainer } from '@/components/kakao/StyledContainer/StyledContainer.styles';
import { MediaQuery } from '@/shared/styles';

export const EnterContainerContainer = styled.div<React.CSSProperties> `
  ${StyledContainer}
  .containerInner.introduce {
    .containerBody {
      background-color:#F2F3F4;
      padding-bottom:0;
      ${MediaQuery.tablet} {
        padding-top: 34px;
        overflow: hidden;
      }
      & > video {
        margin: 40px 0 -20px 0;
        border-radius:16px;
        ${MediaQuery.tablet} {
          width: 154px;
        }
      }
    }
  }
`;
