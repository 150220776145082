import styled from '@emotion/styled';

export const FreeTrialAppForSolutionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  background-color: #05153c;
  text-align: center;
`;

export const FreeTrialTitle = styled.h1`
  margin: 0 0 48px;
  font-weight: 700;
  font-size: 51.2px;
  line-height: 67px;
  letter-spacing: -0.04em;
  color: #fff;
`;

export const SolutionInquiryButton = styled.button`
  width: fit-content;
  padding: 12.5px 68.5px;
  border: 0;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #12233a;

  &:hover {
    color: #fff;
  }
`;
