import '@/styles/main.scss';

import CustomRouter from '@/Routes';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import React, { useLayoutEffect } from 'react';
import WOW from 'wowjs';
/* eslint-disable nonblock-statement-body-position */
import i18n from '@/locales/i18n';
import queryClient from '@/shared/utils/queryClient';
import { useEffect } from 'react';
import { useSolution } from '@/stores/common/useSolution';
import { TM_POST_CHANNEL } from '@/constants';

const App = (): React.ReactElement => {
  const { setIsSolutionFrame } = useSolution();

  // B2B 솔루션 인입 감지 로직, https://tablemanager.atlassian.net/browse/PRD-6856
  const detectSolutionMode = (e) => {
    const { type, data } = e.data;
    if (type === TM_POST_CHANNEL && data === 'Initialize') {
      setIsSolutionFrame(true);
      window.removeEventListener('message', detectSolutionMode);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('message', detectSolutionMode);
    return () => window.removeEventListener('message', detectSolutionMode);
  }, []);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <>
      <div className="main-container-desktop">
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <CustomRouter />
          </QueryClientProvider>
        </I18nextProvider>
      </div>
    </>
  );
};

export default App;
