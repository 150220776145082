import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import SolutionExperienceForm from '../SolutionExperienceForm/SolutionExperienceForm';
import WOW from 'wowjs';
import { useSolutionPopup } from '@/stores/common/useSolutionPopup';
import { useSolution } from '@/stores/common/useSolution';

const Header = () => {
  const navSection = useRef(null);
  const mobileNav = useRef(null);
  const { solutionModal: isShowSolution, setSolutionModal: setIsShowSolution } = useSolutionPopup();
  const { isSolutionFrame } = useSolution();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const isForForm = searchParams.get('form');
    if (isForForm && isForForm !== '') {
      setIsShowSolution(true);
    }
    const main = document.querySelector('main');
    const allTags = main?.querySelectorAll(
      'h1, h2, h3, h4, h5:not(.notice-content h5), h6, p, dd, dt, img:not(.snsLinks a img, .arrow-icon), li:not(.dotted-list > li, .notice-content li), .newsList li, .TitImg, button, .bigDec'
    );

    allTags &&
      Array.from(allTags).forEach((tag) => {
        tag.classList.add('fadeInUp');
        tag.classList.add('wow');
      });
    new WOW.WOW({ boxClass: 'wow', animateClass: 'animated', offset: 100, mobile: !0, live: !0 }).init();
  }, []);

  useEffect(() => {
    // 페이지 이동시 scroll Top으로 이동
    window.scrollTo(0, 0);
  }, []);

  const fadeInSolution = () => {
    setIsShowSolution(true);
  };

  const toggleMobileNav = () => {
    navSection.current.classList.toggle('active');
    mobileNav.current.classList.toggle('active');
  };

  const goToPage = (path) => {
    let current = pathname;

    if (path === current) toggleMobileNav();
    current = path;
    navigate(path);
  };

  // B2B 솔루션에서 진입 시 헤더 숨김
  if (isSolutionFrame) return null;

  return (
    <>
      <SolutionExperienceForm setIsShowSolution={setIsShowSolution} isShowSolution={isShowSolution} />
      <header>
        <div className={'left ' + (pathname.includes('/kakao') ? 'withkakao' : '')}>
          <Link to="/">
            <img src={require('@/assets/images/icon/logo.svg').default} alt="테이블매니저 로고" />
          </Link>
          <img src={require('@/assets/images/icon/cross.svg').default} alt="cross" className={'cross'} />
          <img src={require('@/assets/images/icon/kakaoYellow.svg').default} alt="카카오로고" className={'kakaoLogo'} />
        </div>
        <div className="nav" ref={navSection}>
          <nav>
            <ul>
              <li>
                기능
                <ul>
                  <li>
                    <Link to="/kakao/">챗봇</Link>
                    <Link to="/kt">AI 통화비서</Link>
                    <Link to="/waiting">대기</Link>
                    <Link to="/pickup">픽업</Link>
                    <Link to="/satisfaction-survey">만족도 조사</Link>
                  </li>
                </ul>
              </li>
              <li>
                TableManager 도입사례
                <ul>
                  <li>
                    <a onClick={() => goToPage('/restaurant')}>레스토랑</a>
                  </li>
                  <li>
                    <a onClick={() => goToPage('/franchise')}>프랜차이즈</a>
                  </li>
                  <li>
                    <a onClick={() => goToPage('/corp')}>공공・의료・레저</a>
                  </li>
                </ul>
              </li>
              <li>
                회사 소개
                <ul className="sub-nav-menu">
                  <li>
                    <a onClick={() => goToPage('/about')}>회사 소개</a>
                  </li>
                  <li>
                    <a onClick={() => goToPage('/news')}>뉴스룸</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <div className="right">
          <button type="button" className="btn-start popupOpen" onClick={fadeInSolution}>
            솔루션 체험하기
          </button>
          <ul className="mNav" onClick={toggleMobileNav} ref={mobileNav}>
            <li />
            <li />
            <li />
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
