import create from 'zustand';

interface IUseSolution {
  isSolutionFrame: boolean;
  setIsSolutionFrame: (val: boolean) => void;
}

export const useSolution = create<IUseSolution>((set) => ({
  isSolutionFrame: false,
  setIsSolutionFrame: (val) => set({ isSolutionFrame: val }),
}));
