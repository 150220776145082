import styled from '@emotion/styled';
import RightArrowIcon from '@/assets/images/icon/right_arrow.svg';
import { MediaQuery } from '@/shared/styles';

export const DownloadIntroductionContainer = styled.article`
  &::before {
    // pdf 다운로드 섹션을 구분하는 선
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    margin-block: 64px;
    background-color: #f2f3f4;

    ${MediaQuery.tablet} {
      margin-block: 40px;
    }
  }

  .waiting-service-introduce-pdf {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 24px;
    background: #ffffff;
    border: 2px solid rgba(18, 35, 58, 0.2);
    border-radius: 4px;
    cursor: pointer;

    .pdf-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.04em;
      color: #12233a;

      ${MediaQuery.mobile} {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;

export const DownloadRightArrowIcon = styled.i`
  display: block;
  width: 34px;
  height: 24px;
  background: url(${RightArrowIcon}) center center/contain;

  ${MediaQuery.mobile} {
    width: 26px;
    height: 18px;
  }
`;
