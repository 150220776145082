import React from 'react';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import HeadTitle from '@/components/HeadTitle/HeadTitle';
import { SatisfactionSurveyImg } from '../SatisfactionSurveyLayout.styles';
import ResponseRateImg from '@/assets/images/survey/response_rate.png';

const ResponseRate = (): React.ReactElement => {
  return (
    <SkillContainer>
      <HeadTitle
        subTitle="카카오톡으로 쉬운 조사 참여"
        title="평균 15%의 높은 응답률"
        desc={'매장 방문 후 고객에게 자동으로 발송되는 \n카카오 알림톡을 통해 더 많은 평가를 받아보세요.'}
      />
      <SatisfactionSurveyImg className="response-rate">
        <ul className="img-content">
          <li>
            <img src={ResponseRateImg} alt="카카오톡으로 쉬운 조사 참여 서비스 소개" />
          </li>
        </ul>
      </SatisfactionSurveyImg>
    </SkillContainer>
  );
};

export default ResponseRate;
