import { MediaQuery } from '@/shared/styles';
import styled from '@emotion/styled';

export const WaitingDetailImg = styled.section`
  display: flex;
  justify-content: center;
  height: 600px;
  box-sizing: border-box;
  background: #f2f3f4;
  border-radius: 16px;

  ${MediaQuery.mobile} {
    height: auto;
    min-height: 280px;
  }

  &.lottie-animation {
    align-items: end;
    padding-top: 40px;
    overflow: hidden;

    ${MediaQuery.mobile} {
      padding-top: 34px;
    }

    .img-content {
      max-width: 288px;
      border-radius: 16px;
      margin-bottom: -20px;
      overflow: hidden;

      ${MediaQuery.tablet} {
        max-width: 278px;
      }

      ${MediaQuery.mobile} {
        max-width: 154px;
      }
    }
  }

  &.customer-management {
    align-items: center;

    .img-content img {
      max-width: 840px;

      ${MediaQuery.tablet} {
        max-width: 798px;
      }

      ${MediaQuery.mobile} {
        max-width: 280px;
      }
    }
  }

  &.view-mode {
    align-items: end;

    ${MediaQuery.mobile} {
      align-items: center;
    }

    .img-content img {
      display: block;
      max-width: 900px;

      ${MediaQuery.tablet} {
        max-width: 800px;
      }

      ${MediaQuery.mobile} {
        max-width: 280px;
      }
    }
  }

  &.flexible-device {
    align-items: center;

    .img-content img {
      max-width: 840px;

      ${MediaQuery.tablet} {
        max-width: 800px;
      }

      ${MediaQuery.mobile} {
        max-width: 280px;
      }
    }
  }

  &.data-utilization {
    align-items: center;

    .img-content img {
      max-width: 802px;
      filter: drop-shadow(0px 4px 20px rgba(18, 35, 58, 0.16));

      ${MediaQuery.tablet} {
        max-width: 798px;
      }

      ${MediaQuery.mobile} {
        max-width: 280px;
      }
    }
  }
`;
