import React from 'react';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import HeadTitle from '@/components/HeadTitle/HeadTitle';
import { PickUpDetailImg } from '../PickUpDetailLayout.styles';
import ConvenientUseImg from '@/assets/images/pickup/convenient_use.json';
import Lottie from 'lottie-react';

const ConvenientUse = (): React.ReactElement => {
  return (
    <SkillContainer>
      <HeadTitle className="convenient-use-head-title" subTitle="편리한 사용법" title="클릭 한 번이면 주문 처리 완료!" desc="번거로운 주문 처리, 클릭 한 번으로 끝내세요." />
      <PickUpDetailImg className="convenient-use">
        <ul className="img-content">
          <li>
            <Lottie animationData={ConvenientUseImg} />
          </li>
        </ul>
      </PickUpDetailImg>
    </SkillContainer>
  );
};

export default ConvenientUse;
