import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import ConvenientUse from '@/components/PickUp/ConvenientUse/ConvenientUse';
import IntegrationManagement from '@/components/PickUp/IntegrationManagement/IntegrationManagement';
import IntroHeader from '@/components/PickUp/IntroHeader/IntroHeader';
import Order from '@/components/PickUp/Order/Order';
import FloatingButton from '@/components/Waiting/FloatingButton/FloatingButton';
import FreeTrialApp from '@/components/Waiting/FreeTrialApp/FreeTrialApp';
import { Wrapper } from '@/components/kakao/Wrapper/Wrapper';
import { FREE_TRIAL_PAGE_TYPE } from '@/shared/utils/survey/survey.constants';

const PickUpHome = () => {
  return (
    <>
      <Header />
      <main>
        <Wrapper>
          <IntroHeader />
          <IntegrationManagement />
          <Order />
          <ConvenientUse />
        </Wrapper>
        <FreeTrialApp freeTrialPageType={FREE_TRIAL_PAGE_TYPE.PICKUP} />
      </main>
      <Footer />
      <FloatingButton title="픽업서비스 신청" />
    </>
  );
};

export default PickUpHome;
