import { MediaQuery } from '@/shared/styles';
import styled from '@emotion/styled';

export const NoticeContainer = styled.article`
  max-width: 1100px;
  margin: 0 auto;
`;

export const NoticeContent = styled.ul`
  margin-top: 40px;
  > li {
    margin-block: 40px;

    ${MediaQuery.tablet} {
      margin-block: 20px;
      box-sizing: border-box;
    }

    ${MediaQuery.mobile} {
      margin-inline: calc(100vw * 0.066);
    }

    &:not(&.dotted-one-line) {
      display: flex;
    }

    &.dotted-one-line {
      h5 {
        padding-bottom: 14px;
      }
    }

    &::before {
      content: '•';
      margin-right: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    h5 {
      display: inline-block;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;

      ${MediaQuery.tablet} {
        font-size: 14px;
        line-height: 21px;
      }
    }

    ul.inner-list {
      li {
        &::before {
          content: '-';
          font-size: 16px;
          line-height: 24px;
          color: #12233a;
        }
      }
    }
  }

  .notice-table-wrapper {
    .notice-table-wrap {
      ${MediaQuery.mobile} {
        margin-left: calc(100vw * -0.066);
      }
      table {
        tbody tr {
          &:first-of-type {
            border-top: 2px solid #12233a;
          }

          th {
            &:first-of-type {
              width: 14.5454%;
            }
            &:nth-of-type(2) {
              width: 13.5454%;
              padding-inline: 29px;
            }
          }
          td {
            width: 72%;
            text-align: left;
          }
        }
      }
    }
  }
`;

export const TableWithDesc = styled.section`
  .caution {
    margin-block: 16px;
  }

  .list-wrapper {
    li {
      margin-block: 16px;

      .dotted-wrap {
        display: flex;
        padding-top: 8px;
        &::before {
          content: '•';
          margin-right: 8px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
`;
