import { MediaQuery } from '@/shared/styles';
import { css } from '@emotion/react';

export const StyledContainer = css`
  width: 1100px;
  padding-bottom: 200px;
  .containerInner {
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 16px;
      color: #2d60b8;
      ${MediaQuery.tablet} {
        text-align: center;
        font-weight: 700;
        font-size: 17.5px;
        line-height: 24px;
        margin-bottom: 12px;
      }
    }

    .sub-title {
      font-weight: 700;
      font-size: 41.6px;
      line-height: 54px;
      color: #12233a;
      margin-bottom: 30px;
      ${MediaQuery.tablet} {
        text-align: center;
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 24px;
      }
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: rgba(18, 35, 58, 0.6);
      margin-bottom: 40px;
      ${MediaQuery.tablet} {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 56px;
      }
    }

    .containerBody {
      display: flex;
      padding: 0;
      gap: 40px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      position: relative;
      ${MediaQuery.tablet} {
        gap: 100px;
      }
    }
  }
  ${MediaQuery.tablet} {
    width: 100vw;
    padding: 140px 24px;
    box-sizing: border-box;
    &:before {
      left: 0;
    }
  }
`;
