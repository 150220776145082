import ChatbotDescription from '@/components/kakao/ChatbotDescription/ChatbotDescription';
import ChatbotImageContainer from '@/components/kakao/ChatbotImageContainer/ChatbotImageContainer';
import EffectContainer from '@/components/kakao/EffectContainer/EffectContainer';
import EnterContainer from '@/components/kakao/EnterContainer/EnterContainer';
import FloatingButton from '@/components/kakao/FloatingButton/FloatingButton';
import Footer from '@/components/Footer/Footer';
import FreeExperience from '@/components/FreeExperience/FreeExperience';
import Header from '@/components/Header/Header';
import InquiryContainer from '@/components/kakao/InquiryContainer/InquiryContainer';
import ManageContainer from '@/components/kakao/ManageContainer/ManageContainer';
import NewbieContainer from '@/components/kakao/NewbieContainer/NewbieContainer';
import React from 'react';
import ReservationContainer from '@/components/kakao/ReservationContainer/ReservationContainer';
import StepContainer from '@/components/kakao/StepContainer/StepContainer';
import TabList from '@/components/kakao/TabList/TabList';
import { Wrapper } from '@/components/kakao/Wrapper/Wrapper';

const Home = (): React.ReactElement => {
  return (
    <>
      <Header />
      <main>
        <Wrapper>
          <ChatbotImageContainer />
          <ChatbotDescription />
          <TabList />
          <EffectContainer />
          <EnterContainer />
          <ManageContainer />
          <NewbieContainer />
          <ReservationContainer />
          <InquiryContainer />
          <StepContainer />
        </Wrapper>
        <FreeExperience />
      </main>
      <Footer />
      <FloatingButton />
    </>
  );
};

export default Home;
