export const sizes = {
  mobile: 800,
  tablet: 1100,
  desktop: 1100,
};

type TMediaQuery = Record<keyof typeof sizes, string>;

const MediaQuery: TMediaQuery = {
  mobile: `@media screen and (max-width: ${sizes.mobile}px)`,
  tablet: `@media screen and (max-width: ${sizes.tablet}px)`,
  desktop: `@media screen and (min-width: ${sizes.desktop}px)`,
};

export { MediaQuery };
