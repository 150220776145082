import { IntroduceContainer, IntroduceImgWrapper } from './Introduce.styles';

import HeadTitle from '@/components/HeadTitle/HeadTitle';
import LeftIntroImage from '@/assets/images/KTcallAssistant/intro01.png';
import RightIntroImage from '@/assets/images/KTcallAssistant/intro02.png';

const Introduce = () => {
  return (
    <IntroduceContainer id="introduce">
      <HeadTitle
        subTitle="소개"
        title="AI 통화비서가 사장님보다 전화를 더 잘 받아요"
        desc="매장 안내 예약 업무, 고객의 요청사항까지 피크타임에도 친절하게, 어려운 설명도 알기 쉽게, 고객의 요구사항 하나도 놓치지 않아요!"
      />
      <IntroduceImgWrapper>
        <ul className="introduce-img-wrap">
          <li>
            <h4>영업시간 문의</h4>
            <img src={LeftIntroImage} alt="kt ai 통화비서 소개 이미지" />
          </li>
          <li>
            <h4>예약 문의</h4>
            <img src={RightIntroImage} alt="kt ai 통화비서 소개 이미지" />
          </li>
        </ul>
      </IntroduceImgWrapper>
    </IntroduceContainer>
  );
};

export default Introduce;
