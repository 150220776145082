import { MediaQuery } from '@/shared/styles';
import styled from '@emotion/styled';

export const FormTitleContainer = styled.div`
  .sub-title {
    font-weight: 700;
    font-size: 41.6px;
    line-height: 54px;
    color: #12233a;
    margin-bottom: 30px;

    ${MediaQuery.tablet} {
      text-align: center;
      font-size: 36.4px;
      line-height: 47px;
    }

    ${MediaQuery.mobile} {
      margin-bottom: 28px;
      font-size: 28px;
      line-height: 36px;
      text-align: left;
    }
  }

  .description {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.03em;
    color: rgba(18, 35, 58, 0.6);
    margin-bottom: 28px;

    ${MediaQuery.tablet} {
      width: 100%;
      margin-bottom: 50px;
      text-align: center;
      font-size: 21px;
      line-height: 30px;
    }

    ${MediaQuery.mobile} {
      font-size: 17.5px;
      margin-bottom: 28px;
      line-height: 24px;
      text-align: left;
    }
  }
`;
