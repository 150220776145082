export const onIncreaseCount = (): void => {
  let isCountFlag: boolean = false;

  function numberCounter(t: string, n): void {
    this.count = 0;
    this.diff = 0;
    this.target_count = parseInt(n);
    this.target_frame = document.getElementById(t);
    this.timer = null;
    this.counter();
  }
  numberCounter.prototype.counter = function () {
    // eslint-disable-next-line consistent-this
    const t = this;
    this.diff = this.target_count - this.count;
    this.diff > 0 && (t.count += Math.ceil(this.diff / 5));
    this.target_frame.innerHTML = this.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    this.count < this.target_count
      ? (this.timer = setTimeout(() => {
          t.counter();
          isCountFlag = true;
        }, 50))
      : clearTimeout(this.timer);
  };

  const onScroll = (): void => {
    const curr: number = document.documentElement.scrollTop;
    const aboutCount = document.getElementById('aboutCount');
    const count_section: number = aboutCount?.offsetTop - window.innerHeight / 2 - 400;
    if (curr > count_section && isCountFlag === false) {
      /* eslint-disable no-new */
      new numberCounter('cnt01', 40000000000);
      new numberCounter('cnt02', 16900000);
      new numberCounter('cnt03', 10700000);
    }
  };
  window.addEventListener('scroll', onScroll);
};
