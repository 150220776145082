import '@/styles/news.scss';

import { devServerApi, serverImgURL } from '@/shared/apis/devServerApi';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Consultation from '@/components/Consultation/Consultation';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import AllNews from '@/components/News/AllNews';
import EmptyCategory from '@/components/News/EmptyData/EmptyCategory';
import { changeTitle } from '@/shared/lib/functions/change-title';
import { dateFilter } from '@/shared/lib/functions/date-format';
import { goToDetail } from '@/shared/lib/functions/news/navigate-detail';
import { CATEGORY_DATA } from '@/shared/utils/news/category';
import { unEscape } from '@/shared/utils/news/unEscape';
import classnames from 'classnames';
import { useQuery } from 'react-query';

const News = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('all');
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    changeTitle(pathname);
  }, []);

  const { data: latestA } = useQuery(['LATEST_A'], () =>
    // 가장 최신 보도자료
    devServerApi.api
      .get('/news?category=A&limit=1')
      .then((response) => response.data.message.data[0])
      .catch((error) => console.log(error))
  );

  const { data: latestB } = useQuery(['LATEST_B'], () =>
    // 가장 최신 외식업계 뉴스
    devServerApi.api
      .get('/news?category=B&limit=1')
      .then((response) => response.data.message.data[0])
      .catch((error) => console.log(error))
  );

  const { isLoading, data: tabNewsData } = useQuery(['NEWS_DATA', activeTab], () =>
    devServerApi.api
      .get(`/news${activeTab === 'all' ? '' : `?category=${activeTab}`}`)
      .then((response) => response.data.message.data)
      .catch((error) => console.log(error))
  );

  useEffect(() => {
    tabNewsData && setIsEmpty(() => tabNewsData.length < 1);
  }, [tabNewsData]);

  return (
    <>
      <Header />
      <>
        <main className="sub">
          <div className="wrap">
            <div className="inner">
              <div className="TitBox">
                <h4>뉴스룸</h4>
                <h1>
                  테이블매니저
                  <br />
                  뉴스룸
                </h1>
              </div>
            </div>
            <div className="gap60" />
          </div>
          <div className="wrap">
            <div className="inner">
              <ul className="newsList">
                {latestA && (
                  <li className={classnames({ emptyImg: latestA.image === '' })}>
                    <div className="newsItem" onClick={() => goToDetail(navigate, latestA.idx, 'A')}>
                      <div className="newsCon">
                        <span className="cate">{CATEGORY_DATA[latestA.category]}</span>
                        <h3>{unEscape(latestA.title)}</h3>
                        <h6>{dateFilter(latestA.date)}</h6>
                      </div>
                      <div
                        className="newsImg"
                        style={{
                          backgroundImage: `url(${latestA.image === '' ? require('@/assets/images/news/emptyImg.png') : serverImgURL + latestA.image.split('.com/').pop()})`,
                        }}
                      />
                    </div>
                    <AllNews location={'/press'} />
                  </li>
                )}

                {latestB && (
                  <li className={classnames({ emptyImg: latestB.image === '' })}>
                    <div className="newsItem" onClick={() => goToDetail(navigate, latestB.idx, 'B')}>
                      <div className="newsCon">
                        <span className="cate">{CATEGORY_DATA[latestB.category]}</span>
                        <h3>{unEscape(latestB.title)}</h3>
                        <h6>{dateFilter(latestB.date)}</h6>
                      </div>
                      <div
                        className="newsImg"
                        style={{
                          backgroundImage: `url(${latestB.image === '' ? require('@/assets/images/news/emptyImg.png') : serverImgURL + latestB.image.split('.com/').pop()})`,
                        }}
                      />
                    </div>
                    <AllNews location={'/newslist'} />
                  </li>
                )}

                {/* --------------------미디어 키트-------------------- */}
                {/* <li>
                  <a href="detail.html" className="newsItem">
                    <div className="newsCon">
                      <span className="cate">테이블매니저</span>
                      <h3>미디어키트</h3>
                      <p>테이블매니저의 로고와 브랜드 이미지를 살펴보세요!</p>
                    </div>
                    <div className="mediaKit">
                      <img src={require('@/assets/images/news/kit@2x.png')} alt="kit" />
                      <button className="defBtn">다운로드</button>
                    </div>
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="gapDef" />
          </div>
          <div className="wrap">
            <div className="inner">
              <h2>뉴스 아카이브</h2>
              <ul className="newsTap">
                <li className={`allBtn ${activeTab === 'all' ? 'active' : ''}`} onClick={() => setActiveTab('all')}>
                  전체
                </li>
                <li className={`bodoBtn ${activeTab === 'A' ? 'active' : ''}`} onClick={() => setActiveTab('A')}>
                  보도자료
                </li>
                <li className={`newsBtn ${activeTab === 'B' ? 'active' : ''}`} onClick={() => setActiveTab('B')}>
                  업계뉴스
                </li>
              </ul>

              {tabNewsData && (
                <ul className="newsList">
                  {tabNewsData.map((list) => (
                    <li className={classnames({ bodo: list.category === 'A', news: list.category === 'B', emptyImg: list.image === '' })} key={list.idx}>
                      <div className="newsItem" onClick={() => goToDetail(navigate, list.idx, list.category)}>
                        <div className="newsCon">
                          <span className="cate">{CATEGORY_DATA[list.category]}</span>
                          <h3>{unEscape(list.title)}</h3>
                          <h6>{dateFilter(list.date)}</h6>
                        </div>
                        <div
                          className="newsImg"
                          style={{ backgroundImage: `url(${list.image === '' ? require('@/assets/images/news/emptyImg.png') : serverImgURL + list.image.split('.com/').pop()})` }}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {isEmpty && <EmptyCategory />}
            </div>
            <div className="gapDef" />
          </div>
          {/* ----------------------미디어 키트------------------------ */}
          {/* <div className="wrap">
            <div className="inner">
              <h2>미디어 키트</h2>
              <ul className="newsList">
                <li>
                  <a href="detail.html" className="newsItem news">
                    <div className="newsCon">
                      <h3>로고</h3>
                    </div>
                    <div className="newsImg" style={{ backgroundImage: `url(${require('@/assets/images/logo/logoimg@2x.jpg')})` }} />
                  </a>
                </li>
                <li>
                  <a href="detail.html" className="newsItem news">
                    <div className="newsCon">
                      <h3>
                        브랜드 이미지 - <br />
                        제품 및 서비스 사진
                      </h3>
                    </div>
                    <div className="newsImg" style={{ backgroundImage: `url(${require('@/assets/images/news/newsimg/newsimg09@2x.jpg')})` }} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="gapDef" />
          </div> */}
          <Consultation />
        </main>
      </>
      <Footer />
    </>
  );
};

export default News;
