import { useSolutionPopup } from '@/stores/common/useSolutionPopup';

const Consultation = () => {
  const { setSolutionModal } = useSolutionPopup();

  return (
    <>
      <div className="wrap colorBack">
        <div className="gapDef" />
        <div className="inner center requestBox">
          <h1>지금 상담 신청하세요.</h1>
          <p>최고의 컨설턴트가 고객님의 상황에 꼭 맞게 설계해 드립니다.</p>
          <button className="defBtn popupOpen" onClick={() => setSolutionModal(true)}>
            상담 신청하기
          </button>
        </div>
        <div className="gapDef" />
      </div>
    </>
  );
};

export default Consultation;
