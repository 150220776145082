import React from 'react';
import { IInquiryContainer } from './InquiryContainer.type';
import {} from './InquiryContainer.constants';
import { InquiryContainerContainer } from './InquiryContainer.styles';
//import classnames from 'classnames';

const InquiryContainer = (props: IInquiryContainer): React.ReactElement => {
  return (
    <InquiryContainerContainer>
      <div className="containerInner inquiry">
        <div className="title">문의</div>
        <div className="sub-title">궁금하면 바로 바로, 편리하게<br/>문의하세요</div>
        <div className="description">예약 관리, 마케팅, 정산, 쿠폰 및 할인 등<br/>무엇이든 궁금하시면 전화나 메일로 1:1 상담을 통해<br/>24시간 이내에 사장님의 궁금증을 해결해 드립니다.</div>
        <div className="containerBody">
          <img src="/kakao/img/contact@2x%201@3x.png" alt="" width={471}/>
        </div>
      </div>
    </InquiryContainerContainer>
  );
};

export default InquiryContainer;
