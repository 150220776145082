import '@/styles/about.scss';
import '@/styles/presets/aboutCount.scss';

import CountNumber from '@/components/CountNumber/CountNumber';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import { changeTitle } from '@/shared/lib/functions/change-title';
import { onIncreaseCount } from '@/shared/lib/functions/scroll/count-number';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const About = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    onIncreaseCount();
    changeTitle(pathname);
  }, []);

  return (
    <>
      <Header />
      <main className="sub newsPage">
        <div className="wrap borderBottom">
          <div className="inner">
            <div className="TitBox">
              <h4>회사 소개</h4>
              <h1>테이블매니저</h1>
            </div>
            <div className="bigDec">
              테이블매니저는 <br />
              모든 레스토랑의 성장과 성공을 도와드립니다.
              <br />
              <br />
              국내 1위 예약 및 고객관리솔루션 TableManager를 시작으로
              <br />
              실시간 온라인 예약플랫폼 더예약
              <br />
              수요예측AI 기반의 빈자리 마케팅 솔루션 예약상품권까지
              <br />
              <br />
              외식업계의 디지털전환을 선도합니다.
            </div>
          </div>
        </div>
        <div className="wrap">
          <div className="gapDef" />
          <div className="inner">
            <h2>
              테이블매니저의
              <br />
              시작
            </h2>
          </div>

          <article className="companyDesc inner">
            <div className="companyImg">
              <img src={require('@/assets/images/about/about-company-sub02.jpg')} alt="테이블매니저 대표이사와 구성원들이 의사결정을 하는 모습" />
            </div>
            <div className="companyExplan">
              <div className="col">
                <p>
                  예약 관리 솔루션 TableManager는 모든 규모의 레스토랑들이 생존과 성공을 위해 힘겨운 사투를 벌이고 있으며, 다른 어떤 산업 분야보다도 '디지털화'가 절실하다는 것을
                  직감하면서 만들어진 제품입니다.
                </p>
                <p>크롬 웹을 기반으로 클라우드를 활용하는 예약 관리 솔루션 TableManager는 구축이 쉽고 어디서나 어떤 기기로든 사용할 수 있습니다. 인터페이스 또한 직관적이죠.</p>
              </div>
              <div>
                <p>
                  회사 이름을 알리기 위해 자체 애플리케이션을 만들기 보다는 고객과 매장을 가장 빨리 연결하기 위해 고객들에게 익숙한 네이버와 카카오 플랫폼을 활용한 서비스를
                  제공하고 있습니다.
                </p>
                <p>테이블매니저는 고객과 레스토랑을 직접 연결하고, 정교한 데이터 분석을 통한 맞춤형 고객관리로 디지털 예약문화를 만들기 위해 노력해 왔습니다.</p>
                <p>
                  이제 테이블매니저는 2,200여개 매장에서 사용하는 온라인 예약관리 솔루션 1위 기업으로 성장했습니다. 레스토랑 뿐만 아니라 공공, 레저, 의료 분야까지 예약이 필요한
                  다양한 영역에서 예약관리 솔루션의 효과를 직접 경험하고 있습니다.
                </p>
              </div>
            </div>
          </article>
        </div>
        <div className="wrap">
          <div className="inner">
            <div className="gapDef" />
            <CountNumber />
          </div>
          <div className="gapDef" />
        </div>
        <div className="wrap">
          <div className="inner">
            <div className="gapDef" />
            <h2>
              예약∙AI∙데이터∙마케팅
              <br />
              전문 기업으로 성장
            </h2>
            <p className="desc-about">
              테이블매니저는 AI 예약 관리 분야의 독보적인 기술력과 빅데이터 마케팅으로 <br />
              예약 비즈니스의 디지털 전환을 돕고 있습니다. <br />
              새로운 기술이 새로운 가치를 창출하는 변화의 시대, <br />
              테이블매니저의 성장은 계속됩니다.
            </p>
            <div className="gap60" />
            <div className="history">
              <h1 className="point">2021</h1>
              <ul>
                <li>
                  과학기술정보통신부, 정보통신산업진흥원(NIPA) 추진 ‘SW고성장클럽200’
                  <br className="pcview" />
                  우수기업으로 과학기술정보통신부 장관상 수상
                </li>
                <li>
                  2021년 성장 관광벤처기업 성과 평가 결과 신입 관광벤처 부문 <br className="pcview" />
                  최우수기업으로 한국관광공사 사장상 수상
                </li>
                <li>2021 벤처창업진흥 유공자로 중소벤처기업부 장관 표창</li>
                <li>중소벤처기업부 주최 ‘인공지능 챔피언십 2021’ CJ CGV 과제 2위 수상</li>
                <li>수요예측 AI 기술특허 승인</li>
                <li>수요예측 AI를 상용화한 예약상품권 런칭</li>
                <li>신한카드 전략적 투자 유치</li>
                <li>SKT, KT 서비스 연동</li>
                <li>병의원 예약시스템 개발 협약 외</li>
              </ul>
            </div>

            <div className="history">
              <h1 className="point">2020</h1>
              <ul>
                <li>
                  관광산업의 새로운 사업 영역을 개척하고 일자리 창출에 기여한 공로를 인정 받아 <br className="pcview" />
                  문화체육관광부와 한국관광공사가 발표한 ‘2020년 우수관광벤처’ 선정
                </li>
                <li>
                  중소벤처기업부 개최 ‘도전! 케이(K)-스타트업 2020 왕중왕전에 ‘빈자리 예측
                  <br className="pcview" />
                  인공지능(AI) 기반 예약 관리 및 빅데이터 마케팅 플랫폼’으로 출전해 특허청장상 수상
                </li>
                <li>중소벤처기업부 TIPS 프로그램 성공 판정</li>
                <li>국내 최초 구글 예약 파트너사 선정 및 제휴</li>
                <li>35억 원 시리즈 A투자 유치</li>
              </ul>
            </div>

            <div className="history">
              <h1 className="point">2019</h1>
              <ul>
                <li>카카오톡, 삼성카드 등과 업무 제휴 체결, BC카드와 할인 프로모션 진행</li>
                <li>
                  네이버 예약 서비스와 테이블매니저 솔루션 실시간 연동, POS 기기 연동,
                  <br className="pcview" />
                  BC・삼성카드 등과 할인 프로모션 진행
                </li>
                <li>POS 기기 연동, 삼성카드와 업무 제휴 체결</li>
              </ul>
            </div>

            <div className="history">
              <h1 className="point">2018</h1>
              <ul>
                <li>레스토랑 실시간 예약 서비스 '마이테이블(現 더예약)' 출시</li>
                <li>누적 예약 100만 건 돌파</li>
              </ul>
            </div>

            <div className="history">
              <h1 className="point">2017</h1>
              <ul>
                <li>중소벤처기업부 TIPS 프로그램 선정</li>
                <li>네이버 계열 ‘스프링캠프’, 카카오 계열 ‘카카오벤처스’로부터 3억 원 시드 투자 유치</li>
              </ul>
            </div>

            <div className="history">
              <h1 className="point">2016</h1>
              <ul>
                <li>중소기업청 ‘스마트 창작터’ 과제 선정 (우수 판정)</li>
                <li>테이블매니저 솔루션 정식 출시</li>
              </ul>
            </div>

            <div className="history">
              <h1 className="point">2014</h1>
              <ul>
                <li>미래창조과학부 K-ICT 멘토링센터 전담 멘티 기업 선정</li>
              </ul>
            </div>
            <div className="gapDef" />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default About;
