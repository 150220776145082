import React from 'react';
import { IReservationContainer } from './ReservationContainer.type';
import {} from './ReservationContainer.constants';
import { ReservationContainerContainer } from './ReservationContainer.styles';
//import classnames from 'classnames';

const ReservationContainer = (props: IReservationContainer): React.ReactElement => {
  function moveHomepage() {
    location.href = 'http://www.tablemanager.io/?form=true';
  }
  return (
    <ReservationContainerContainer>
      <div className="containerInner reservation">
        <div className="title">다채널 예약 통합 관리 솔루션</div>
        <div className="sub-title">다채널 예약<br />통합 관리 솔루션</div>
        <div className="description">다양한 경로로 들어오는 예약을 실시간으로 통합 관리할 수 있습니다. 전용 기기나 프로그램 설치 필요 없이<br />인터넷 접속이 가능한 곳이라면 언제, 어디서든 하나의 화면으로 쉽고 편리하게 관리할 수 있습니다.</div>
        <div className="containerBody">
          <img src="/kakao/img/edited.png" alt="" width={698}/>
        </div>
      </div>
    </ReservationContainerContainer>
  );
};

export default ReservationContainer;
