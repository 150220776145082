import { privacyApi } from '@/shared/apis/devServerApi';
import { SELECT_PRIVACY_TYPE } from '@/shared/utils/privacy/privacy.constants';
import { useMutation, useQuery } from 'react-query';

interface IUserData {
  phone: string;
  email?: string;
  privacyType: string;
  isCheckedAgreement: boolean;
}

const getPrivacyUrl = (privacyType) => {
  return useQuery([privacyType], () => {
    return privacyApi
      .get(`/service/policies?servicePolicyType[]=${SELECT_PRIVACY_TYPE[privacyType]}`)
      .then((data: any) => data.response.servicePolicies)
      .catch((error) => console.log(error));
  });
};

const putPrivacyData = () => {
  return useMutation((userData: IUserData) => putPrivacyData.fetcher(userData), {
    onError: () => {
      return alert('개인정보가 저장되지 않았습니다. 다시 신청해주세요.');
    },
  });
};

putPrivacyData.fetcher = (userData: IUserData) =>
  privacyApi.api.put('/user/termsAgreements', {
    user: {
      phone: userData.phone,
      email: userData.email,
    },
    termsAgreements: [
      {
        servicePolicyType: userData.privacyType,
        agreement: userData.isCheckedAgreement,
      },
    ],
  });

export { getPrivacyUrl, putPrivacyData };
