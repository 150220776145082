import { DetailsContainer, InformationContentWrapper, InformationDetails, InformationSummary, InformationTable, InformationTableWrap } from './Details.styles';

import { KT_AI_CALL_MANAGER_PAGE_TYPE } from '@/shared/utils/ktAiCallManager.constants';

const Details = ({ type }) => {
  const isCashNoteType = type === KT_AI_CALL_MANAGER_PAGE_TYPE.CASH_NOTE;

  return (
    <DetailsContainer>
      <InformationDetails>
        <InformationSummary>상세정보(제공서비스)</InformationSummary>
        <InformationContentWrapper className="details-content-wrapper">
          <InformationTableWrap>
            <InformationTable>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>AI 통화비서 스탠다드</th>
                  {isCashNoteType ? null : <th>AI 통화비서 라이트</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>정의</th>
                  <td>유선전화 착신호에서 AI가 대신 받아 업무를 처리하는 착신형 부가서비스</td>
                  {isCashNoteType ? null : <td>유선전화 착신 실패 시, AI가 대신 받아 전달하는 착신형 부가 서비스</td>}
                </tr>
                <tr>
                  <th>주요제공기능</th>
                  <td>
                    <ul className="dotted-list">
                      <li>
                        <div>
                          <b>인사말</b> : 앱설정인사말(TTS) + 배경음악
                        </div>
                      </li>
                      <li>
                        <div>
                          <b>기본 응대</b> : 운영시간, 위치안내, 주차안내
                        </div>
                      </li>
                      <li>
                        <div>
                          <b>AI메모</b> : 부재중/무응답(25초)시 전달할 말 기록(STT)
                        </div>
                      </li>
                      <li>
                        <div>
                          <b>예약 접수</b> : 예약 신청, 변경, 취소 기능
                        </div>
                      </li>
                      <li>
                        <div>
                          <b>업종 특화기능</b> : 부동산업종 매물 등록, 음식업종 주문접수, 미용업종 시술접수
                        </div>
                      </li>
                      <li>
                        <div>
                          <b>전용 앱</b> : 통화이력/AI메모처리/예약처리/특화기능처리/기능각종 설정
                        </div>
                      </li>
                      <li>
                        <div>
                          <b>문자 발송</b> : 무료 300건
                        </div>
                      </li>
                      <li>
                        <div>착신통화 전환</div>
                      </li>
                    </ul>
                  </td>
                  {isCashNoteType ? null : (
                    <td>
                      <ul className="dotted-list">
                        <li>
                          <div>
                            <b>인사말</b> : 앱설정인사말(TTS) + 배경음악
                          </div>
                        </li>
                        <li>
                          <div>
                            <b>AI메모</b> : 부재중/무응답(25초)시 전달할 말 기록(STT)
                          </div>
                        </li>
                        <li>
                          <div>
                            <b>전용 앱</b> : 통화이력/AI메모처리/예약처리/특화기능처리/기능각종 설정
                          </div>
                        </li>
                        <li>
                          <div>
                            <b>문자 발송</b> : 유료
                          </div>
                        </li>
                        <li>
                          <div>착신통화 전환</div>
                        </li>
                      </ul>
                    </td>
                  )}
                </tr>
              </tbody>
            </InformationTable>
          </InformationTableWrap>
        </InformationContentWrapper>
      </InformationDetails>
    </DetailsContainer>
  );
};

export default Details;
