import { ChatbotDescriptionContainer } from '@/components/kakao/ChatbotDescription/ChatbotDescription.styles';

const KTdescription = () => {
  return (
    <ChatbotDescriptionContainer>
      <div className="title">KT AI 통화비서</div>
      <div className="description">
        이젠 걸려오는 전화 한 통도 놓치지 마세요. <br />
        사장님이 받지 못할 때도 AI 통화비서가 응대해 드려요.
      </div>
    </ChatbotDescriptionContainer>
  );
};

export default KTdescription;
