/* eslint-disable quotes */
/* eslint-disable no-param-reassign */
export const unEscape = (htmlStr: string): string => {
  htmlStr = htmlStr.replace(/&lt;/g, '<');
  htmlStr = htmlStr.replace(/&gt;/g, '>');
  htmlStr = htmlStr.replace(/&quot;/g, '"');
  htmlStr = htmlStr.replace(/&#39;/g, "'");
  htmlStr = htmlStr.replace(/&amp;/g, '&');

  return htmlStr;
};
