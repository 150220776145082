import { Link } from 'react-router-dom';
interface IAllNews {
  location: string;
}
const AllNews = ({ location }: IAllNews) => {
  return (
    <Link to={location} className="btn-all">
      모든 뉴스 보기
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.696" viewBox="0 0 12 11.696">
        <path
          id="arrow-right-solid_2_"
          data-name="arrow-right-solid (2)"
          className="cls-1"
          d="M5.1,38.433l.595-.595a.64.64,0,0,1,.908,0l5.207,5.2a.64.64,0,0,1,0,.908L6.6,49.157a.64.64,0,0,1-.908,0L5.1,48.563a.644.644,0,0,1,.011-.919L8.34,44.569H.643A.641.641,0,0,1,0,43.927v-.857a.641.641,0,0,1,.643-.643h7.7L5.113,39.352A.639.639,0,0,1,5.1,38.433Z"
          transform="translate(0 -37.65)"
        />
      </svg>
    </Link>
  );
};

export default AllNews;
