import { SkillContainer, SkillImgWrapper } from './Skill.styles';

import HeadTitle from '@/components/HeadTitle/HeadTitle';
import LeftSkillImage from '@/assets/images/KTcallAssistant/skill01.png';
import RightSkillImage from '@/assets/images/KTcallAssistant/skill02.png';

const Skill = () => {
  return (
    <SkillContainer>
      <HeadTitle subTitle="기능" title="실제로 대부분의 전화문의 응대가 가능합니다" desc="경기도 수원시 소재의 초밥집은 전화의 72.1%를 AI 통화비서가 처리했어요!" />
      <SkillImgWrapper>
        <ul className="skill-img-wrap">
          <li>
            <img src={LeftSkillImage} alt="kt ai 통화비서 기능 이미지" />
          </li>
          <li>
            <img src={RightSkillImage} alt="kt ai 통화비서 기능 이미지" />
          </li>
        </ul>
      </SkillImgWrapper>
    </SkillContainer>
  );
};

export default Skill;
