const RESTAURANT_SEQUENCE = ['a', 'b', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n'];
const CORPERATION_SEQUENCE = ['01', '02', '03', '04', '05', '06'];

interface IPartners {
  srcType: string;
}

const Partners = ({ srcType }: IPartners) => {
  const repeatLogo = (type: string) => {
    let imgList: any[] = [];

    switch (type) {
      case 'restaurant':
        for (let j = 0; j < 2; j++) {
          for (let i = 0; i < RESTAURANT_SEQUENCE.length; i++) {
            imgList.push(<img key={i + j * RESTAURANT_SEQUENCE.length} src={require(`@/assets/images/logo/logo-${RESTAURANT_SEQUENCE[i]}.jpg`)} alt="업체 로고" />);
          }
        }
        return imgList;

      case 'corp':
        for (let j = 0; j < 3; j++) {
          for (let i = 0; i < CORPERATION_SEQUENCE.length; i++) {
            imgList.push(<img key={i + j * CORPERATION_SEQUENCE.length} src={require(`@/assets/images/corp/logo/others-logo${CORPERATION_SEQUENCE[i]}.jpg`)} alt="업체 로고" />);
          }
        }
        return imgList;

      default:
        console.log('error');
        break;
    }
  };

  return (
    <div className="partnersWrap">
      <div id="partner">{srcType && repeatLogo(srcType)}</div>
    </div>
  );
};

export default Partners;
