import { MediaQuery } from '@/shared/styles';
import React from 'react';
import styled from '@emotion/styled';

export const ChatbotDescriptionContainer = styled.div<React.CSSProperties>`
  padding: 60px 0 !important;
  position: relative;
  box-sizing: border-box;
  ${MediaQuery.tablet} {
    padding: 100px 66px !important;
  }
  ${MediaQuery.mobile} {
    padding: 140px 24px !important;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    bottom: 0;
    background-color: #05153c;
    width: 100vw;
    ${MediaQuery.tablet} {
      /* left: 0; */
    }
  }
  & > * {
    z-index: 1;
    position: relative;
  }

  .title {
    font-weight: 700;
    font-size: 41.6px;
    line-height: 54px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 40px;
    z-index: 2;
    ${MediaQuery.tablet} {
      font-size: 36.4px;
      line-height: 47px;
    }
    ${MediaQuery.mobile} {
      margin-bottom: 28px;
      text-align: left;
      font-size: 28px;
      line-height: 36px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    z-index: 2;
    color: rgba(234, 238, 248, 0.8);

    ${MediaQuery.tablet} {
      font-size: 14px;
      line-height: 21px;
    }
    ${MediaQuery.mobile} {
      font-size: 14px;
      line-height: 21px;
      text-align: left;
    }
  }
`;
