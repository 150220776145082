import { MediaQuery } from './media-query';
import { ResetStyles } from '@/shared/styles/reset';
import { css } from '@emotion/react';

export const GlobalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');

  ${ResetStyles};
  /*common*/
  a {
    transition: 0.2s ease;
    -webkit-tap-highlight-color: transparent;
  }
  a:hover {
    color: #2d50b8;
  }
  body {
    font-size: 16px;
    color: #12233a;
    word-break: keep-all;
    line-height: 1.5em;
    overflow-x: hidden;
  }
  button {
    background-color: #12233a;
    color: #fff;
    font-weight: 700;
    transition: 0.2s ease;
    cursor: pointer;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
  }
  h1 {
    font-size: 3.2em;
    line-height: 1.3em;
  }
  h2 {
    font-size: 2.6em;
    line-height: 1.3em;
    margin-bottom: 1.1em;
  }
  h3 {
    font-size: 1.5em;
    line-height: 1.4em;
  }
  h4 {
    font-size: 1.25em;
    line-height: 1.5em;
  }
  h5 {
    font-size: 1.1em;
  }
  h6 {
    font-size: 0.9em;
  }
  p {
    margin: 0 0 1.5em;
    padding: 0;
  }
  p,
  dd {
    color: rgb(18 35 58 / 70%);
  }
  dl,
  dt,
  dd {
    padding: 0;
    margin: 0;
  }
  dt {
    margin-bottom: 12px;
  }

  /* wrap */
  main .wrap {
    width: 100%;
    float: left;
  }
  .wrap .inner {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  /* border */
  .topBorder {
    border-top: 1px solid #eee;
  }

  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .center {
    text-align: center;
  }
  .inner .right {
    width: 60%;
    float: right;
  }

  /* width */
  .w50 {
    max-width: 50%;
  }
  .w100 {
    width: 100%;
  }
  .max80 {
    width: 100%;
    max-width: 80%;
  }

  .requestBox p {
    margin: 1em 0 2em;
    font-size: 1.1em;
  }

  /* gap */
  .gapDef {
    width: 100%;
    // float: left;
    height: 200px;
    clear: both;
  }
  .gap60 {
    width: 100%;
    float: left;
    height: 60px;
  }

  /**/
  footer {
    background-color: #12233a;
    color: rgba(255, 255, 255, 0.5);
    width: 100%;
    float: left;
    padding: 80px 0;
  }
  footer .inner,
  .layerPopup .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  footer p {
    color: rgba(255, 255, 255, 0.5);
  }
  footer h2 {
    color: #fff;
    margin-bottom: 0.4em;
  }
  footer h2 a {
    color: #fff;
    float: left;
  }
  footer .inner .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  footer .inner .right {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  .address ul {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.2em;
  }
  .address li {
    margin-right: 2em;
  }
  .copyright {
    margin: 2em 0 0;
    width: 100%;
    float: left;
  }
  footer a {
    color: rgba(255, 255, 255, 0.5);
  }
  footer .right li {
    text-align: right;
    display: inline-block;
    margin-left: 1em;
  }
  .on-desktop {
    ${MediaQuery.tablet} {
      display: none;
    }
  }
  .on-handheld {
    ${MediaQuery.desktop} {
      display: none;
    }
  }
`;
