import React from 'react';
import { ITabList } from './TabList.type';
import {} from './TabList.constants';
import { TabListContainer } from './TabList.styles';
//import classnames from 'classnames';

const TabList = (props: ITabList): React.ReactElement => {
  function moveEffect() {
    document.getElementById('tab-2').classList.remove('selected');
    document.getElementById('tab-1').classList.add('selected');
    document.querySelector('#effect').scrollIntoView({ behavior: 'smooth' });
  }

  function moveStep() {
    document.getElementById('tab-1').classList.remove('selected');
    document.getElementById('tab-2').classList.add('selected');
    document.querySelector('#step').scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <TabListContainer>
      <div className="tab selected" onClick={moveEffect} id="tab-1">주요특징</div>
      <div className="tab" onClick={moveStep} id="tab-2">도입안내</div>
    </TabListContainer>
  );
};

export default TabList;
