import React from 'react';
import styled from '@emotion/styled';
import { StyledContainer } from '@/components/kakao/StyledContainer/StyledContainer.styles';
import { MediaQuery } from '@/shared/styles';

export const InquiryContainerContainer = styled.div<React.CSSProperties> `
  ${StyledContainer};
  ${MediaQuery.tablet} {
    background-color:#EAEEF8;
  }
  .containerInner.inquiry {
    .containerBody {
      background-color:#F2F3F4;
      padding: 54px 0;
      ${MediaQuery.tablet} {
        background-color:#EAEEF8;
        img {
          width: 300px!important;
        }
      }
    }
  }
`;
