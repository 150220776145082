import React from 'react';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import HeadTitle from '@/components/HeadTitle/HeadTitle';
import { PickUpDetailImg } from '@/components/PickUp/PickUpDetailLayout.styles';
import IntegrationManagementImg from '@/assets/images/pickup/integration_management.png';

const IntegrationManagement = (): React.ReactElement => {
  return (
    <SkillContainer>
      <HeadTitle subTitle="예약 • 주문 통합 관리" title="예약을 넘어 주문까지" desc={'예약 손님뿐 아니라 포장 손님도 \n솔루션에서 관리하세요.'} />
      <PickUpDetailImg className="integration-management">
        <ul className="img-content">
          <li>
            <img src={IntegrationManagementImg} alt="예약 • 주문 통합 관리 서비스 소개" />
          </li>
        </ul>
      </PickUpDetailImg>
    </SkillContainer>
  );
};

export default IntegrationManagement;
