import '@/styles/presets/paging.scss';

import { devServerApi, serverImgURL } from '@/shared/apis/devServerApi';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Consultation from '@/components/Consultation/Consultation';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import { changeTitle } from '@/shared/lib/functions/change-title';
import { dateFilter } from '@/shared/lib/functions/date-format';
import { unEscape } from '@/shared/utils/news/unEscape';
import classnames from 'classnames';
import { useQuery } from 'react-query';

const NewsList = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';

  useEffect(() => {
    changeTitle(pathname);
  }, []);

  const { isLoading, data: dataB } = useQuery(['NEWS_LIST', page], () =>
    devServerApi.api
      .get(`/news?category=B${page ? `&page=${page}` : ''}`)
      .then((response) => response.data.message)
      .catch((error) => console.log(error))
  );

  // pagination 5
  const PAGES_PER_LIST = 5;

  const [totalPage, setTotalPage] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [count, setCount] = useState(1);

  useEffect(() => {
    setTotalPage(Array.from({ length: dataB?.page.lastPage }, (_, i) => i + 1));
  }, [dataB]);

  useEffect(() => {
    let pageArr = [];
    for (let i = 0; i < totalPage.length; i += PAGES_PER_LIST) {
      pageArr.push(totalPage.slice(i, i + PAGES_PER_LIST));
    }
    setPageList(pageArr);

    setCount(pageArr.findIndex((pageSet) => pageSet.includes(Number(page))) + 1);
  }, [totalPage]);

  const prevPaging = () => {
    if (count > 1) {
      setCount((prev) => prev - 1);
      navigate(`/newslist?page=${count - 1}`);
    }
  };
  const nextPaging = () => {
    if (count < pageList.length) {
      setCount((prev) => prev + 1);
      navigate(`/newslist?page=${count + PAGES_PER_LIST}`);
    }
  };

  return (
    dataB && (
      <>
        <Header />
        <main className="sub">
          <div className="wrap">
            <div className="inner">
              <div className="TitBox">
                <h4>뉴스룸</h4>
                <h1>업계뉴스</h1>
              </div>
            </div>
            <div className="gap60" />
          </div>
          <div className="wrap">
            <div className="inner">
              <ul className="newsList">
                {dataB.data.map((list) => (
                  <li className={classnames({ emptyImg: list.image === '' })} key={list.idx}>
                    <div className="newsItem news" onClick={() => navigate(`/newslist/${list.idx}`)}>
                      <div className="newsCon">
                        <span className="cate">업계뉴스</span>
                        <h3>{unEscape(list.title)}</h3>
                        <h6>{dateFilter(list.date)}</h6>
                      </div>
                      <div
                        className="newsImg"
                        style={{ backgroundImage: `url(${list.image === '' ? require('@/assets/images/news/emptyImg.png') : serverImgURL + list.image.split('.com/').pop()})` }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
              <ul className="paging">
                <li onClick={prevPaging}>
                  <a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.121" height="11.414" viewBox="0 0 7.121 11.414">
                      <path
                        id="path_492"
                        data-name="path 492"
                        d="M6121.3-21614.07l5,5,5-5"
                        transform="translate(-21607.656 -6120.594) rotate(90)"
                        fill="none"
                        stroke="#12233a"
                        strokeWidth="2"
                      />
                    </svg>
                  </a>
                </li>
                {dataB.page.lastPage &&
                  pageList[count - 1]?.map((item) => (
                    <li key={item} className={classnames({ active: dataB.page.currentPage === item })}>
                      <Link to={`/newslist?page=${item}`}>{item}</Link>
                    </li>
                  ))}
                <li id="next" onClick={nextPaging}>
                  <a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.121" height="11.414" viewBox="0 0 7.121 11.414">
                      <path id="path_500" data-name="path 500" d="M0,5,5,0l5,5" transform="translate(5.707 0.707) rotate(90)" fill="none" stroke="#12233a" strokeWidth="2" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <div className="gapDef" />
            <Consultation />
          </div>
        </main>
        <Footer />
      </>
    )
  );
};

export default NewsList;
