import React from 'react';
import { SkillContainer } from '@/components/KTcallAssistant/Skill/Skill.styles';
import HeadTitle from '@/components/HeadTitle/HeadTitle';
import { SatisfactionSurveyImg } from '../SatisfactionSurveyLayout.styles';
import ReviewImg from '@/assets/images/survey/review.png';

const Review = (): React.ReactElement => {
  return (
    <SkillContainer>
      <HeadTitle subTitle="카카오톡으로 결과 전송" title={'사장님만 확인 가능한 \n수집된 리뷰'} desc={'수집된 리뷰는 공개되지 않고 \n사장님만 확인 가능합니다.'} />
      <SatisfactionSurveyImg className="review">
        <ul className="img-content">
          <li>
            <img src={ReviewImg} alt="카카오톡으로 결과 전송 서비스 소개" />
          </li>
        </ul>
      </SatisfactionSurveyImg>
    </SkillContainer>
  );
};

export default Review;
